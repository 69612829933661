import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"

import BackendClientMixin from "@/mixins/BackendClientMixin"

import {BIcon, BIconChatDots, BIconFileEarmarkText, BIconGeoAlt, BIconGeoAltFill, BIconPatchQuestionFill, BIconStar, BIconTag} from "bootstrap-vue"
import CheckBoxGroupInput from "@/components/form/CheckBoxGroupInput"
import TextInput from "@/components/form/TextInput"
import RadioGroupInput from "@/components/form/RadioGroupInput"

import {ProposalDetails} from "@/_modules/proposal/types"
import {Address, Language, RateType, Rating} from "@/types"
import {GmapAddressComponents} from "@/store/map"
import {isEmpty} from "@/constants/DataBoundaries"

import I18nOptions from "@/utils/I18nOptions"
import {ValidationInstance} from "@/utils/vuelidate-extension";
import {calculatePriceWithPercentage, distanceFormatter, getDistance} from "@/utils/formatters";
import ToolTip from "@/components/layout/ToolTip";
import {configStore} from "@/store/config";
import AppIconVerified from "@/components/icons/AppIconVerified"
import AppIconStar from "@/components/icons/AppIconStar"
import { authStore } from "@/store"
import AppIconGenderAmbiguous from "@/components/icons/AppIconGenderAmbiguous"

interface Props {
  allowChanges: boolean;
  proposal: ProposalDetails;
  v: object;
}

@Component({
  name: 'ProposalProviderDetails',
  components: {
    BIcon,
    BIconChatDots,
    BIconFileEarmarkText,
    BIconGeoAlt,
    BIconGeoAltFill,
    BIconPatchQuestionFill,
    BIconStar,
    BIconTag
  }
})
export default class extends BackendClientMixin implements Props {
  @Prop(Boolean) public readonly allowChanges!: boolean;
  @Prop(Object) public readonly proposal!: ProposalDetails;
  @Prop(Object) public readonly v!: ValidationInstance<ProposalDetails>
  @Prop(Object) public readonly demandProviderRating!: Rating

  private get address(): string {
    const address: Address = this.proposal.service.location.address
    return String([address.address, address.cityOrCounty]).split(',').join(' ')
  }

  private get isAddressCorrect(): boolean {
    return !Object.values(this.proposal.service.location.address).some(it => isEmpty(it))
  }

  private get isAddressEmpty(): boolean {
    return ((isEmpty(this.proposal.service.location.address.address))
      && (isEmpty(this.proposal.service.location.address.cityOrCounty))
      && (isEmpty(this.proposal.service.location.address.zipCode)))
  }

  private get proposalDraft(): boolean {
    return isEmpty(this.proposal.status)
  }

  protected get isProvider(): boolean {
    const userId = authStore.authInfo?.userId
    return (this.proposalDraft || this.proposal.service.provider.userId === userId)
  }

  private parseAddress(adr: GmapAddressComponents[], key: string): GmapAddressComponents | undefined {
    const result = adr.filter((it) => {
      return it.types.some((iter) => {
        return iter === key
      })
    })
    if (result[0] !== undefined) {
      return result[0]
    } else {
      return undefined
    }
  }

  private formatAddress(street?: string, streetNum?: string): string {
    if (street !== undefined && streetNum !== undefined) {
      return `${street} ${streetNum}`
    } else if (street !== undefined && streetNum === undefined) {
      return street
    } else if (street === undefined && streetNum !== undefined) {
      return ''
    } else {
      return ''
    }
  }

  private onLocationChanged(place: google.maps.places.PlaceResult): void {

    this.proposal.service.location.address.address = ''
    this.proposal.service.location.address.cityOrCounty = ''
    this.proposal.service.location.address.zipCode = ''

    const location = place.geometry?.location
    const adrComp = place.address_components

    if (location !== undefined && place.address_components !== undefined) {

      this.proposal.service.location.address.zipCode = this.parseAddress(adrComp!, 'postal_code') !== undefined ? this.parseAddress(adrComp!, 'postal_code')!.long_name : ''
      this.proposal.service.location.address.address = this.formatAddress(this.parseAddress(adrComp!, 'route') !== undefined ? this.parseAddress(adrComp!, 'route')!.long_name : undefined, this.parseAddress(adrComp!, 'street_number') !== undefined ? this.parseAddress(adrComp!, 'street_number')!.long_name : undefined)
      this.proposal.service.location.address.cityOrCounty = this.formatAddress(this.parseAddress(adrComp!, 'locality') !== undefined ? this.parseAddress(adrComp!, 'locality')!.long_name : '', this.parseAddress(adrComp!, 'sublocality') !== undefined ? this.parseAddress(adrComp!, 'sublocality')!.long_name : undefined)
      this.proposal.service.location.address.country = this.parseAddress(adrComp!, 'country') !== undefined ? this.parseAddress(adrComp!, 'country')!.short_name : ''

      this.proposal.service.location.coordinates = {
        latitude: location.lat(),
        longitude: location.lng()
      }
    }
  }

  public render(): VNode {
    return (
      <div>
        <h4 class="h3 mt-0 mb-3">{this.proposal.service.provider.name.first} {this.proposal.service.provider.name.last.charAt(0)}.</h4>
        <div class="d-flex mb-4">
          {this.proposal.service.provider.verified &&
            <span class="small text-nowrap mr-4 d-flex align-items-center">
              <AppIconVerified class="app-icon-lg mt-0 align-top mr-2 text-success" />
              {this.translation('msg_param_verified_true')}
            </span>
          }
          {!this.proposal.service.provider.verified &&
            <span class="small text-nowrap mr-4 d-flex align-items-center">
              <b-icon-patch-question-fill class="app-icon-lg mt-0 align-top mr-2" variant="danger-dark" />
              {this.translation('msg_param_verified_false')}
            </span>
          }
          {this.demandProviderRating.ratingsCount !== 0 &&
            <span class="small text-nowrap d-flex align-items-center">
              <AppIconStar class="app-icon-lg mt-0 align-top mr-2 text-warning" aria-label={this.translation('lbl_rating')} />
              {this.demandProviderRating.grade} <span aria-hidden="true">({this.demandProviderRating.ratingsCount})</span>
            </span>
          }
          {this.demandProviderRating.ratingsCount === 0 &&
            <span class="small text-nowrap d-flex align-items-center">
              <b-icon-star class="app-icon-lg mt-0 align-top text-gray-400 mr-2" aria-hidden="true" />
              {this.translation('lbl_no_rating')}
            </span>
          }
        </div>
        <hr class="w-100 mt-0 mb-6" />
        {this.proposal.demand.requestedAtClientPlace &&
          <div class="d-flex mb-4">
            <div class="flex-grow-0 mr-3">
              <b-icon icon={this.isProvider ? 'geo-alt' : 'geo-alt-fill'} class={`app-icon-lg align-top mt-0${this.isProvider ? ' text-gray-500' : ' text-success'}`} aria-hidden={true} />
            </div>
            <div>
              <p class="small mb-0 font-weight-bold">{this.translation('lbl_distance')}</p>
              {this.proposalDraft &&
                <span>
                  {distanceFormatter(getDistance(this.proposal.demand.location.coordinates, this.proposal.service.location.coordinates), this.translation('text_m'), this.translation('text_km'))}. {this.translation('msg_service_at_requester_location')}
                </span>
              }
              {!this.proposalDraft &&
                <span>
                  {distanceFormatter(Number(this.proposal.service.distanceInMeters), this.translation('text_m'), this.translation('text_km'))}. {this.translation('msg_service_at_requester_location')}
                </span>
              }
            </div>
          </div>
        }
        {this.proposalDraft &&
          <div>
            {!this.proposal.demand.requestedAtClientPlace &&
              <div class="d-flex mb-4">
                <div class="flex-grow-0 mr-3">
                  <b-icon icon={'geo-alt-fill'} class={'app-icon-lg align-top mt-0 text-success'} aria-hidden={true} />
                </div>
                <div class="flex-fill">
                  <label for="service-address" class="small mb-0 font-weight-bold d-block">{this.translation('lbl_address_of_service')}</label>
                  <gmap-autocomplete
                    id="service-address"
                    class="form-control mt-1"
                    value={this.address.trim()}
                    placeholder={this.translation('fld_service_location')}
                    onplace_changed={this.onLocationChanged}
                    select-first-on-enter={true}
                    components={{country: 'ee'}} //TODO: filter not working
                    onInput={(e: InputEvent) => {
                      if (e.data === null || e.data === undefined) {
                        this.proposal.service.location.address.address = ''
                        this.proposal.service.location.address.cityOrCounty = ''
                        this.proposal.service.location.address.zipCode = ''
                      }
                    }}
                  />
                  {!this.isAddressCorrect && 
                    <span class="text-danger">
                      {(!this.isAddressCorrect && !this.isAddressEmpty) &&
                        <span>{this.translation('err_incorrect_address')}</span>
                      }
                      {/* empty span required, validation watcher search for previous element sibling to start scrolling */}
                      <span />
                      <span class="invalid-feedback d-block">
                        {this.buildInvalidMessage(this.v.service.location.address.address) &&
                          <span>{this.translation('err_input_field_value_incorrect')}</span>
                        }
                      </span>
                    </span>
                  }
                </div>
              </div>
            }
            <div class="d-flex">
              <div class="flex-grow-0 mr-3">
                <b-icon icon={'chat-dots'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div>
                <p class="small mb-0 font-weight-bold">{this.translation('lbl_user_languages')}</p>
                <CheckBoxGroupInput
                  checkBoxGroupClass="my-1"
                  v-model={this.proposal.service.languages}
                  options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(Language), 'enum_language_user_speak_short_')}
                  invalid-message={this.buildInvalidMessage(this.v!.service!.languages!)}
                />
              </div>
            </div>
            <div class="d-flex mb-4">
              <div class="flex-grow-0 mr-3">
                <AppIconGenderAmbiguous class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div>
                <p class="small mb-0 font-weight-bold">{this.translation('lbl_sex')}</p>
                <span>{this.translation(`enum_sex_type_${this.proposal.service.provider.sex?.toLowerCase()}`)}</span>
              </div>
            </div>
            <div class="d-flex mb-4">
              <div class="flex-grow-0 mr-3">
                <b-icon icon={'file-earmark-text'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div>
                <p class="small mb-0 font-weight-bold">{this.translation('lbl_qualification')}</p>
                <span>{this.translation(`enum_qualification_grade_${this.proposal.service.provider.qualification}`)}</span>
              </div>
            </div>
            <div class="d-flex">
              <div class="flex-grow-0 mr-3">
                <b-icon icon={'tag'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <fieldset>
                <legend class="small mb-0 font-weight-bold">{this.translation('lbl_service_price')}</legend>
                <RadioGroupInput
                  class="mt-1 mb-0"
                  options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(RateType), 'enum_services_rate_type_')}
                  v-model={this.proposal.serviceRate.type}
                  onChange={() => this.$emit('priceChanged')}
                />
                <TextInput
                  class="mb-4"
                  v-model={this.proposal.serviceRate.price.amount}
                  invalidMessage={this.buildInvalidMessage(this.v!.serviceRate.price.amount)}
                  onBlur={() => this.$emit('priceChanged')}
                  custom-class="price-input border-right-0"
                  append={(): VNode => {
                    return (
                      <div class="input-group-text pl-0">{this.translation(`enum_currency_${this.proposal.serviceRate.price.currency}`)}</div>
                    )
                  }}
                />
              </fieldset>
            </div>
            <div class="d-flex mb-4">
              <div class="flex-grow-0 mr-3">
                <b-icon icon={'tag'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div>
                <p class="small mb-0 font-weight-bold">
                  {this.translation('fld_service_price_for_requester')}&nbsp;
                  <ToolTip
                    options={{title: this.translation(`tooltip_msg_service_price_for_requester_${this.proposal.serviceRate.type}`)}}
                    variant={'primary'}
                  />
                </p>
                <span>{calculatePriceWithPercentage(configStore.serverConfig, this.proposal.serviceRate.price)}&nbsp;{this.translation(`enum_currency_${this.proposal.serviceRate.price.currency}`)}{this.proposal.serviceRate.type === RateType.HOURLY_PAYMENT ? this.translation('text_hour_price') : ''}</span>
              </div>
            </div>
          </div>
        }
        {!this.proposalDraft &&
          <div>
            {!this.proposal.demand.requestedAtClientPlace &&
              <div class="d-flex mb-4">
                <div class="flex-grow-0 mr-3">
                  <b-icon icon={this.isProvider ? 'geo-alt-fill' : 'geo-alt'} class={`app-icon-lg align-top mt-0${this.isProvider ? ' text-success' : ' text-gray-500'}`} aria-hidden={true} />
                </div>
                <div class="flex-fill">
                  <p class="small mb-0 font-weight-bold">{this.translation('lbl_address_of_service')}</p>
                  <span>
                    {`${this.proposal.service.location.address.address}, ${this.proposal.service.location.address.zipCode} ${this.proposal.service.location.address.cityOrCounty}`}
                  </span>
                </div>
              </div>
            }
            <div class="d-flex mb-4">
              <div class="flex-grow-0 mr-3">
                <b-icon icon={'chat-dots'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div>
                <p class="small mb-0 font-weight-bold">{this.translation('lbl_user_languages')}</p>
                <span>
                  {this.proposal.service.languages.map((lang, index) => {
                    return (
                      lang === Language.ASE ?
                        <span>
                          <b-link class="text-primary" v-b-tooltip={{title: this.translation('enum_language_user_speak_ase')}}>
                            {this.translation('enum_language_user_speak_short_' + lang.toLowerCase())}
                          </b-link>{index !== this.proposal.service.languages.length - 1 ? ', ' : ''}
                        </span> :
                        <span>
                          {this.translation('enum_language_user_speak_short_' + lang.toLowerCase())}{index !== this.proposal.service.languages.length - 1 ? ', ' : ''}
                        </span>
                    )
                  })}
                </span>
              </div>
            </div>
            <div class="d-flex mb-4">
              <div class="flex-grow-0 mr-3">
                <AppIconGenderAmbiguous class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div>
                <p class="small mb-0 font-weight-bold">{this.translation('lbl_sex')}</p>
                <span>{this.translation(`enum_sex_type_${this.proposal.service.provider.sex?.toLowerCase()}`)}</span>
              </div>
            </div>
            <div class="d-flex mb-4">
              <div class="flex-grow-0 mr-3">
                <b-icon icon={'file-earmark-text'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div>
                <p class="small mb-0 font-weight-bold">{this.translation('lbl_qualification')}</p>
                <span>{this.translation(`enum_qualification_grade_${this.proposal.service.provider.qualification}`)}</span>
              </div>
            </div>
            <div class="d-flex mb-4">
              <div class="flex-grow-0 mr-3">
                <b-icon icon={'tag'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div>
                <p class="small mb-0 font-weight-bold">{this.translation('lbl_service_price')}</p>
                <span>{this.proposal.service.rate.price.amount}&nbsp;{this.translation(`enum_currency_${this.proposal.service.rate.price.currency}`)}{this.proposal.service.rate.type === RateType.HOURLY_PAYMENT ? this.translation('text_hour_price') : ''}</span>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}
