import { Component, Prop } from "vue-property-decorator"
import { VNode } from "vue"
import { AbstractInputComponent } from "@/components/form/AbstractInputComponent";
import { ServiceType } from "@/_modules/service/types";
import CheckBoxGroupInput from "@/components/form/CheckBoxGroupInput";
import { appStore } from "@/store";
import { RecursivePartial } from "@/utils/typescript-library-extensions";
import { getServiceTypeTranslationByKey } from "@/_modules/service/store/services";

type ValueType = string[] | string | number | boolean | undefined

interface Props {
  readonly label?: string;
  readonly placeholder?: string;
  readonly options: RecursivePartial<ServiceType>[];
  readonly invalidMessage?: string;
  readonly value: string[];
  readonly onInput?: (v: string[]) => void;
  readonly onChange?: (v: string[]) => void;
}

@Component({name: 'ServiceTypeSelectInput'})
export default class extends AbstractInputComponent<ValueType, Props> {
  @Prop(Array) public readonly options!: RecursivePartial<ServiceType>[]
  @Prop(String) public readonly placeholder!: string
  @Prop(Array) public readonly value!: string[]

  public isEmpty(): boolean {
    return this.value.length === 0
  }

  public get state(): boolean | undefined {
    return this.invalidMessage !== undefined ? false : undefined
  }

  public render(): VNode {
    return (
      <div class="dropdown-with-checkbox-options">
        {this.label !== undefined && 
          <div class="font-weight-bold mb-1" id={this.id}>{this.label}</div>
        }
        <b-form-group
          state={this.state}
          invalid-feedback={this.invalidMessage}
        >
          <b-dropdown
            disabled={this.disabled}
            block={true}
            no-flip={true}
            class={this.state ? 'is-invalid position-static' : 'position-static'}
            menu-class="pb-0"
            boundary='scrollParent'
            text={this.placeholder}
            variant="outline-light"
            aria-labelledby={this.id}
          >
            <b-dropdown-form form-class="pt-4 pb-0">
              {this.options.map((option, index) => {
                return (
                  <div>
                    <p class={`h3 fieldset-title${index > 0 ? ' pt-4' : ''}`}>{getServiceTypeTranslationByKey(appStore.locale, option.category!)}</p>
                    <CheckBoxGroupInput
                      checkBoxGroupClass="px-4 mb-3"
                      value={this.value}
                      options={(option.subCategories!.map((type) => {
                        return {
                          value: type!.category,
                          text: getServiceTypeTranslationByKey(appStore.locale, type!.category!)
                        }
                      }))}
                      stacked
                      onInput={(values: string[]) => this.$emit('input', values)}
                      onChange={(values: string[]) => this.$emit('change', values)}
                      disabled={this.disabled}
                    />
                  </div>
                )
              })}
            </b-dropdown-form>
          </b-dropdown>
        </b-form-group>
      </div>
    )
  }
}
