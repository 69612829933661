import {Component, Prop} from 'vue-property-decorator'
import {VNode} from "vue"
import {I18nComponent} from "@/components/I18nComponent";
import {Income} from "@/types";

@Component({name: 'ServicesTurnover'})
export default class extends I18nComponent {
  @Prop(Object) public readonly value?: Income

  public render(): VNode {
    return (
      <b-card class="dashboard-card">
        <div class="d-flex align-items-center align-items-sm-start flex-column flex-sm-row">
          <b-img alt="" aria-hidden="true" src={require('@/assets/images/icons/services_turnover.svg')}/>
          <div class="mt-2 ml-sm-4 text-center text-sm-left">
            <h3 class="m-0">{this.translation('lbl_services')}</h3>
            <p class="small m-0">{this.translation('lbl_services_description')}</p>
          </div>
        </div>
        <hr/>
        {this.value!.count !== 0 &&
          <div class="d-flex flex-column flex-sm-row flex-md-column flex-lg-row mb-n4">
            <div class="text-center flex-fill mr-2 mb-4">
              <p class="m-0">
                {this.translation('lbl_overview_amount')}
              </p>
              <p class="m-0 h1">
                {this.value?.count}
              </p>
            </div>
            <div class="text-center flex-fill mb-4">
              <p class="m-0">
                {this.translation('lbl_overview_income')}
              </p>
              <p class="m-0 h1">
                {this.value?.netIncome.amount} {this.translation(`enum_currency_${this.value?.netIncome.currency}`)}
              </p>
            </div>
          </div>
        }
        {this.value!.count === 0 &&
          <p class="m-0 small text-center">{this.translation('lbl_services_empty')}</p>
        }
      </b-card>
    )
  }
}
