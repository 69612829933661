import {integer, maxValue, minValue, required, requiredIf} from 'vuelidate/lib/validators'
import {timeValidator} from '@/constants/DataBoundaries'
import {ComponentValidation} from '@/components/calendar/CompactScheduleCalendar';
import {CalendarForm} from '@/components/calendar/mixin'
import {RepeatBy, RepeatOptions} from '@/types'
import {EventFromCalendarWithTaskData} from "@/_modules/request/types";
import moment from "moment";
//import {CalendarRecurrences} from "@/utils/calendar-recurrences";
//import {Frequency} from "rrule";

function checkIsTimeInFuture(from?: string, to?: string): boolean {
  if (from === undefined || to === undefined) {
    return false
  }
  const timeFrom = from.split(':')
  const hoursFrom = timeFrom[0]
  const minutesFrom = timeFrom[1]

  const timeTo = to.split(':')
  const hoursTo = timeTo[0]
  const minutesTo = timeTo[1]

  return hoursFrom > hoursTo || (hoursFrom === hoursTo && minutesFrom >= minutesTo)
}

export function calendarValidation(form: CalendarForm): ComponentValidation {

  const minVal = 2
  const maxVal = 500
  function earlyThenRule(v: string, vm: object): boolean {
    if ((vm as EventFromCalendarWithTaskData).recurrenceRule.repeatUntil === undefined) {
      return true
    }
    return moment(v).diff((vm as EventFromCalendarWithTaskData).recurrenceRule.repeatUntil) < 0
  }

  /*function getRelativeFrequency(repeatType?: RepeatOptions): Frequency | undefined {
    switch (repeatType) {
      case RepeatOptions.EVERY_DAY:
        return Frequency.DAILY
      case RepeatOptions.EVERY_MONTH:
        return Frequency.MONTHLY
      case RepeatOptions.EVERY_WEEK:
        return Frequency.WEEKLY
      default:
        return undefined
    }
  }*/

  /*function repeatUntilRule(formData: CalendarForm): boolean {
    const repeatType = formData.task.recurrenceRule?.repeatType
    const startDate = formData.task.start
    const endDate = formData.task.end

    const rrule = new CalendarRecurrences(startDate!, endDate!, getRelativeFrequency(repeatType))
    return false
  }*/

  return {
    form: {
      repeatBy: {required: requiredIf(() => form.task.recurrenceRule?.repeatType !== RepeatOptions.ONCE)},
      task: {
        id: {},
        calendarId: {},
        title: {},
        start: {earlyThenRule: earlyThenRule},
        end: {},
        body: {required},
        category: {},
        completed: {},
        confirmed: {},
        recurrenceRule: {
          repeatType: {required},
          repeatUntil: {required: requiredIf(() => {
            return form.repeatBy === RepeatBy.UNTIL
          })/*, repeatUntilRule: (() => repeatUntilRule(form))*/},
          repeatCount: {required: requiredIf(() => {
            return form.repeatBy === RepeatBy.COUNT
          }), maxValue: maxValue(maxVal), minValue: minValue(minVal), integer}
        },
        bgColor: {},
        occurrences: {},
        serviceCategories: [],
        state: {}
      },
      timeFrom: {required, time: timeValidator},
      timeTo: {
        required,
        time: timeValidator,
        timeInFuture: (() => !checkIsTimeInFuture(form.timeFrom, form.timeTo))
        // startEqualEnd: (() => {this.form.timeFrom >= moment(this.form.timeTo)})
      }
    }
  }
}
