import {Component, Prop, Watch} from "vue-property-decorator"
import {VNode} from "vue"

import BackendClientMixin from "@/mixins/BackendClientMixin"

import TextAreaInput from "@/components/form/TextAreaInput"
import Button from "@/components/form/Button"
import Attachments from "@/components/Attachments"

import {Message} from "@/_modules/request/types"
import {FileData} from "@/types";
import {servicesStore} from "@/_modules/service/store/services";
import {BackendUrls} from "@/constants/APIconstants";

import moment from "moment"
import {v4 as uuidv4} from "uuid";
import {isEmpty} from "@/constants/DataBoundaries";
import {BIconPlus, BIconXCircleFill} from "bootstrap-vue";
import {appStore} from "@/store";
import {convertToI18nLang} from "@/store/app";
import {chatStore} from "@/_modules/message-center/store/chat-store";
import {webSocketHandler} from "@/ws/ws-handler";

@Component({name: 'Messenger', components: {
  BIconPlus,
  BIconXCircleFill
}})
export default class extends BackendClientMixin {
  @Prop(Array) public readonly value?: Message[]
  @Prop(String) public readonly viewForCurrentUserById?: string
  @Prop(String) public readonly otherPersonName?: string
  @Prop(Boolean) public readonly disabled?: boolean
  @Prop(Boolean) public readonly msgUploading?: boolean
  @Prop({type: Boolean, default: false}) public readonly isMessageCenter!: boolean

  public message: Message = {
    message: '',
    uploadToken: undefined,
    userId: undefined,
    createdAt: undefined,
    attachments: []
  }
  public newUploadToken = ''
  private files: FileData[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //private filePreviews: any | null = null

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  public delay = 0
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public to: any | null = null
  public busyState = false

  public async beforeDestroy(): Promise<void> {
    await webSocketHandler.wsStreamSingleChatDown()
    chatStore.clearChatMessages()
  }

  @Watch('value') // may be it is no needed anymore because now all messages coming from buffer
  public setDelay(): void {
    // Inline messenger & message center desktop layout
    const messagesLayout = document.getElementsByClassName('messages-layout')

    // Mobile message center layout
    const messagesSidebar = document.querySelectorAll('#messenger-sidebar .b-sidebar-body')

    this.$nextTick(() => {
      if (messagesLayout.length !== 0) {
        messagesLayout[0].scrollTop = messagesLayout[0].scrollHeight
      }
      if (messagesSidebar.length !== 0) {
        messagesSidebar[0].scrollTop = messagesSidebar[0].scrollHeight
      }
    })
    this.delay = 100
    this.busyState = true
    clearTimeout(this.to)
    this.to = setTimeout(() => {
      this.busyState = false
      this.$nextTick(() => {
        if (messagesLayout.length !== 0) {
          messagesLayout[0].scrollTop = messagesLayout[0]?.scrollHeight
        }
        if (messagesSidebar.length !== 0) {
          messagesSidebar[0].scrollTop = messagesSidebar[0]?.scrollHeight
        }
      })
    }, this.delay)
  }

  public created(): void {
    this.newUploadToken = uuidv4()
  }

  public onKeydown(e: Event): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const event = e as any
    if (event.code === 'Enter' && !event.shiftKey) {
      e.preventDefault()
      e.stopPropagation()
      if ((!isEmpty(this.message.message) || this.files.length > 0) && this.fileUploadingProgress.length === 0) {
        this.onSendMessage()
      }
    }
  }

  public onSendMessage(): void {
    this.message.uploadToken = undefined
    if (this.files.length > 0) {
      this.message.uploadToken = this.newUploadToken
    }
    const payload = {
      message: this.message.message,
      uploadToken: this.message.uploadToken
    }
    this.$emit('sendMessage', payload)
    this.message.message = ''
    this.files = []
    this.newUploadToken = uuidv4()
  }

  public addFile(file: FileData): void {
    this.files.push(file)
  }

  public async onRemoveFile(value: FileData): Promise<void> {
    await this.withRequest(servicesStore.deleteFileById(value.id), undefined, true)
    this.files.splice(this.files.indexOf(value), 1)
  }

  private fileUploadingProgress: boolean[] = []

  private eachFileUploadingState(v: boolean): void {
    if (v) {
      this.fileUploadingProgress.push(v)
    } else {
      this.fileUploadingProgress = this.fileUploadingProgress.slice(1)
    }
  }

  private onChatInputFocus(e: Event): void {
    e.preventDefault()
    if (this.value?.length) {
      this.$emit('chatInputFocus', this.value![this.value!.length - 1].id)
    }
  }

  public render(): VNode {
    return (
      <div class="messenger">
        <div class="pt-2 pt-md-4">
          {(this.value?.length === 0 && this.otherPersonName !== undefined && !this.disabled) && 
            <div class="bg-gray-100 rounded overflow-hidden d-flex align-items-center justify-content-center mb-4 mb-md-8 mx-2 mx-md-4">
              <div class="my-8 p-6 d-flex flex-column align-items-center justify-content-center text-center no-results-found">
                <p class="h4 mb-0">{this.otherPersonName}</p>
                <p class="small mb-0">{this.translation('msg-center.start_conversation')}</p>
              </div>
            </div>
          }
          {(this.value?.length === 0 && this.disabled) && 
            <div class="messages-layout"></div>
          }
          {this.value?.length !== 0 && 
            <div class="messages-layout">
              {!this.msgUploading && this.value?.map((it, index) => {
                const activeUser = it.userId === this.viewForCurrentUserById
                return (
                  <div class="mb-4 px-2 px-md-4" key={index}>
                    <div class={[activeUser ? "messages primary-msg" : "messages secondary-msg"]}>
                      {it.attachments.length > 0 &&
                        <div>
                          {it.attachments.length > 0 && it.attachments.map(file => {
                            return (
                              <div>
                                <b-link class="link-style" href={`${BackendUrls.urlApi}${BackendUrls.file}/${encodeURIComponent(file.id)}`}>
                                  {file.fileName}
                                </b-link>
                              </div>
                            )
                          })}
                        </div>
                      }
                      {(it.attachments.length > 0 && it.message !== '') &&
                      <hr class="mb-3" />
                      }
                      <p class="mb-0">{it.message}</p>
                    </div>
                    <div class={['message-date pt-1 px-3', activeUser ? "text-right" : "text-left"]}>
                      {moment(it.createdAt).locale(convertToI18nLang(appStore.locale)).format('LLL')}
                    </div>
                  </div>
                )
              })}
            </div>
          }
        </div>
        <div class="messenger-controls mt-md-4 px-2 px-md-4">
          <div class={`messenger-inputs d-flex flex-column mr-n2 ${this.isMessageCenter ? 'flex-lg-row align-items-lg-start' : 'flex-md-row align-items-md-start'}`}>
            <TextAreaInput
              textCounter
              maxLength={1000}
              class="flex-fill mr-2 mb-4 mb-md-6"
              disabled={this.disabled}
              placeholder={this.translation('msg-center.fld_message')}
              value={this.message.message}
              onInput={(v: string) => (this.message.message = v.trim())}
              onKeydown={(e: Event) => this.onKeydown(e)}
              rows={1}
              onFocus={(e: Event) => this.onChatInputFocus(e)}
            />
            <div class="d-flex mb-2 mb-md-6">
              <Attachments
                class="mr-2"
                disabled={this.disabled}
                upload-token={this.newUploadToken}
                value={this.files}
                onInput={this.addFile}
                messenger={true}
                publicOnly={true}
                aria-label={this.translation('msg-center.fld_add_file')}
                browseText={this.translation('msg-center.fld_add_file')}
                onAttachmentsError={(err: string) => this.$emit('messengerError', err)}
                onFileUploadInProgress={(v: boolean) => this.eachFileUploadingState(v)}
              />
              <Button
                disabled={this.fileUploadingProgress.length > 0 || this.busy || this.disabled || (this.message.message === '' && this.files.length === 0)}
                class={`btn-message-send mr-2${this.isMessageCenter ? ' flex-fill flex-lg-grow-0' : ''}`}
                variant="primary"
                label={this.translation('btn_send')}
                onClick={() => this.onSendMessage()}
              />
            </div>
          </div>
        </div>
        {this.files.length > 0 &&
          <div class="message-files mt-md-n2 p-2 px-md-4">
            {this.files.map((it) => {
              return (
                <div class="d-inline-flex w-100 my-1">
                  <div class="mr-2">
                    <b-link
                      class="text-danger"
                      aria-label={this.translation('btn_delete')}
                      onClick={() => this.onRemoveFile(it)}
                    >
                      <b-icon-x-circle-fill aria-hidden="true" class="app-icon-lg d-block m-0 align-baseline" />
                    </b-link>
                  </div>
                  <b-link class="w-100 d-flex align-items-baseline" href={`${BackendUrls.urlApi}${BackendUrls.file}/${encodeURIComponent(it.id)}`}>
                    <div class="w-100 ellipsis-string-container">
                      <span>{it.fileName}</span>
                    </div>
                  </b-link>
                </div>
              )
            })}
          </div>
        }
      </div>
    )
  }
}
