import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"

import BackendClientMixin from "@/mixins/BackendClientMixin"

import {BIcon, BIconChatDots, BIconFileEarmarkText, BIconGeoAlt, BIconGeoAltFill, BIconPatchQuestionFill, BIconStar, BIconTag} from "bootstrap-vue"

import {Language, RateType, Rating} from "@/types"
import {RequestDetails} from "@/_modules/request/types"
import {isEmpty} from "@/constants/DataBoundaries"
import {calculatePriceWithPercentage, distanceFormatter, getDistance} from "@/utils/formatters"
import {configStore} from "@/store/config";
import AppIconVerified from "@/components/icons/AppIconVerified"
import AppIconStar from "@/components/icons/AppIconStar"
import AppIconGenderAmbiguous from "@/components/icons/AppIconGenderAmbiguous"
import { authStore } from "@/store"

@Component({
  name: 'RequestProviderDetails',
  components: {
    BIcon,
    BIconChatDots,
    BIconFileEarmarkText,
    BIconGeoAlt,
    BIconGeoAltFill,
    BIconPatchQuestionFill,
    BIconStar,
    BIconTag
  }
})
export default class RequestProviderDetails extends BackendClientMixin {
  @Prop(Object) public readonly request!: RequestDetails
  @Prop(Object) public readonly serviceProviderRating!: Rating

  public get requestDraft(): boolean {
    return isEmpty(this.request.status)
  }

  protected get isRequester(): boolean {
    const userId = authStore.authInfo?.userId
    return (this.requestDraft || this.request.service.provider.userId !== userId)
  }

  public render(): VNode {
    return (
      <div>
        <h4 class="h3 mt-0 mb-3">{this.request.service.provider.name.first} {this.request.service.provider.name.last.charAt(0)}.</h4>
        <div class="d-flex mb-4">
          {this.request.service.provider.verified &&
            <span class="small text-nowrap mr-4 d-flex align-items-center">
              <AppIconVerified class="app-icon-lg mt-0 align-top mr-2 text-success" />
              {this.translation('msg_param_verified_true')}
            </span>
          }
          {!this.request.service.provider.verified &&
            <span class="small text-nowrap mr-4 d-flex align-items-center">
              <b-icon-patch-question-fill class="app-icon-lg mt-0 align-top mr-2" variant="danger-dark" />
              {this.translation('msg_param_verified_false')}
            </span>
          }
          {this.serviceProviderRating.ratingsCount !== 0 &&
            <span class="small text-nowrap d-flex align-items-center">
              <AppIconStar class="app-icon-lg mt-0 align-top mr-2 text-warning" aria-label={this.translation('lbl_rating')} />
              {this.serviceProviderRating.grade} <span aria-hidden="true">({this.serviceProviderRating.ratingsCount})</span>
            </span>
          }
          {this.serviceProviderRating.ratingsCount === 0 &&
            <span class="small text-nowrap d-flex align-items-center">
              <b-icon-star class="app-icon-lg mt-0 align-top text-gray-400 mr-2" aria-hidden="true" />
              {this.translation('lbl_no_rating')}
            </span>
          }
        </div>
        <hr class="w-100 mt-0 mb-6" />
        {this.request.service.providedAtClientPlace &&
          <div class="d-flex mb-4">
            <div class="flex-grow-0 mr-3">
              <b-icon icon={this.isRequester ? 'geo-alt-fill' : 'geo-alt'} class={`app-icon-lg align-top mt-0${this.isRequester ? ' text-success' : ' text-gray-500'}`} aria-hidden={true} />
            </div>
            <div>
              <p class="small mb-0 font-weight-bold">{this.translation('lbl_distance')}</p>
              {this.requestDraft &&
                <span>
                  {distanceFormatter(getDistance(this.request.service.location.coordinates, this.request.requester.location.coordinates), this.translation('text_m'), this.translation('text_km'))}. {this.translation('msg_service_at_requester_location')}
                </span>
              }
              {!this.requestDraft && 
                <span>
                  {distanceFormatter(Number(this.request.service.distanceInMeters), this.translation('text_m'), this.translation('text_km'))}. {this.translation('msg_service_at_requester_location')}
                </span>
              }
            </div>
          </div>
        }
        {!this.request.service.providedAtClientPlace &&
          <div class="d-flex mb-4">
            <div class="flex-grow-0 mr-3">
              <b-icon icon={this.isRequester ? 'geo-alt' : 'geo-alt-fill'} class={`app-icon-lg align-top mt-0${this.isRequester ? ' text-gray-500' : ' text-success'}`} aria-hidden={true} />
            </div>
            <div>
              <p class="small mb-0 font-weight-bold">{this.translation('lbl_address_of_service')}</p>
              <span>
                {`${this.request.service.location.address.address}, ${this.request.service.location.address.zipCode} ${this.request.service.location.address.cityOrCounty}`}
              </span>
            </div>
          </div>
        }
        <div class="d-flex mb-4">
          <div class="flex-grow-0 mr-3">
            <b-icon icon={'chat-dots'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
          </div>
          <div>
            <p class="small mb-0 font-weight-bold">{this.translation('lbl_languages')}</p>
            <span>
              {this.request.service.languages.map((lang, index) => {
                return (
                  lang === Language.ASE ?
                    <span>
                      <b-link class="text-primary" v-b-tooltip={{title: this.translation('enum_language_user_speak_ase')}}>
                        {this.translation('enum_language_user_speak_short_' + lang.toLowerCase())}
                      </b-link>{index !== this.request.service.languages.length - 1 ? ', ' : ''}
                    </span> :
                    <span>
                      {this.translation('enum_language_user_speak_short_' + lang.toLowerCase())}{index !== this.request.service.languages.length - 1 ? ', ' : ''}
                    </span>
                )
              })}
            </span>
          </div>
        </div>
        {this.request.service.provider.sex &&
          <div class="d-flex mb-4">
            <div class="flex-grow-0 mr-3">
              <AppIconGenderAmbiguous class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
            </div>
            <div>
              <p class="small mb-0 font-weight-bold">{this.translation('lbl_sex')}</p>
              <span>{this.translation(`enum_sex_type_${this.request.service.provider.sex?.toLowerCase()}`)}</span>
            </div>
          </div>
        }
        {this.requestDraft &&
        <div>
          <div class="d-flex mb-4">
            <div class="flex-grow-0 mr-3">
              <b-icon icon={'file-earmark-text'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
            </div>
            <div>
              <p class="small mb-0 font-weight-bold">{this.translation('lbl_qualification')}</p>
              <span>{this.translation(`enum_qualification_grade_${this.request.service.qualification}`)}</span>
            </div>
          </div>
          <div class="d-flex mb-4">
            <div class="flex-grow-0 mr-3">
              <b-icon icon={'tag'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
            </div>
            <div>
              <p class="small mb-0 font-weight-bold">{this.translation('lbl_service_price')}</p>
              <span>{calculatePriceWithPercentage(configStore.serverConfig, this.request.service.rate.price)}&nbsp;{this.translation(`enum_currency_${this.request.service.rate.price.currency}`)}{this.request.service.rate.type === RateType.HOURLY_PAYMENT ? this.translation('text_hour_price') : ''}</span>
            </div>
          </div>
        </div>
        }
        {!this.requestDraft &&
          <div>
            <div class="d-flex mb-4">
              <div class="flex-grow-0 mr-3">
                <b-icon icon={'file-earmark-text'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div>
                <p class="small mb-0 font-weight-bold">{this.translation('lbl_qualification')}</p>
                <span>{this.translation(`enum_qualification_grade_${this.request.service.qualification}`)}</span>
              </div>
            </div>
            <div class="d-flex mb-4">
              <div class="flex-grow-0 mr-3">
                <b-icon icon={'tag'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div>
                <p class="small mb-0 font-weight-bold">{this.translation('lbl_service_price')}</p>
                <span>{this.request.service.rate.price.amount}&nbsp;{this.translation(`enum_currency_${this.request.service.rate.price.currency}`)}{this.request.service.rate.type === RateType.HOURLY_PAYMENT ? this.translation('text_hour_price') : ''}</span>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}
