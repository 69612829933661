import moment from "moment"

import {serverDateFormat, serverDateTimeFormat} from "@/constants/ApplicationConfiguration"
import {DateNoTime, DateTime} from "@/types"

export function dateToServerDateNoTime(value: Date): DateNoTime {
  return moment(value).format(serverDateFormat)
}

export function dateToServerDateTime(value: Date): DateTime {
  return moment(value).format(serverDateTimeFormat)
}

export function buildCalendarYearsRange(): string[] {
  const years = []
  const currentDate = moment().toDate()
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  for(let i = -5; i < 5; i++) {
    years.push((currentDate.getFullYear() + i).toString())
  }
  return years
}