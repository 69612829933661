import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import BackendClientMixin from "@/mixins/BackendClientMixin";
import {BasicEvents} from "@/constants/ComponentEvents";

interface Props {
  txt?: string;
  to?: string;
  href?: string;
  cssClass?: string;
  target?: string;
  onClick?(e: Event): void;
}

@Component({name: 'Link'})
export default class extends BackendClientMixin {
  public $props!: Readonly<{class?: string}> & Readonly<Props>
  @Prop(String) public readonly txt!: string
  @Prop(String) public readonly href?: string
  @Prop(String) public readonly to?: string
  @Prop(String) public readonly cssClass?: string
  @Prop(String) public readonly cssStyle?: string
  @Prop(String) public readonly target?: string


  public onClick(e: Event): void {
    this.$emit(BasicEvents.Click, e)
  }
  
  public render(): VNode {
    return (
      <b-link 
        to={this.to}
        href={this.href}
        disabled={this.busy} // property from backend client mixin
        class={this.cssClass}
        style={this.cssStyle}
        onClick={this.onClick}
        target={this.target}
      >
        {this.$slots.label === undefined ? this.txt : this.$slots.label}
      </b-link>
    )
  }
}