export function scrollListner(): void {
  try {
    const currentHeight = window.pageYOffset
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const doc = document as any
    const countdownElem = doc.getElementsByClassName('count-down-popup')[0]
    const countdownElemTopOffset = doc.getElementsByClassName('count-down-popup')[0].offsetTop
    const coundownReplacer = doc.getElementsByClassName('countdown-relative-replacer')[0]
    const coundownReplacerOffset = doc.getElementsByClassName('countdown-relative-replacer')[0].offsetTop
    
    const errorPopup = doc.getElementsByClassName('global-message-popup')[0]
    
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const errorPopupHeight = 40
    
    let actualCurrentHeight = currentHeight
    
    if (errorPopup !== undefined) {
      actualCurrentHeight = currentHeight + errorPopupHeight
    }
    
    if (actualCurrentHeight >= countdownElemTopOffset) {
      doc.body.classList.add('has-count-down')
      countdownElem.classList.add('count-down-popup-fixed')
      coundownReplacer.classList.add('render-default-replacer')
      if (errorPopup) {
        countdownElem.classList.add('count-down-popup-fixed-with-offset')
        coundownReplacer.classList.add('render-extra-offset')
      }
      if (!errorPopup) {
        countdownElem.classList.remove('count-down-popup-fixed-with-offset')
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (actualCurrentHeight < coundownReplacerOffset) {
      doc.body.classList.remove('has-count-down')
      countdownElem.classList.remove('count-down-popup-fixed')
      coundownReplacer.classList.remove('render-default-replacer')
      
      coundownReplacer.classList.remove('render-extra-offset')
      countdownElem.classList.remove('count-down-popup-fixed-with-offset')
      
    }
  } catch (err) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const doc = document as any
    doc.body.classList.remove('has-count-down')
    window.removeEventListener('scroll', scrollListner)
  }
}