import { Component, Prop } from "vue-property-decorator"
import { VNode } from "vue"
import { TsxComponent } from "@/components/TsxComponent";
import { DiaryDataForm, Temperature } from "@/types";
import { BIconXCircleFill } from "bootstrap-vue";
import { appStore } from "@/store";
import { celsiusToFahrenheit } from "@/utils/temperature-utils";
import { isEmpty } from "@/constants/DataBoundaries";

interface Props {
  value: DiaryDataForm;
  disabled?: boolean;
  medicineNote?: string;
}
@Component({ name: 'DiaryEntry', components: { BIconXCircleFill }})
export default class DiaryEntry extends TsxComponent<Props> {
  @Prop(Object) public readonly value!: DiaryDataForm
  @Prop(Boolean) public readonly disabled!: boolean
  @Prop(String) public readonly medicineNote?: string

  private get medications(): string {
    const medications = (this.value.medications! as string[]).map((medication) => (medication.replace(/\.$/, "")));

    return medications!.join(', ');
  }

  private get bodyTemperature(): string | undefined {
    let bodyTemperature = this.value.bodyTemperature;

    if (isEmpty(bodyTemperature)) {
      return bodyTemperature;
    }

    if (appStore.preferredTemperatureScale === Temperature.fahrenheit) {
      bodyTemperature = celsiusToFahrenheit(bodyTemperature!);
    }

    return Number(bodyTemperature).toLocaleString(appStore.locale);
  }

  public render(): VNode {
    return (
      <div class="py-6 pt-md-10 px-4">
        <b-row>
          {this.bodyTemperature && 
            <b-col cols="12" sm="6" lg="4">
              <h5 class="mb-0">
                <span class="mr-1">{this.translation('shared.fld-body-temperature')}</span>
                <span class=" text-nowrap font-weight-normal">
                  <b-button
                    class={`p-0 border-0 align-baseline ${appStore.preferredTemperatureScale === Temperature.celsius ? 'text-body text-decoration-none' : 'text-primary' }`}
                    pressed={appStore.preferredTemperatureScale === Temperature.celsius}
                    variant="link"
                    onClick={() => {appStore.setPreferredTemperatureScale(Temperature.celsius)}}
                    disabled={this.disabled}
                  >
                    {this.translation('shared.enum-temperature-C')}
                  </b-button>
                  <span class="mx-1">|</span>
                  <b-button
                    class={`p-0 border-0 align-baseline ${appStore.preferredTemperatureScale === Temperature.fahrenheit ? 'text-body text-decoration-none' : 'text-primary' }`}
                    pressed={appStore.preferredTemperatureScale === Temperature.fahrenheit}
                    variant="link"
                    onClick={() => {appStore.setPreferredTemperatureScale(Temperature.fahrenheit)}}
                    disabled={this.disabled}
                  >
                    {this.translation('shared.enum-temperature-F')}
                  </b-button>
                </span>
              </h5>
              <div class="d-flex align-items-end mb-4 mb-md-6">
                <span class="display-2 mb-1">{this.bodyTemperature}</span>
                <span class="ml-1 lead mb-2">{this.translation(`shared.enum-temperature-${appStore.preferredTemperatureScale}`)}</span>
              </div>
            </b-col>
          }
          {this.value.heartRate && 
            <b-col cols="12" sm="6" lg="4">
              <h5 class="mb-0">{this.translation('shared.fld-heart-rate')}</h5>
              <div class="d-flex align-items-end mb-4 mb-md-6">
                <span class="display-2 mb-1">{this.value.heartRate}</span>
                <span class="ml-1 lead mb-2">{this.translation('shared.fld-heart-rate-append')}</span>
              </div>
            </b-col>
          }
          {this.value.bloodPressure && 
            <b-col cols="12" sm="6" lg="4">
              <h5 class="mb-0">{this.translation('shared.fld-blood-pressure')}</h5>
              <div class="d-flex align-items-end mb-4 mb-md-6">
                <span class="display-2 mb-1">{this.value.bloodPressure}</span>
                <span class="ml-1 lead mb-2">{this.translation('shared.fld-blood-pressure-append')}</span>
              </div>
            </b-col>
          }
          {this.value.bloodSugarLevel && 
            <b-col cols="12" sm="6" lg="4">
              <h5 class="mb-0">{this.translation('shared.fld-blood-sugar-level')}</h5>
              <div class="d-flex align-items-end mb-4 mb-md-6">
                <span class="display-2 mb-1">{Number(this.value.bloodSugarLevel).toLocaleString(appStore.locale)}</span>
                <span class="ml-1 lead mb-2">{this.translation('shared.fld-blood-sugar-level-append')}</span>
              </div>
            </b-col>
          }
          {this.value.saturation && 
            <b-col cols="12" sm="6" lg="4">
              <h5 class="mb-0">{this.translation('shared.fld-saturation')}</h5>
              <div class="d-flex align-items-end mb-4 mb-md-6">
                <span class="display-2 mb-1">{Number(this.value.saturation).toLocaleString(appStore.locale)}</span>
                <span class="ml-1 lead mb-2">{this.translation('shared.fld-saturation-append')}</span>
              </div>
            </b-col>
          }
          {this.value.urineVolume && 
            <b-col cols="12" sm="6" lg="4">
              <h5 class="mb-0">{this.translation('shared.fld-urine-volume')}</h5>
              <div class="d-flex align-items-end mb-4 mb-md-6">
                <span class="display-2 mb-1">{Number(this.value.urineVolume).toLocaleString(appStore.locale)}</span>
                <span class="ml-1 lead mb-2">{this.translation('shared.fld-urine-volume-append')}</span>
              </div>
            </b-col>
          }
        </b-row>
        <b-row>
          {this.medications &&
            <b-col cols="12" md={this.value.notes ? '6' : undefined}>
              <h5 class="mb-0">{this.translation('shared.fld-medications')}</h5>
              <p class="lead">
                {this.medications}
              </p>
            </b-col>
          }
          {this.value.notes && 
            <b-col cols="12" md={this.medications ? '6' : undefined}>
              <h5 class="mb-0">{this.translation('shared.fld-notes')}</h5>
              <p class="lead">
                {this.value.notes}
              </p>
            </b-col>
          }
        </b-row>
      </div>
    )
  }
}
