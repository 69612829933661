import Vue from "vue"
import {Component} from "vue-property-decorator"
import {VNode} from "vue"

@Component({name: 'AppIconChevronLeft'})
export default class extends Vue {
  public render(): VNode {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" class="app-icon" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 4l-8 8 8 8"/>
      </svg>
    )
  }
}
