import { VNode } from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import { AbstractInputComponent, AbstractInputPropsType } from "@/components/form/AbstractInputComponent"

import { TextInputType } from "@/constants/Elements"
import { isEmpty } from "@/constants/DataBoundaries"
import { BasicEvents } from "@/constants/ComponentEvents"

type ValueType = string | undefined

interface PropsType extends AbstractInputPropsType<ValueType> {
  value?: string;
}

@Component({name: "BloodPressureInput"})
export default class extends AbstractInputComponent<ValueType, PropsType> implements PropsType {
  @Prop([String]) public readonly value?: ValueType

  public valueParts = {
    systolic: '',
    diastolic: ''
  }

  public isEmpty(): boolean {
    if (this.value === undefined) {
      return true
    } else if (typeof this.value === "string") {
      return this.value.length === 0
    } else {
      return false
    }
  }

  public get state(): boolean | undefined {
    return this.disabled ? undefined : (this.invalidMessage !== undefined ? false : undefined)
  }

  private setBloodPressure(): void {
    let bloodPressure = this.value;

    if (!isEmpty(this.valueParts.systolic)) {
      bloodPressure = this.valueParts.systolic;
    }

    if (!isEmpty(this.valueParts.diastolic)) {
      bloodPressure = `${bloodPressure}/${this.valueParts.diastolic}`;
    }

    this.$emit(BasicEvents.Input, bloodPressure);
  }

  @Watch('value')
  public resetValueParts(): void {
    if (isEmpty(this.value)) {
      this.valueParts = {
        systolic: '',
        diastolic: ''
      };
    }
  }

  public mounted(): void {
    if (!isEmpty(this.value)) {
      const bloodPressureParts = this.value!.split('/');

      this.valueParts.systolic = bloodPressureParts[0];

      if (bloodPressureParts.length > 1) {
        this.valueParts.diastolic = bloodPressureParts[1];
      }
    }
  }

  public render(): VNode {
    return (
      <b-form-group
        label={this.translation('shared.fld-blood-pressure')}
        label-for="blood-pressure-input"
        invalid-feedback={this.disabled ? undefined : this.invalidMessage}
        state={this.state}
      >
        <b-input-group>
          <b-form-input
            class={{'border-danger': this.state === false}}
            disabled={this.disabled}
            min="0"
            id="blood-pressure-input"
            type={TextInputType.Number}
            v-model={this.valueParts.systolic}
            onInput={this.setBloodPressure}
          />
          <div class="input-group-append input-group-prepend">
            <div class={["input-group-text px-2", this.state === false ? 'border-danger' : 'border-gray-400']}>/</div>
          </div>
          <b-form-input
            class="border-right-0"
            disabled={this.disabled}
            min="0"
            state={this.state}
            type={TextInputType.Number}
            v-model={this.valueParts.diastolic}
            onInput={this.setBloodPressure}
          />
          <b-input-group-append>
            <div class="input-group-text pl-0">
              {this.translation('shared.fld-blood-pressure-append')}
            </div>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    )
  }
}
