import {AuthorService, ClientAge, ClientDisease, ClientMentalAbility, ClientPhysicalActivity, ClientWeight, DealStatus, FileData, GeoLocation, Language, Price, PriceValidation, Qualification, Rate, Sex, UserId, InitialTaskData, RecurrenceRule, PricesTaxes} from '@/types'
import {dateToServerDateTime} from "@/utils/date-utils";
import {RequesterProtected} from "@/_modules/demand/types";
import {ProviderProtected} from "@/_modules/proposal/types";
import {ValidationObject} from "@/utils/vuelidate-extension";
import {ServiceType} from "@/_modules/service/types";
import {RecursivePartial} from "@/utils/typescript-library-extensions";

export interface OccurrenceEventState {
  //id: string;
  requesterUserId?: string;
  requesterUserName?: string;
  providerUserId?: string;
  providerUserName?: string;
  dealType?: string;
  dealId?: string;
  dealName?: string;
  dealStatus?: string;
  completed: boolean;
  confirmed: boolean;
  parentId: string;
  firstTaskStart: Date;
  lastTaskEnd?: Date;
  reminder: string;
}

export interface TaskInternalOccurrence {
  id?: string;
  start: Date;
  end: Date;
  bgColor: string;
  body: string;
  title: string;
  recurrenceRule: RecurrenceRule;
  state: OccurrenceEventState;
}

export interface ServerOccurrence {
  id: string;
  start: string;
  end: string;
  completed?: boolean;
  confirmed?: boolean;
}

export interface TaskDefinition {
  id?: string;
  start: string;
  end: string;
  recurrenceRule: RecurrenceRule;
  serviceCategories?: string[];
  occurrences: TaskInternalOccurrence[];
}

export interface InternalTaskData {
  id: string;
  title?: string;
  body: string;
  start: string;
  end: string;
  requesterUserId?: string;
  requesterUserName?: string;
  providerUserId?: string;
  providerUserName?: string;
  dealType?: string;
  dealId?: string;
  dealName?: string;
  bgColor?: string;
  dealStatus?: string;
  recurrenceRule: RecurrenceRule;
  occurrences?: TaskInternalOccurrence[];
  state: OccurrenceEventState;
}

export interface ProviderReq extends AuthorService {
  userId: UserId;
}

export interface ServiceFromRequest {
  id?: string;
  serviceId?: string;
  category: string;
  description_ru?: string;
  description_et?: string;
  description_en?: string;
  scheduleInfo_ru?: string;
  scheduleInfo_et?: string;
  scheduleInfo_en?: string;
  provider: ProviderProtected;
  rate: Rate;
  languages: Language[];
  title: string;
  location: GeoLocation;
  qualification: Qualification;
  providedAtClientPlace: boolean;
  distanceInMeters: string;
  clientAge: ClientAge[];
  clientSex: Sex[];
  clientWeight: ClientWeight[];
  clientDisease: ClientDisease[];
  clientPhysicalActivity: ClientPhysicalActivity[];
  clientMentalAbility: ClientMentalAbility[];
  competencesWithParent: RecursivePartial<ServiceType>[];
}

export interface AcceptReqVersionPayload {
  requestId: string;
  reqVersion: number;
  termsAccepted: boolean;
}

interface RequestLockedBy {
  first: string;
  last: string;
}

export interface LockState {
  status: DealStatus | string;
  timeLeftInSeconds: number;
  lockedBy: RequestLockedBy;
}

export interface ServiceRequest {
  rate: Rate;
  location: GeoLocation;
  distanceInMeters: number;

}

export enum RatedBy {
  Nobody = "NOBODY",
  Requester = "REQUESTER",
  Provider = "PROVIDER",
  Both = "BOTH"
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RequesterPrivateValidation extends ValidationObject <RequesterProtected> {

}

export interface RequestDetailsValidation extends ValidationObject<RequestDetails> {
  price: PriceValidation;
  requester: RequesterPrivateValidation;
}

export interface RequestDetails extends NewRequest {
  id?: string;
  title: string;
  requester: RequesterProtected;
  service: ServiceFromRequest;
  tasks: InitialTaskData[];
  price: PricesTaxes;
  lockState: LockState;
  changesToAccept?: string[];
  version?: number;
  status: DealStatus | string;
  acceptedLatestVersion?: boolean;
  servicePriceOverrided: boolean;
  isRatedBy: RatedBy | string;
  createdAt: Date;
  agreementFileId: string;
  agreementNumber: string;
  calculatePrice?: boolean;
  demandDescription_ru?: string;
  demandDescription_et?: string;
  demandDescription_en?: string;
  clientSex?: Sex;
  clientAge?: ClientAge;
  clientWeight?: ClientWeight;
  clientDisease?: ClientDisease[];
  clientPhysicalActivity?: ClientPhysicalActivity[];
  clientMentalAbility?: ClientMentalAbility;
  clientRequirementsWithParent: RecursivePartial<ServiceType>[];
}

export interface NewRequest extends UpdateRequest{
  serviceId: string;
  location?: GeoLocation;
  languages: Language[];
  demandId?: string;
  clientSex?: Sex;
  clientWeight?: string | ClientWeight;
  tasks: InitialTaskData[];
}

export interface UpdateRequest {
  price?: Price | PricesTaxes;
}

interface ServiceFee {
  amount: string;
}
interface ServiceTax {
  percentage: string;
}
export interface ServicesRates {
  serviceFee: ServiceFee;
  serviceTax: ServiceTax;
}

export interface RequestUpdatePayload {
  requestId: string;
  tasks: InitialTaskData[];
  price?: Price;
  calculatePrice?: boolean;
  //donate?: Price;
}

export interface RequestSummary {
  id: string;
  name: string;
  createdAt: string;
  proposals: number;
  active: string;
}

export interface RequestsSummary {
  items: RequestSummary[];
  nr: number;
  size: number;
  total: number;
}

export interface RequestNewMessagePayload {
  message: Message;
  requestId: string;
}

export interface Message {
  id?: string;
  message: string;
  userId?: string;
  createdAt?: string;
  uploadToken?: string;
  attachments: FileData[];
  lastInBatch?: boolean;
}

export interface CalendarDefinition {
  id: string;
  name: string;
}

export interface EventFromCalendarWithTaskData {
  id?: string;
  calendarId: string;
  title: string;
  start: Date;
  end: Date;
  body: string;
  bgColor: string;
  category: string;
  completed: boolean;
  confirmed: boolean;
  recurrenceRule: RecurrenceRule;
  occurrences: TaskInternalOccurrence[];
  serviceCategories?: string[];
  state: Partial<OccurrenceEventState>;
}

export type CalendarScheduleForm = Partial<EventFromCalendarWithTaskData>

export function buildTaskFromCalendarEvent(form: EventFromCalendarWithTaskData): TaskDefinition {
  return {
    id: form.state.parentId,
    start: dateToServerDateTime(form.start),
    end: dateToServerDateTime(form.end),
    recurrenceRule: form.recurrenceRule,
    serviceCategories: form.serviceCategories,
    occurrences: [{
      title: form.title,
      start: form.start,
      end: form.end,
      body: form.body,
      bgColor: form.bgColor,
      recurrenceRule: form.recurrenceRule,
      state: {
        completed: form.completed,
        confirmed: form.confirmed,
        parentId: form.state.parentId!,
        firstTaskStart: form.state.firstTaskStart!,
        reminder: form.state.reminder!
      }
    }]
  }
}

export interface RequestPriceAndTasks {
  requestId?: string;
  serviceRate?: Rate;
  servicePrice?: Price;
  calculatePrice?: boolean;
  donate?: Price;
  tasks: InitialTaskData[];
}

export interface RequestTaskAsCompleteOrConfirmPayload {
  requestId: string;
  taskId: string;
}
