import {Component, Ref} from 'vue-property-decorator'
import {VNode} from 'vue'

import BackendClientMixin from "@/mixins/BackendClientMixin"

import IncomeOverview from "@/_modules/activity/components/ServicesTurnover"
import ExpensesOverview from "@/_modules/activity/components/DemandsTurnover"

import {userStore} from "@/store/user-store"
import {authStore} from "@/store"

import {serverDateFormat} from "@/constants/ApplicationConfiguration"
import {InternalTaskData, RequestTaskAsCompleteOrConfirmPayload, TaskDefinition} from '@/_modules/request/types'
import {DealType, Turnover, UsersTasksPayload, UserTurnoverPayload} from "@/types"
import {} from "@/_modules/request/data-builders"
import moment from "moment"
import {convertTasksSeriesToInternalUse} from '@/_modules/shared-builders'
import ContentSection from '@/components/layout/ContentSection'
import PageHeader from '@/components/layout/PageHeader'
import MonthPicker from '@/components/form/MonthPicker'
import { BIconArrowLeft, BIconArrowRight, BIconCalendar4Week, BIconListUl } from 'bootstrap-vue'
import AssistEarn from '@/components/AssistEarn'
import CompactScheduleCalendar, { TasksCalendarInterface } from '@/components/calendar/CompactScheduleCalendar'
import { requestServiceStore } from '@/_modules/request/store/request-service-store'
import { RequestErrorHandler } from '@/_modules/request/views/Request'
import { ProposalTaskAsCompletePayload } from '@/_modules/proposal/types'
import { proposalDemandStore } from '@/_modules/proposal/store/proposal-demand-store'
import { ProposalErrorHandler } from '@/_modules/proposal/views/Proposal'
import { CustomEvents } from '@/constants/ComponentEvents'
import { EventBus } from '@/main'
import ScheduleList from '@/components/calendar/ScheduleList'

interface Calendar {
  startFrom: Date;
}

interface Form {
  usersTurnover: Partial<Turnover>;
  year: string;
  month: string;
  calendar: Calendar;
}

const requestErrorHandler = new RequestErrorHandler()
const proposalErrorHandler = new ProposalErrorHandler()

@Component({
  name: 'Overview',
  components: {
    BIconArrowLeft,
    BIconArrowRight,
    BIconCalendar4Week,
    BIconListUl 
  }
})
export default class extends BackendClientMixin {

  @Ref('tasksCalendar') public readonly tasksCalendar!: TasksCalendarInterface

  public form: Form = {
    usersTurnover: {},
    year: moment().toObject().years.toString(),
    month: moment().toObject().months.toString(),
    calendar: {
      startFrom: new Date()
    }
  }

  private selectedMonth: moment.Moment = moment().startOf('month')

  private scheduleList: TaskDefinition[] = []

  private showCalendarComponent: boolean = true;

  public async mounted(): Promise<void> {
    await this.fetchTurnover()
    await this.fetchCalendar(moment().startOf('isoWeek').toDate())
  }

  public async fetchTurnover(): Promise<void> {
    const userId = authStore.authInfo?.userId
    if (userId !== undefined) {
      const payload: UserTurnoverPayload = {
        userId: userId,
        year: this.form.year,
        month: this.form.month
      }
      this.form.usersTurnover = await this.withRequest(userStore.getUsersTurnover(payload))
    }
  }

  public async fetchCalendar(weekStartDate: Date): Promise<void> {
    const userId = authStore.authInfo?.userId
    if (userId !== undefined) {
      const date = moment(weekStartDate).format(serverDateFormat)
      const payload: UsersTasksPayload = {
        userId: userId,
        weekStartDate: date
      }
      const resp = await this.withRequest(userStore.getUserTasks(payload))
      const result = convertTasksSeriesToInternalUse(resp)
      this.scheduleList = result.map(it => {
        it.occurrences.forEach(o => (o.title = `${o.state!.dealName}`))
        return it
      })
    }
  }

  private async onSetTaskComplete(occurrenceId: string, task: InternalTaskData): Promise<void> {
    if (task.dealType === DealType.Proposal.toUpperCase()) {
      // Set proposal task occurrence as complete
      const taskAsCompletePayload: ProposalTaskAsCompletePayload = {
        proposalId: task.dealId!,
        taskId: occurrenceId
      }

      await this.withRequest(proposalDemandStore.setTaskComplete(taskAsCompletePayload), proposalErrorHandler, true)

    } else if (task.dealType === DealType.Request.toUpperCase()) {
      // Set request task occurrence as complete
      const requestTaskAsCompletePayload: RequestTaskAsCompleteOrConfirmPayload = {
        requestId: task.dealId!,
        taskId: occurrenceId
      }

      await this.withRequest(requestServiceStore.setTaskComplete(requestTaskAsCompletePayload), requestErrorHandler, true)
    }

    await this.fetchCalendar(this.tasksCalendar.weekStartDate())
    this.messagesPayload.successMessageKey = 'msg_task_completed'
    EventBus.$emit(CustomEvents.FlashMsg, this.messagesPayload)
    EventBus.$on(CustomEvents.DismissMsg, () => (this.messagesPayload.successMessageKey = null))
  }

  private async onSetTaskCompleteConfirm(occurrenceId: string, task: InternalTaskData): Promise<void> {
    if (task.dealType === DealType.Proposal.toUpperCase()) {
      const taskAsCompleteConfirmPayload: ProposalTaskAsCompletePayload = {
        proposalId: task.dealId!,
        taskId: occurrenceId
      }

      await this.withRequest(proposalDemandStore.setTaskCompleteConfirm(taskAsCompleteConfirmPayload), proposalErrorHandler, true)

    } else if (task.dealType === DealType.Request.toUpperCase()) {
      // Set request task occurrence as confirmed
      const requestTaskAsCompleteConfirmPayload: RequestTaskAsCompleteOrConfirmPayload = {
        requestId: task.dealId!,
        taskId: occurrenceId
      }

      await this.withRequest(requestServiceStore.setTaskCompleteConfirm(requestTaskAsCompleteConfirmPayload), requestErrorHandler, true)
    }

    await this.fetchCalendar(this.tasksCalendar.weekStartDate())
    this.messagesPayload.successMessageKey = 'msg_task_confirmed'
    EventBus.$emit(CustomEvents.FlashMsg, this.messagesPayload)
    EventBus.$on(CustomEvents.DismissMsg, () => (this.messagesPayload.successMessageKey = null))
  }

  public onClickAllTasksNav(direction: string): void {
    let newDate = moment(this.selectedMonth);

    if (direction === 'prev') {
      newDate.subtract(1, 'month')
    } else if (direction === 'next') {
      newDate.add(1, 'month')
    } else {
      newDate = moment()
    }

    this.setSelectedMonth(newDate)
  }

  public setSelectedMonth(date: moment.Moment): void {
    this.selectedMonth = date
    this.form.month = this.selectedMonth.get('month').toString();
    this.form.year = this.selectedMonth.get('year').toString();

    this.fetchTurnover();
  }

  public render(): VNode {
    return(
      <b-container fluid="xl">
        <b-row>
          <PageHeader
            headerClass="my-10 mt-lg-4"
            title={this.translation('menu_overview')}
            wideHeader={true}
          />
        </b-row>
        <ContentSection class="mb-6">
          <h2 class="fieldset-title">{this.translation('title_completed_tasks')}</h2>
          <div class="compact-schedule-controls">
            <MonthPicker
              minimumView="month"
              maximumView="month"
              noFutureDate={true}
              value={this.selectedMonth.toDate()}
              format="MMMM yyyy"
              onInput={(date: Date | null) => {
                if (date instanceof Date) {
                  this.setSelectedMonth(moment(date))
                }
              }}
            />
            <div class="d-flex justify-content-center my-2 my-sm-0 mx-sm-4">
              <b-link
                class="text-primary btn-link d-flex p-3"
                onClick={() => this.onClickAllTasksNav('prev')}
                aria-label={this.translation('lbl_previous_month')}
              >
                <b-icon-arrow-left class="app-icon-lg mt-0" aria-hidden="true" />
              </b-link>
              <b-link
                class="text-primary btn-link d-flex p-3"
                disabled={this.selectedMonth.isSame(moment(), 'month')}
                onClick={() => this.onClickAllTasksNav('next')}
                aria-label={this.translation('lbl_next_month')}
              >
                <b-icon-arrow-right class="app-icon-lg mt-0" aria-hidden="true" />
              </b-link>
            </div>
            <b-button class="text-nowrap d-none d-md-inline-block mr-4" variant="outline-primary" onClick={() => this.onClickAllTasksNav('current')}>
              {this.translation('lbl_current_month')}
            </b-button>
          </div>
          <b-row class="row-sm-narrow mt-sm-4 mt-md-8">
            <b-col cols="12" md="6" class="mb-4 mb-md-0">
              {this.form.usersTurnover.income && 
                <IncomeOverview
                  value={this.form.usersTurnover.income}
                />
              }
            </b-col>
            <b-col cols="12" md="6">
              {this.form.usersTurnover.expenses && 
                <ExpensesOverview
                  value={this.form.usersTurnover.expenses}
                />
              }
            </b-col>
          </b-row>
        </ContentSection>
        <ContentSection class="mb-6">
          <h2 class="fieldset-title">{this.translation('title_tasks')}</h2>
          <b-row>
            <b-col>
              <CompactScheduleCalendar
                disabled
                ref="tasksCalendar"
                value={this.scheduleList}
                showCalendarComponent={this.showCalendarComponent}
                onWeekChanged={(date: Date) => this.fetchCalendar(date)}
                onSetTaskComplete={(occurrenceId: string, task: InternalTaskData) => {this.onSetTaskComplete(occurrenceId, task)}}
                onSetTaskCompleteConfirm={(occurrenceId: string, task: InternalTaskData) => {this.onSetTaskCompleteConfirm(occurrenceId, task)}}
                isOverview
              >
                <template slot="headerControls">
                  <b-button-group class="ml-auto mt-4">
                    <b-button
                      aria-label={this.translation('lbl_show_calendar')}
                      pressed={this.showCalendarComponent}
                      class="d-flex justify-content-center px-3"
                      variant={this.showCalendarComponent ? 'primary' : 'outline-primary'}
                      onClick={() => {
                        this.showCalendarComponent = true
                      }}
                    >
                      <b-icon-calendar4-week class="app-icon-lg d-flex" aria-hidden="true"/>
                    </b-button>
                    <b-button
                      aria-label={this.translation('lbl_show_tasks_list')}
                      pressed={!this.showCalendarComponent}
                      class="d-flex justify-content-center px-3"
                      variant={this.showCalendarComponent ? 'outline-primary' : 'primary'}
                      onClick={() => {
                        this.showCalendarComponent = false
                      }}
                    >
                      <b-icon-list-ul class="app-icon-lg d-flex" aria-hidden="true"/>
                    </b-button>
                  </b-button-group>
                </template>
              </CompactScheduleCalendar>
              {!this.showCalendarComponent && this.scheduleList.length > 0 && this.tasksCalendar &&
                <ScheduleList
                  canComplete={false}
                  canConfirm={false}
                  disabled={true}
                  selectedWeek={this.tasksCalendar!.selectedWeekDateRange()}
                  showLockBtn={false}
                  value={this.tasksCalendar!.filteredTasks}
                />
              }
            </b-col>
          </b-row>
        </ContentSection>
        <AssistEarn />
      </b-container>
    )
  }
}
