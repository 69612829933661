import { Component, Prop } from "vue-property-decorator"
import { VNode } from "vue"
import { TsxComponent } from "@/components/TsxComponent"
import ContentSection from "./ContentSection"
import random from "@/utils/random"
import AppIconChevronLeft from "../icons/AppIconChevronLeft"

interface Props {
  id?: string;
  title: string;
  visible?: boolean;
}

@Component({name: 'CollapseSection'})
export default class extends TsxComponent<Props> implements Props {
  @Prop({ type: String, required: true }) public readonly title!: string
  @Prop({ type: String, required: false, default: () => random.generateElementId() }) public readonly id!: string
  @Prop({ type: Boolean, required: false, default: () => false }) public readonly visible?: boolean

  public render(): VNode {
    return (
      <ContentSection>
        <b-link class="d-flex collapse-toggle" v-b-toggle={this.id}>
          <h2 class="d-flex justify-content-center align-items-center flex-row w-100 m-0">
            <span class="fieldset-title flex-fill mr-4">
              {this.title}
            </span>
            <div class="app-icon-collapse">
              <AppIconChevronLeft class="app-icon-lg text-primary mt-0" />
            </div>
          </h2>
        </b-link>
        <b-collapse
          id={this.id}
          role="tabpanel"
          visible={this.visible}
        >
          <div class="pt-2">
            {this.$slots.default}
          </div>
        </b-collapse>
      </ContentSection>
    )
  }
}
