import lodash from "lodash"
import {InitialTaskData} from '@/types'
import {convertTasksSeriesToInternalUse} from '@/_modules/shared-builders'
import {TaskDefinition} from '@/_modules/request/types'

export function buildBusyTimeSchedule(busyList: InitialTaskData[], scheduleList: TaskDefinition[]): TaskDefinition[] {
  const diffTasks = lodash.difference(busyList.map(iter => iter.id), scheduleList.map(it => it.id))
  const result = busyList.filter(it => {
    return diffTasks.find(iter => {
      return iter === it.id
    })
  })
  return convertTasksSeriesToInternalUse(result)
}

export function checkIfWindowWasUnfocused(values: boolean[]): boolean | undefined {
  return values.find((it, i) => {
    if (i !== 0 && values.length > 1 && values.find(iter => {return iter})) {
      if (!it) {
        return !it
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  })
}