import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import VueMultiSelect from "vue-multiselect"

import {BIcon, BIconChatDots, BIconGeoAlt, BIconGeoAltFill, BIconPatchQuestionFill, BIconStar} from "bootstrap-vue"

import {RequestDetails} from "@/_modules/request/types"
import {isEmpty} from "@/constants/DataBoundaries"
import {Address, ClientWeight, Language, Rating, Sex} from "@/types"
import FormMixin from "@/mixins/FormMixin"
//import {isEqual} from "lodash"
import {GmapAddressComponents} from "@/store/map"
import RadioGroupInput from "@/components/form/RadioGroupInput"
import I18nOptions, { SelectOption } from "@/utils/I18nOptions"
import {ValidationInstance} from "@/utils/vuelidate-extension"
import {distanceFormatter, getDistance} from "@/utils/formatters"
import CheckBoxGroupInput from "@/components/form/CheckBoxGroupInput";
import AppIconVerified from "@/components/icons/AppIconVerified"
import AppIconStar from "@/components/icons/AppIconStar"
import AppIconGenderAmbiguous from "@/components/icons/AppIconGenderAmbiguous"
import AppIconScale from "@/components/icons/AppIconScale"
import { authStore } from "@/store"

interface Props {
  request: RequestDetails;
  serviceRequesterRating: Rating;
  v?: object;
}

@Component({name: 'RequesterDetails', components: {
  BIcon,
  BIconChatDots,
  BIconGeoAlt,
  BIconGeoAltFill,
  BIconPatchQuestionFill,
  BIconStar
}})
export default class extends FormMixin implements Props {
  @Prop(Object) public readonly request!: RequestDetails
  @Prop(Object) public readonly serviceRequesterRating!: Rating
  @Prop(Object) public readonly v!: ValidationInstance<RequestDetails>;

  private get address(): string {
    const address: Address = this.request.requester.location.address
    return String([address.address, address.cityOrCounty]).split(',').join(' ')
  }

  private get requestDraft(): boolean {
    return isEmpty(this.request.status)
  }

  protected get isRequester(): boolean {
    const userId = authStore.authInfo?.userId
    return (this.requestDraft || this.request.service.provider.userId !== userId)
  }

  private get isAddressCorrect(): boolean {
    return !Object.values(this.request.requester.location!.address!).some(it => isEmpty(it))
  }

  private get isAddressEmpty(): boolean {
    return ((isEmpty(this.request.requester.location.address.address))
      && (isEmpty(this.request.requester.location.address.cityOrCounty))
    && (isEmpty(this.request.requester.location.address.zipCode)))
  }

  private get clientWeightOptions(): SelectOption<string>[] {
    return I18nOptions.buildEnumOptions(this.$i18n, Object.values(ClientWeight), 'shared.enum-client-weight-', true);
  }

  private parseAddress(adr: GmapAddressComponents[], key: string): GmapAddressComponents | undefined {
    const result = adr.filter((it) => {
      return it.types.some((iter) => {
        return iter === key
      })
    })
    if (result[0] !== undefined) {
      return result[0]
    } else {
      return undefined
    }
  }

  private formatAddress(street?: string, streetNum?: string): string {
    if (street !== undefined && streetNum !== undefined) {
      return `${street} ${streetNum}`
    } else if (street !== undefined && streetNum === undefined) {
      return street
    } else if (street === undefined && streetNum !== undefined) {
      return ''
    } else {
      return ''
    }
  }

  private onLocationChanged(place: google.maps.places.PlaceResult): void {

    this.request.requester.location.address.address = ''
    this.request.requester.location.address.cityOrCounty = ''
    this.request.requester.location.address.zipCode = ''

    const location = place.geometry?.location
    const adrComp = place.address_components

    if (location !== undefined && place.address_components !== undefined) {

      this.request.requester.location.address.zipCode = this.parseAddress(adrComp!, 'postal_code') !== undefined ? this.parseAddress(adrComp!, 'postal_code')!.long_name : ''
      this.request.requester.location.address.address = this.formatAddress(this.parseAddress(adrComp!, 'route') !== undefined ? this.parseAddress(adrComp!, 'route')!.long_name : undefined, this.parseAddress(adrComp!, 'street_number') !== undefined ? this.parseAddress(adrComp!, 'street_number')!.long_name : undefined)
      this.request.requester.location.address.cityOrCounty = this.formatAddress(this.parseAddress(adrComp!, 'locality') !== undefined ? this.parseAddress(adrComp!, 'locality')!.long_name : '', this.parseAddress(adrComp!, 'sublocality') !== undefined ? this.parseAddress(adrComp!, 'sublocality')!.long_name : undefined)
      this.request.requester.location.address.country = this.parseAddress(adrComp!, 'country') !== undefined ? this.parseAddress(adrComp!, 'country')!.short_name : ''

      this.request.requester.location.coordinates = {
        latitude: location.lat(),
        longitude: location.lng()
      }
    }
  }

  public render(): VNode {
    return (
      <div>
        <h4 class="h3 mt-0 mb-3">{this.request.requester.name.first} {this.request.requester.name.last}</h4>
        <div class="d-flex mb-4">
          {this.request.requester.verified &&
            <span class="small text-nowrap mr-4 d-flex align-items-center">
              <AppIconVerified class="app-icon-lg mt-0 align-top mr-2 text-success" />
              {this.translation('msg_param_verified_true')}
            </span>
          }
          {!this.request.requester.verified &&
            <span class="small text-nowrap mr-4 d-flex align-items-center">
              <b-icon-patch-question-fill class="app-icon-lg mt-0 align-top mr-2" variant="danger-dark" />
              {this.translation('msg_param_verified_false')}
            </span>
          }
          {this.serviceRequesterRating.ratingsCount !== 0 &&
            <span class="small text-nowrap d-flex align-items-center">
              <AppIconStar class="app-icon-lg mt-0 align-top mr-2 text-warning" aria-label={this.translation('lbl_rating')} />
              {this.serviceRequesterRating.grade} <span aria-hidden="true">({this.serviceRequesterRating.ratingsCount})</span>
            </span>
          }
          {this.serviceRequesterRating.ratingsCount === 0 &&
            <span class="small text-nowrap d-flex align-items-center">
              <b-icon-star class="app-icon-lg mt-0 align-top text-gray-400 mr-2" aria-hidden="true" />
              {this.translation('lbl_no_rating')}
            </span>
          }
        </div>
        <hr class="w-100 mt-0 mb-6" />
        {!this.request.service.providedAtClientPlace &&
          <div class="d-flex mb-4">
            <div class="flex-grow-0 mr-3">
              <b-icon icon={this.isRequester ? 'geo-alt' : 'geo-alt-fill'} class={`app-icon-lg align-top mt-0${this.isRequester ? ' text-gray-500' : ' text-success'}`} aria-hidden={true} />
            </div>
            <div>
              <p class="small mb-0 font-weight-bold">{this.translation('lbl_distance')}</p>
              {this.requestDraft &&
                <span>
                  {distanceFormatter(getDistance(this.request.service.location.coordinates, this.request.requester.location.coordinates), this.translation('text_m'), this.translation('text_km'))}. {this.translation('msg_service_at_provider_location')}
                </span>
              }
              {!this.requestDraft &&
                <span>
                  {distanceFormatter(Number(this.request.service.distanceInMeters), this.translation('text_m'), this.translation('text_km'))}. {this.translation('msg_service_at_provider_location')}
                </span>
              }
            </div>
          </div>
        }
        {this.requestDraft &&
          <div>
            {this.request.service.providedAtClientPlace &&
              <div class="d-flex mb-4">
                <div class="flex-grow-0 mr-3">
                  <b-icon icon={'geo-alt-fill'} class={'app-icon-lg align-top mt-0 text-success'} aria-hidden={true} />
                </div>
                <div class="flex-fill">
                  <label for="service-address" class="small mb-0 font-weight-bold d-block">{this.translation('lbl_address_of_service')}</label>
                  <gmap-autocomplete
                    id="service-address"
                    class="form-control mt-1"
                    value={this.address.trim()}
                    placeholder={this.translation('fld_service_location')}
                    onplace_changed={this.onLocationChanged}
                    select-first-on-enter={true}
                    components={{country: 'ee'}} //TODO: filter not working
                  />
                  {!this.isAddressCorrect && 
                    <span class="text-danger">
                      {(!this.isAddressCorrect && !this.isAddressEmpty) &&
                        <span>{this.translation('err_incorrect_address')}</span>
                      }
                      {/* empty span required, validation watcher search for previous element sibling to start scrolling */}
                      <span />
                      <span class="invalid-feedback d-block">
                        {this.buildInvalidMessage(this.v.requester.location.address.address) &&
                          <span>{this.translation('err_input_field_value_incorrect')}</span>
                        }
                      </span>
                    </span>
                  }
                </div>
              </div>
            }
            <div class="d-flex">
              <div class="flex-grow-0 mr-3">
                <b-icon icon={'chat-dots'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div>
                <p class="small mb-0 font-weight-bold">{this.translation('lbl_user_languages')}</p>
                <CheckBoxGroupInput
                  checkBoxGroupClass="my-1"
                  v-model={this.request.requester.languages}
                  options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(Language), 'enum_language_user_speak_short_')}
                  invalid-message={this.buildInvalidMessage(this.v.requester.languages)}
                />
              </div>
            </div>
            <div class="d-flex mb-4">
              <div class="flex-grow-0 mr-3">
                <AppIconGenderAmbiguous class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div>
                <p class="small mb-0 font-weight-bold">{this.translation('lbl_requester_sex')}</p>
                <span>{this.translation(`enum_sex_type_${this.request.requester.sex?.toLowerCase()}`)}</span>
              </div>
            </div>
            <div class="d-flex">
              <div class="flex-grow-0 mr-3">
                <AppIconGenderAmbiguous class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div>
                <p class="small mb-0 font-weight-bold">{this.translation('lbl_client_sex')}</p>
                <RadioGroupInput
                  class="my-1"
                  options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(Sex), 'enum_sex_type_')}
                  v-model={this.request.clientSex}
                  invalidMessage={this.buildInvalidMessage(this.v.clientSex!)}
                />
              </div>
            </div>
            <div class="d-flex mb-4">
              <div class="flex-grow-0 mr-3">
                <AppIconScale class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div class="flex-fill">
                <p class="small mb-0 font-weight-bold">{this.translation('lbl_client_weight')}</p>
                <b-form-group
                  class="mt-1 mb-0"
                  state={this.buildInvalidMessage(this.v.clientWeight!) !== undefined ? false : undefined}
                  invalid-feedback={this.buildInvalidMessage(this.v.clientWeight!)}
                >
                  <VueMultiSelect
                    class="custom-control-single-select"
                    multiple={false}
                    close-on-select={true}
                    preselect-first={true}
                    searchable={false}
                    show-labels={false}
                    placeholder=""
                    options={this.clientWeightOptions}
                    value={this.clientWeightOptions.find(item => item.value === this.request.clientWeight)}
                    track-by="value"
                    label="text"
                    onInput={(value: SelectOption<ClientWeight>) => {
                      this.request.clientWeight = value!.value;
                    }}
                    scopedSlots={{
                      singleLabel: ({ option }: { option: SelectOption<ClientWeight> }): VNode => {
                        return (
                          <span>{this.translation(`shared.enum-client-weight-${option.value}`)}</span>
                        )
                      }
                    }}
                  />
                </b-form-group>
              </div>
            </div>
          </div>
        }
        {!this.requestDraft &&
          <div>
            {this.request.service.providedAtClientPlace &&
              <div class="d-flex mb-4">
                <div class="flex-grow-0 mr-3">
                  <b-icon icon={this.isRequester ? 'geo-alt-fill' : 'geo-alt'} class={`app-icon-lg align-top mt-0${this.isRequester ? ' text-success' : ' text-gray-500'}`} aria-hidden={true} />
                </div>
                <div class="flex-fill">
                  <p class="small mb-0 font-weight-bold">{this.translation('lbl_address_of_service')}</p>
                  <span>
                    {`${this.request.service.location.address.address}, ${this.request.service.location.address.zipCode} ${this.request.service.location.address.cityOrCounty}`}
                  </span>
                </div>
              </div>
            }
            <div class="d-flex mb-4">
              <div class="flex-grow-0 mr-3">
                <b-icon icon={'chat-dots'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div>
                <p class="small mb-0 font-weight-bold">{this.translation('lbl_user_languages')}</p>
                <span>
                  {this.request.requester.languages.map((lang, index) => {
                    return (
                      lang === Language.ASE ?
                        <span>
                          <b-link class="text-primary" v-b-tooltip={{title: this.translation('enum_language_user_speak_ase')}}>
                            {this.translation('enum_language_user_speak_short_' + lang.toLowerCase())}
                          </b-link>{index !== this.request.requester.languages.length - 1 ? ', ' : ''}
                        </span> :
                        <span>
                          {this.translation('enum_language_user_speak_short_' + lang.toLowerCase())}{index !== this.request.requester.languages.length - 1 ? ', ' : ''}
                        </span>
                    )
                  })}
                </span>
              </div>
            </div>
            <div class="d-flex mb-4">
              <div class="flex-grow-0 mr-3">
                <AppIconGenderAmbiguous class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div>
                <p class="small mb-0 font-weight-bold">{this.translation('lbl_requester_sex')}</p>
                <span>{this.translation(`enum_sex_type_${this.request.requester.sex?.toLowerCase()}`)}</span>
              </div>
            </div>
            <div class="d-flex mb-4">
              <div class="flex-grow-0 mr-3">
                <AppIconGenderAmbiguous class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
              </div>
              <div>
                <p class="small mb-0 font-weight-bold">{this.translation('lbl_client_sex')}</p>
                <span>{this.translation(`enum_sex_type_${this.request.clientSex?.toLowerCase()}`)}</span>
              </div>
            </div>
            {this.request.clientWeight !== undefined &&
              <div class="d-flex mb-4">
                <div class="flex-grow-0 mr-3">
                  <AppIconScale class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
                </div>
                <div class="flex-fill">
                  <p class="small mb-0 font-weight-bold">{this.translation('lbl_client_weight')}</p>
                  <span>{this.translation(`shared.enum-client-weight-${this.request.clientWeight}`)}</span>
                </div>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}
