import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import {DealStatus} from "@/types";
import {I18nComponent} from '@/components/I18nComponent'
//import DealRoadMap from "@/components/DealRoadMap";

interface Props {
  dataSection: () => VNode;
  scheduleSection: () => VNode;
  messengerSection: () => VNode;
  confirmationSection: () => VNode;
  requestStatus: string;
}

@Component({name: "DealLayout"})
export default class extends I18nComponent implements Props {
  @Prop(Function) public readonly dataSection!: () => VNode
  @Prop(Function) public readonly scheduleSection!: () => VNode
  @Prop(Function) public readonly messengerSection!: () => VNode
  @Prop(Function) public readonly confirmationSection!: () => VNode
  @Prop(String) public readonly requestStatus!: DealStatus
  
  
  public render(): VNode {
    return (
      <div>
        {/*<DealRoadMap dealStatus={this.requestStatus} />*/}
        {this.dataSection()}
        {this.scheduleSection()}
        {this.messengerSection()}
        {this.confirmationSection()}
      </div>
    )
  }
}