import {Component, Prop} from "vue-property-decorator"
import Vue, {VNode} from "vue"
import {TsxComponent} from "@/components/TsxComponent";
import {BIconChevronRight} from "bootstrap-vue";
import {Service, ServiceType} from "@/_modules/service/types";
import {ServiceFromRequest} from '@/_modules/request/types'
import {ServiceProposal} from '@/_modules/proposal/types'
import {getServiceTypeTranslationByKey} from "@/_modules/service/store/services";
import {appStore} from "@/store";
import {RecursivePartial} from "@/utils/typescript-library-extensions";

interface Props {
  readonly value: Service | ServiceFromRequest | ServiceProposal;
  readonly optionsWithCategoryName: RecursivePartial<ServiceType[]>;
  readonly accordionTitleClass?: string;
}

interface AccordionStates {
  [key: string]: boolean;
}

@Component({name: 'ServiceDetailsClientParameters', components: {BIconChevronRight}})
export default class ServiceDetailsClientParameters extends TsxComponent<Props> {
  @Prop(Object) public readonly value!: Service | ServiceFromRequest | ServiceProposal
  @Prop(Array) public readonly optionsWithCategoryName!: RecursivePartial<ServiceType[]>
  @Prop({type: String, required: false, default: ''}) public readonly accordionTitleClass!: string

  private firstAccordionStates: AccordionStates = {};
  private secondAccordionStates: AccordionStates = {};

  private isAccordionFullyOpen(collapseStates: AccordionStates): boolean {
    return Object.values(collapseStates).every((state) => state === true)
  }

  private toggleFirstAccordionItems(): void {
    const accodionState = this.isAccordionFullyOpen(this.firstAccordionStates);
    Object.keys(this.firstAccordionStates).forEach((index) => {
      this.firstAccordionStates[index] = !accodionState
    })
  }

  public mounted(): void {
    this.firstAccordionStates = {
      'sd-accordion-mental-ability': false,
      'sd-accordion-physical-activity': false
    }

    if (this.value.clientMentalAbility !== undefined && this.value.clientDisease.length > 0) {
      Vue.set(this.firstAccordionStates, 'sd-accordion-client-disease', false);
    }

    this.optionsWithCategoryName.map((it, index) => {
      // Vue.set is needed to keep this.secondAccordionStates reactive
      Vue.set(this.secondAccordionStates, `sd-accordion-category-${index}`, false);
    })
  }

  public render(): VNode {
    return (
      <div>
        <b-row>
          <b-col cols="12">
            <h2 class={['d-flex justify-content-center align-items-md-center flex-column flex-md-row mt-6 mt-md-8', this.accordionTitleClass]}>
              <span class="fieldset-title flex-fill mr-md-4">
                {this.translation('service-details.subtitle_clients_parameters')}
              </span>
              <b-link
                class="h5 mt-2 mb-0 mt-md-0 font-weight-normal text-nowrap text-primary d-none d-md-inline"
                onClick={this.toggleFirstAccordionItems}
              >
                {this.isAccordionFullyOpen(this.firstAccordionStates) ? this.translation(`service-details.hide_all`) : this.translation(`service-details.show_all`)}
              </b-link>
            </h2>
          </b-col>
          <b-col cols="12" md="4" class="pl-md-8 mb-4">
            <h3 class="h4">{this.translation('service-details.lbl_age')}</h3>
            <p class="pl-6">
              {this.value.clientAge.map((value, index) => 
                `${this.translation(`shared.enum-client-age-${value}`)}${index !== this.value.clientAge.length - 1 ? ', ' : ''}`
              )}
            </p>
          </b-col>
          <b-col cols="12" md="4" class="pl-md-8 mb-4">
            <h3 class="h4">{this.translation('service-details.lbl_sex')}</h3>
            <p class="pl-6">
              {this.value.clientSex.map((value, index) => 
                `${this.translation(`shared.enum-sex-type-${value}`)}${index !== this.value.clientSex.length - 1 ? ', ' : ''}`
              )}
            </p>
          </b-col>
          <b-col cols="12" md="4" class="pl-md-8 mb-4">
            <h3 class="h4">{this.translation('service-details.lbl_weight')}</h3>
            <p class="pl-6">
              {this.value.clientWeight.map((value, index) => 
                `${this.translation(`shared.enum-client-weight-${value}`)}${index !== this.value.clientWeight.length - 1 ? ', ' : ''}`
              )}
            </p>
          </b-col>
          <b-col cols="12">
            <b-link
              class="h5 mb-6 font-weight-normal text-nowrap text-primary d-block d-md-none"
              onClick={this.toggleFirstAccordionItems}
            >
              {this.isAccordionFullyOpen(this.firstAccordionStates) ? this.translation(`service-details.hide_all`) : this.translation(`service-details.show_all`)}
            </b-link>
            <div class="accordion pl-md-4" role="tablist">
              <div>
                <div class="d-flex" v-b-toggle={'sd-accordion-mental-ability'}>
                  <b-icon-chevron-right variant="primary" class="app-icon-lg mt-0 mr-2" />
                  <h3 class="h4 m-0">
                    {this.translation('service-details.lbl_mental_ability')}
                  </h3>
                </div>
                <b-collapse
                  id="sd-accordion-mental-ability"
                  class="pl-10"
                  role="tabpanel"
                  v-model={this.firstAccordionStates['sd-accordion-mental-ability']}
                >
                  <ul class="list-styled mt-6 mb-0 pb-2">
                    {this.value.clientMentalAbility.map(v => (
                      <li>{this.translation(`shared.enum-client-mental-ability-${v}`)}</li>
                    ))}
                  </ul>
                </b-collapse>
              </div>
              <div>
                <div class="d-flex mt-6 mt-md-8" v-b-toggle={'sd-accordion-physical-activity'}>
                  <b-icon-chevron-right variant="primary" class="app-icon-lg mt-0 mr-2" />
                  <h3 class="h4 m-0">
                    {this.translation('service-details.lbl_physical_activity')}
                  </h3>
                </div>
                <b-collapse
                  id="sd-accordion-physical-activity"
                  class="pl-10"
                  role="tabpanel"
                  v-model={this.firstAccordionStates['sd-accordion-physical-activity']}
                >
                  <ul class="list-styled mt-6 mb-0 pb-2">
                    {this.value.clientPhysicalActivity.map(v => (
                      <li>{this.translation(`shared.enum-client-physical-activity-${v}`)}</li>
                    ))}
                  </ul>
                </b-collapse>
              </div>
              {this.value.clientDisease !== undefined && this.value.clientDisease.length > 0 &&
                <div>
                  <div class="d-flex mt-6 mt-md-8" v-b-toggle={'sd-accordion-client-disease'}>
                    <b-icon-chevron-right variant="primary" class="app-icon-lg mt-0 mr-2" />
                    <h3 class="h4 m-0">
                      {this.translation('service-details.lbl_client_disease')}
                    </h3>
                  </div>
                  <b-collapse
                    id="sd-accordion-client-disease"
                    class="pl-10"
                    role="tabpanel"
                    v-model={this.firstAccordionStates!['sd-accordion-client-disease']}
                  >
                    <ul class="list-styled mt-6 mb-0 pb-2">
                      {this.value.clientDisease.map(v => {
                        return <li>{this.translation(`shared.enum-client-disease-${v}`)}</li>
                      })}
                    </ul>
                  </b-collapse>
                </div>
              }
            </div>
            <h2 class={['d-flex justify-content-center align-items-md-center flex-column flex-md-row mt-10 mt-md-12', this.accordionTitleClass]}>
              <span class="fieldset-title flex-fill mr-md-4">
                {this.translation(`service-details.title_list_of_categories`)}
              </span>
              <b-link
                class="h5 mt-2 mb-0 mt-md-0 font-weight-normal text-nowrap text-primary"
                onClick={() => {
                  const accodionState = this.isAccordionFullyOpen(this.secondAccordionStates);
                  Object.keys(this.secondAccordionStates).forEach((index) => {
                    this.secondAccordionStates[index] = !accodionState
                  })
                }}
              >
                {this.isAccordionFullyOpen(this.secondAccordionStates) ? this.translation(`service-details.hide_all`) : this.translation(`service-details.show_all`)}
              </b-link>
            </h2>
            <div class="accordion pl-md-4" role="tablist">
              {this.optionsWithCategoryName.map((it, index) => (
                <div>
                  <div class={`d-flex mt-6${index > 0 ? ' mt-md-8' : ''}`} v-b-toggle={`sd-accordion-category-${index}`}>
                    <b-icon-chevron-right variant="primary" class="app-icon-lg mt-0 mr-2" />
                    <h3 class="h4 m-0">
                      {getServiceTypeTranslationByKey(appStore.locale, it!.category)} ({it!.subCategories?.length})
                    </h3>
                  </div>
                  <b-collapse
                    id={`sd-accordion-category-${index}`}
                    class="pl-10"
                    role="tabpanel"
                    v-model={this.secondAccordionStates[`sd-accordion-category-${index}`]}
                  >
                    <ul class="list-styled mt-6 mb-0 pb-2">
                      {it!.subCategories!.map(iter => {
                        return (
                          <li>{getServiceTypeTranslationByKey(appStore.locale, iter?.category)}</li>
                        )
                      })}
                    </ul>
                  </b-collapse>
                </div>
              ))}
            </div>
          </b-col>
        </b-row>
      </div>
    )
  }
}
