import {VNode} from "vue"
import {Component, Prop, Ref} from "vue-property-decorator"

import {AbstractInputComponent, AbstractInputPropsType} from "@/components/form/AbstractInputComponent"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DatePicker from 'vuejs-datepicker'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { en, ee, ru } from 'vuejs-datepicker/dist/locale/index'

import moment from "moment"
import {AppLang} from "@/i18n"
import {datepickerDisplayDateFormat} from "@/constants/ApplicationConfiguration"

import {BIconCalendar4Week} from "bootstrap-vue"
import { DatePickerInterface } from "@/types"

interface DisabledDates {
  to?: Date;
  from?: Date;
}

const todayStart = moment().startOf('day').toDate()
const todayEnd = moment().endOf('day').toDate()

type ValueType = Date | null

interface PropsType extends AbstractInputPropsType<ValueType> {
  placeholder?: string;
  minimumView?: string;
  maximumView?: string;
  format?: string;
  noPastDate?: boolean;
  noFutureDate?: boolean;
  hideLabel?: boolean;
  disableEarlyThenDate?: Date;
}

@Component({
  name: "MonthPicker",
  components: {
    BIconCalendar4Week
  }
})
export default class extends AbstractInputComponent<ValueType, PropsType> implements PropsType {
  @Prop(Date) public readonly value?: ValueType
  @Prop(String) public readonly placeholder?: string
  @Prop(String) public readonly minimumView?: string
  @Prop(String) public readonly maximumView?: string
  @Prop({type: String, default: datepickerDisplayDateFormat}) public readonly format?: string
  @Prop(Boolean) public readonly noPastDate!: boolean
  @Prop(Boolean) public readonly noFutureDate!: boolean
  @Prop(Boolean) public readonly hideLabel?: boolean
  @Prop(Date) public readonly disableEarlyThenDate?: Date

  @Ref('input') public readonly input!: DatePickerInterface

  public get disabledDates(): DisabledDates | undefined {
    if (this.disableEarlyThenDate) {
      return { to: moment(this.disableEarlyThenDate).endOf('day').toDate() }
    }
    if (!this.noPastDate && !this.noFutureDate) {
      return undefined
    } else if (this.noPastDate && this.noFutureDate) {
      throw Error('It is restricted to set both attributes simultaneously: "noPastDate" & "noFutureDate"')
    } else {
      return this.noPastDate ? { to: todayStart } : { from: todayEnd }
    }
  }

  public get lang(): object {
    switch (this.$i18n.locale) {
      case AppLang.ET:
        return ee
      case AppLang.RU:
        return ru
      default:
        return en
    }
  }

  public focus(): void {
    this.input.focus()
  }

  public onClick(): void {
    this.input.showCalendar()
  }

  public isEmpty(): boolean {
    return this.value === undefined
  }

  public onInput(date: ValueType): void {
    this.$emit('input', date);
  }

  public render(): VNode {
    return (
      <DatePicker
        id={this.id}
        name={this.name}
        placeholder={this.placeholder}
        required={this.required}
        disabled={this.disabled}
        value={this.value}
        ref="input"
        minimumView={this.minimumView}
        maximumView={this.maximumView}
        monday-first={true}
        language={this.lang}
        disabled-dates={this.disabledDates}
        format={this.format}
        typeable={true}
        onInput={this.onInput}
        bootstrap-styling={true}
        calendar-class='app-datepicker-calendar'
        input-class={'schedule-datepicker-input border-right-0 pr-0 bg-white'}
      >
        {!this.disabled &&
        <template slot="afterDateInput">
          <span class="input-group-append" onClick={this.onClick}>
            <span class="input-group-text">
              <b-icon-calendar4-week variant="primary" />
            </span>
          </span>
        </template>
        }
      </DatePicker>
    )
  }
}
