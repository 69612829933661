import { VNode } from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import { AbstractInputComponent, AbstractInputPropsType } from "@/components/form/AbstractInputComponent"

import { isEmpty } from "@/constants/DataBoundaries"
import { BasicEvents } from "@/constants/ComponentEvents"
import { Temperature } from "@/types"
import { appStore } from "@/store"
import TextInput from "@/components/form/TextInput"
import { celsiusToFahrenheit, fahrenheitToCelsius } from "@/utils/temperature-utils"

type ValueType = string | undefined

interface PropsType extends AbstractInputPropsType<ValueType> {
  value?: string;
}

@Component({name: "TemperatureInput"})
export default class extends AbstractInputComponent<ValueType, PropsType> implements PropsType {
  @Prop([String]) public readonly value?: ValueType

  public displayValue: ValueType = '';

  public isEmpty(): boolean {
    if (this.value === undefined) {
      return true
    } else if (typeof this.value === "string") {
      return this.value.length === 0
    } else {
      return false
    }
  }

  private setBodyTemperature (value: string): void {
    this.displayValue = value.replace(',', '.');

    if (appStore.preferredTemperatureScale === Temperature.celsius) {
      this.$emit(BasicEvents.Input, this.displayValue);
    } else if (appStore.preferredTemperatureScale === Temperature.fahrenheit) {
      this.$emit(BasicEvents.Input, fahrenheitToCelsius(this.displayValue));
    }
  }

  private changeTemperatureScale (scale: Temperature): void {
    appStore.setPreferredTemperatureScale(scale);

    if (!isEmpty(this.displayValue)) {
      this.setBodyTemperature(this.displayValue!);
    }
  }

  @Watch('value')
  public resetDisplayValue(): void {
    if (isEmpty(this.value)) {
      this.displayValue = this.value;
    }
  }

  public mounted(): void {
    if (appStore.preferredTemperatureScale === Temperature.celsius) {
      this.displayValue = this.value;
    } else if (appStore.preferredTemperatureScale === Temperature.fahrenheit && !isEmpty(this.value)) {
      this.displayValue = celsiusToFahrenheit(this.value!);
    }
  }

  public render(): VNode {
    return (
      <TextInput
        label={this.translation('shared.fld-body-temperature')}
        value={this.displayValue}
        disabled={this.disabled}
        invalidMessage={this.invalidMessage}
        onInput={(value: string) => (this.setBodyTemperature(value))}
        append={(): VNode[] => {
          const appendButtons = [];

          appendButtons.push(<b-button
            class="px-4"
            pressed={appStore.preferredTemperatureScale === Temperature.celsius}
            variant={appStore.preferredTemperatureScale === Temperature.celsius ? 'primary': 'outline-primary'}
            onClick={() => {this.changeTemperatureScale(Temperature.celsius)}}
            disabled={this.disabled}
          >
            {this.translation('shared.enum-temperature-C')}
          </b-button>);
          appendButtons.push(<b-button
            class="px-4"
            pressed={appStore.preferredTemperatureScale === Temperature.fahrenheit}
            variant={appStore.preferredTemperatureScale === Temperature.fahrenheit ? 'primary': 'outline-primary'}
            onClick={() => {this.changeTemperatureScale(Temperature.fahrenheit)}}
            disabled={this.disabled}
          >
            {this.translation('shared.enum-temperature-F')}
          </b-button>);

          return appendButtons;
        }}
      />
    )
  }
}
