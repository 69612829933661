/* eslint-disable */
import {Component, Prop, Watch} from "vue-property-decorator"
import {VNode} from "vue"
import {TsxComponent} from "@/components/TsxComponent";
import { DiaryDataForm, Temperature } from "@/types";
import moment from "moment";
import _ from "lodash";
import {buildChartSettings, buildInitialSerieState, ChartOptions} from "@/components/diary/settings";
import {appStore} from "@/store";
import {convertToI18nLang} from "@/store/app";
import { isEmpty } from "@/constants/DataBoundaries";
import { celsiusToFahrenheit } from "@/utils/temperature-utils";
interface Props {
  value: DiaryDataForm[];
}

interface SerieData {
  name: string;
  data: number[][];
}

interface ChartsData {
  heartRate: SerieData[];
  heartRateOptions: ChartOptions;
  bodyTemperature: SerieData[];
  bodyTemperatureOptions: ChartOptions;
  bloodPressure: SerieData[];
  bloodPressureOptions: ChartOptions;
  bloodSugarLevel: SerieData[];
  bloodSugarLevelOptions: ChartOptions;
  urineVolume: SerieData[];
  urineVolumeOptions: ChartOptions;
  saturation: SerieData[];
  saturationOptions: ChartOptions;
}

const initialSerieState = buildInitialSerieState()
const sharedOptions = buildChartSettings()

@Component({name: 'DiaryChart'})
export default class DiaryChart extends TsxComponent<Props> {
  @Prop(Array) public readonly value!: DiaryDataForm[]

  private data: ChartsData = {

    bodyTemperature: [...initialSerieState],
    bodyTemperatureOptions: {
      ...sharedOptions,
      title: {
        ...sharedOptions.title,
        text: this.translation('shared.fld-body-temperature')
      },
      chart: {
        ...sharedOptions.chart,
        id: 'bt'
      },
      tooltip: {
        ...sharedOptions.tooltip,
        y: {
          ...sharedOptions.tooltip.y,
          dataUnit: '',
        }
      }
    },

    heartRate: [...initialSerieState],
    heartRateOptions: {
      ...sharedOptions,
      title: {
        ...sharedOptions.title,
        text: this.translation('shared.fld-heart-rate')
      },
      chart: {
        ...sharedOptions.chart,
        id: 'hr'
      },
      tooltip: {
        ...sharedOptions.tooltip,
        y: {
          ...sharedOptions.tooltip.y,
          dataUnit:  this.translation('shared.fld-heart-rate-append'),
        }
      }
    },

    bloodPressure: [...initialSerieState],
    bloodPressureOptions: {
      ...sharedOptions,
      title: {
        ...sharedOptions.title,
        text: this.translation('shared.fld-blood-pressure')
      },
      chart: {
        ...sharedOptions.chart,
        id: 'bp'
      },
      tooltip: {
        ...sharedOptions.tooltip,
        y: {
          ...sharedOptions.tooltip.y,
          dataUnit:  this.translation('shared.fld-blood-pressure-append'),
        }
      }
    },

    bloodSugarLevel: [...initialSerieState],
    bloodSugarLevelOptions: {
      ...sharedOptions,
      title: {
        ...sharedOptions.title,
        text: this.translation('shared.fld-blood-sugar-level')
      },
      chart: {
        ...sharedOptions.chart,
        id: 'bs'
      },
      tooltip: {
        ...sharedOptions.tooltip,
        y: {
          ...sharedOptions.tooltip.y,
          dataUnit:  this.translation('shared.fld-blood-sugar-level-append'),
        }
      }
    },

    saturation: [...initialSerieState],
    saturationOptions: {
      ...sharedOptions,
      title: {
        ...sharedOptions.title,
        text: this.translation('shared.fld-saturation')
      },
      chart: {
        ...sharedOptions.chart,
        id: 's'
      },
      tooltip: {
        ...sharedOptions.tooltip,
        y: {
          ...sharedOptions.tooltip.y,
          dataUnit:  this.translation('shared.fld-saturation-append'),
        }
      }
    },

    urineVolume: [...initialSerieState],
    urineVolumeOptions: {
      ...sharedOptions,
      title: {
        ...sharedOptions.title,
        text: this.translation('shared.fld-urine-volume')
      },
      chart: {
        ...sharedOptions.chart,
        id: 'uv'
      },
      tooltip: {
        ...sharedOptions.tooltip,
        y: {
          ...sharedOptions.tooltip.y,
          dataUnit:  this.translation('shared.fld-urine-volume-append'),
        }
      }
    }
  }

  private get bodyTemperatureSeries(): SerieData[] {
    return [{
      name: this.translation('shared.fld-body-temperature'),
      data: _.compact(this.value.map((it) => {
        if (isEmpty(it.bodyTemperature)) {
          return undefined;
        }
  
        let bodyTemperature = it.bodyTemperature!;
  
        if (appStore.preferredTemperatureScale === Temperature.fahrenheit) {
          bodyTemperature = celsiusToFahrenheit(bodyTemperature);
        }
  
        return [moment(it.collectedAt).toDate().getTime(), Number(bodyTemperature)]
      }))
    }]
  }

  @Watch('$i18n.locale')
  @Watch('value')
  public setupChartData(): void {
    this.data = {
      bodyTemperature: this.bodyTemperatureSeries,
      bodyTemperatureOptions: {
        ...sharedOptions,
        title: {
          ...sharedOptions.title,
          text: `${this.translation('shared.fld-body-temperature')} (${this.translation(`shared.enum-temperature-${appStore.preferredTemperatureScale}`)})`
        },
        chart: {
          ...sharedOptions.chart,
          id: 'bt',
          toolbar: {
            tools: {
              ...sharedOptions.chart!.toolbar!.tools,
              customIcons: [{
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-thermometer-half" viewBox="0 0 16 16"><path d="M9.5 12.5a1.5 1.5 0 1 1-2-1.415V6.5a.5.5 0 0 1 1 0v4.585a1.5 1.5 0 0 1 1 1.415z"/><path d="M5.5 2.5a2.5 2.5 0 0 1 5 0v7.55a3.5 3.5 0 1 1-5 0V2.5zM8 1a1.5 1.5 0 0 0-1.5 1.5v7.987l-.167.15a2.5 2.5 0 1 0 3.333 0l-.166-.15V2.5A1.5 1.5 0 0 0 8 1z"/></svg>',
                index: 0,
                title: this.translation('shared.change-temperature-scale'),
                click: () => {
                  if (appStore.preferredTemperatureScale === Temperature.fahrenheit) {
                    appStore.setPreferredTemperatureScale(Temperature.celsius);
                  } else {
                    appStore.setPreferredTemperatureScale(Temperature.fahrenheit);
                  }

                  // Update chart series to show updated temperature scale
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const bodyTemperatureChart = (this.$refs.bodyTemperatureChart as any);
                  bodyTemperatureChart.updateSeries(this.bodyTemperatureSeries);

                  // Update chart title and unit
                  bodyTemperatureChart.updateOptions({
                    title: {
                      text: `${this.translation('shared.fld-body-temperature')} (${this.translation(`shared.enum-temperature-${appStore.preferredTemperatureScale}`)})`
                    },
                    tooltip: {
                      y: {
                        dataUnit: this.translation(`shared.enum-temperature-${appStore.preferredTemperatureScale}`)
                      }
                    }
                  });
                },
                class: 'apexcharts-temperature-toggle'
              }]
            }
          }
        },
        tooltip: {
          ...sharedOptions.tooltip,
          y: {
            ...sharedOptions.tooltip.y,
            dataUnit: this.translation(`shared.enum-temperature-${appStore.preferredTemperatureScale}`),
          }
        }
      },

      heartRate: [{
        name: this.translation('shared.fld-heart-rate'),
        data: _.compact(this.value.map(it => {
          return it.heartRate ? [moment(it.collectedAt).toDate().getTime(), Number(it.heartRate)] : undefined
        }))
      }],
      heartRateOptions: {
        ...sharedOptions,
        title: {
          ...sharedOptions.title,
          text: `${this.translation('shared.fld-heart-rate')} (${this.translation('shared.fld-heart-rate-append')})`
        },
        chart: {
          ...sharedOptions.chart,
          id: 'hr'
        },
        tooltip: {
          ...sharedOptions.tooltip,
          y: {
            ...sharedOptions.tooltip.y,
            dataUnit:  ` ${this.translation('shared.fld-heart-rate-append')}`,
          }
        }
      },

      bloodPressure: [{
          name: this.translation('shared.fld-blood-pressure-diastolic'),
          data: _.compact(this.value.map(it => {
            if (isEmpty(it.bloodPressure)) {
              return undefined;
            }

            const bloodPressureParts = it.bloodPressure!.split('/');

            if (bloodPressureParts.length !== 2) {
              return undefined;
            }

            return [moment(it.collectedAt).toDate().getTime(), Number(bloodPressureParts[1])];
          }))
        },{
          name: this.translation('shared.fld-blood-pressure-systolic'),
          data: _.compact(this.value.map(it => {
            if (isEmpty(it.bloodPressure)) {
              return undefined;
            }

            const bloodPressureParts = it.bloodPressure!.split('/');
            if (bloodPressureParts[0].length === 0) {
              return undefined;
            }

            return [moment(it.collectedAt).toDate().getTime(), Number(bloodPressureParts[0])];
          }))
        }
      ],
      bloodPressureOptions: {
        ...sharedOptions,
        title: {
          ...sharedOptions.title,
          text: `${this.translation('shared.fld-blood-pressure')} (${this.translation('shared.fld-blood-pressure-append')})`
        },
        chart: {
          ...sharedOptions.chart,
          id: 'bp'
        },
        tooltip: {
          ...sharedOptions.tooltip,
          y: {
            ...sharedOptions.tooltip.y,
            dataUnit:  ` ${this.translation('shared.fld-blood-pressure-append')}`,
          }
        }
      },

      bloodSugarLevel: [{
        name: this.translation('shared.fld-blood-sugar-level'),
        data: _.compact(this.value.map((it) => {
          return it.bloodSugarLevel ? [moment(it.collectedAt).toDate().getTime(), Number(it.bloodSugarLevel)] : undefined
        }))
      }],
      bloodSugarLevelOptions: {
        ...sharedOptions,
        title: {
          ...sharedOptions.title,
          text: `${this.translation('shared.fld-blood-sugar-level')} (${this.translation('shared.fld-blood-sugar-level-append')})`
        },
        chart: {
          ...sharedOptions.chart,
          id: 'bt'
        },
        tooltip: {
          ...sharedOptions.tooltip,
          y: {
            ...sharedOptions.tooltip.y,
            dataUnit:  ` ${this.translation('shared.fld-blood-sugar-level-append')}`,
          }
        }
      },

      saturation: [{
        name: this.translation('shared.fld-saturation'),
        data: _.compact(this.value.map(it => {
          return it.saturation ? [moment(it.collectedAt).toDate().getTime(), Number(it.saturation)] : undefined
        }))
      }],
      saturationOptions: {
        ...sharedOptions,
        title: {
          ...sharedOptions.title,
          text: `${this.translation('shared.fld-saturation')} (${this.translation('shared.fld-saturation-append')})`
        },
        chart: {
          ...sharedOptions.chart,
          id: 's'
        },
        tooltip: {
          ...sharedOptions.tooltip,
          y: {
            ...sharedOptions.tooltip.y,
            dataUnit:  this.translation('shared.fld-saturation-append'),
          }
        }
      },

      urineVolume: [{
        name: this.translation('shared.fld-urine-volume'),
        data: _.compact(this.value.map((it) => {
          return it.urineVolume ? [moment(it.collectedAt).toDate().getTime(), Number(it.urineVolume)] : undefined
        }))
      }],
      urineVolumeOptions: {
        ...sharedOptions,
        title: {
          ...sharedOptions.title,
          text: `${this.translation('shared.fld-urine-volume')} (${this.translation('shared.fld-urine-volume-append')})`
        },
        chart: {
          ...sharedOptions.chart,
          id: 'bt'
        },
        tooltip: {
          ...sharedOptions.tooltip,
          y: {
            ...sharedOptions.tooltip.y,
            dataUnit:  ` ${this.translation('shared.fld-urine-volume-append')}`,
          }
        }
      }
    }
  }
  
  public mounted(): void {
    this.setupChartData();
  }

  public render(): VNode {
    return (
      <div class="mt-6 mt-md-10">
        <div id="wrapper" class="apexcharts-wrapper">
          {this.data.bodyTemperature[0].data.length > 0 &&
            <div id="bodyTemperature">
              <apexchart
                ref="bodyTemperatureChart"
                type="line"
                height={sharedOptions.chart!.height!}
                series={this.data.bodyTemperature}
                options={{
                  ...this.data.bodyTemperatureOptions,
                  chart: {
                    ...this.data.bodyTemperatureOptions.chart,
                    defaultLocale: convertToI18nLang(appStore.locale)
                  }
                }}
              />
            </div>
          }
          {this.data.heartRate[0].data.length > 0 &&
            <div id="heartRate">
              <apexchart
                type="line"
                height={sharedOptions.chart!.height!}
                series={this.data.heartRate}
                options={{
                  ...this.data.heartRateOptions,
                  chart: {
                    ...this.data.heartRateOptions.chart,
                    defaultLocale: convertToI18nLang(appStore.locale)
                  }
                }}
              />
            </div>
          }
          {this.data.bloodPressure[0].data.length > 0 &&
            <div id="bloodPressure">
              <apexchart
                type="line"
                height={sharedOptions.chart!.height!}
                series={this.data.bloodPressure}
                options={{
                  ...this.data.bloodPressureOptions,
                  chart: {
                    ...this.data.bloodPressureOptions.chart,
                    defaultLocale: convertToI18nLang(appStore.locale)
                  }
                }}
              />
            </div>
          }
          {this.data.bloodSugarLevel[0].data.length > 0 &&
            <div id="bloodSugarLevel">
              <apexchart
                type="line"
                height={sharedOptions.chart!.height!}
                series={this.data.bloodSugarLevel}
                options={{
                  ...this.data.bloodSugarLevelOptions,
                  chart: {
                    ...this.data.bloodSugarLevelOptions.chart,
                    defaultLocale: convertToI18nLang(appStore.locale)
                  }
                }}
              />
            </div>
          }
          {this.data.saturation[0].data.length > 0 &&
            <div id="bloodSugarLevel">
              <apexchart
                type="line"
                height={sharedOptions.chart!.height!}
                series={this.data.saturation}
                options={{
                  ...this.data.saturationOptions,
                  chart: {
                    ...this.data.saturationOptions.chart,
                    defaultLocale: convertToI18nLang(appStore.locale)
                  }
                }}
              />
            </div>
          }
          {this.data.urineVolume[0].data.length > 0 &&
            <div id="urineVolume">
              <apexchart
                type="line"
                height={sharedOptions.chart!.height!}
                series={this.data.urineVolume}
                options={{
                  ...this.data.urineVolumeOptions,
                  chart: {
                    ...this.data.urineVolumeOptions.chart,
                    defaultLocale: convertToI18nLang(appStore.locale) // TODO: temp solution
                  }
                }}
              />
            </div>
          }
        </div>
      </div>
    )
  }
}
