import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"

import BackendClientMixin from "@/mixins/BackendClientMixin"

import {BIcon, BIconChatDots, BIconGeoAlt, BIconGeoAltFill, BIconPatchQuestionFill, BIconStar, BIconTag} from "bootstrap-vue"

import {ProposalDetails} from "@/_modules/proposal/types"
import {Language, RateType, Rating} from "@/types"
import {isEmpty} from "@/constants/DataBoundaries";
import {distanceFormatter, getDistance} from "@/utils/formatters";
import AppIconVerified from "@/components/icons/AppIconVerified"
import AppIconStar from "@/components/icons/AppIconStar"
import { authStore } from "@/store"
import AppIconGenderAmbiguous from "@/components/icons/AppIconGenderAmbiguous"
import AppIconScale from "@/components/icons/AppIconScale"

interface Props {
  allowChanges: boolean;
  proposal: ProposalDetails;
}

@Component({
  name: 'ProposalRequesterDetails',
  components: {
    BIcon,
    BIconChatDots,
    BIconGeoAlt,
    BIconGeoAltFill,
    BIconPatchQuestionFill,
    BIconStar,
    BIconTag
  }
})
export default class extends BackendClientMixin implements Props {
  @Prop(Boolean) public readonly allowChanges!: boolean;
  @Prop(Object) public readonly proposal!: ProposalDetails;
  @Prop(Object) public readonly demandRequesterRating!: Rating

  public get formattedPrice(): string {
    const budget = this.proposal.demand.rate
    return `${budget?.price.amount ? budget.price.amount : '-'} ${this.translation(`enum_currency_${budget?.price.currency}`)}${budget?.type === RateType.HOURLY_PAYMENT ? this.translation('text_hour_price') : ''}`
  }

  public get proposalDraft(): boolean {
    return isEmpty(this.proposal.status)
  }

  protected get isProvider(): boolean {
    const userId = authStore.authInfo?.userId
    return (this.proposalDraft || this.proposal.service.provider.userId === userId)
  }

  public render(): VNode {
    return (
      <div>
        <h4 class="h3 mt-0 mb-3">{this.proposal.requester.name.first} {this.proposal.requester.name.last}</h4>
        <div class="d-flex mb-4">
          {this.proposal.requester.verified &&
            <span class="small text-nowrap mr-4 d-flex align-items-center">
              <AppIconVerified class="app-icon-lg mt-0 align-top mr-2 text-success" />
              {this.translation('msg_param_verified_true')}
            </span>
          }
          {!this.proposal.requester.verified &&
            <span class="small text-nowrap mr-4 d-flex align-items-center">
              <b-icon-patch-question-fill class="app-icon-lg mt-0 align-top mr-2" variant="danger-dark" />
              {this.translation('msg_param_verified_false')}
            </span>
          }
          {this.demandRequesterRating.ratingsCount !== 0 &&
            <span class="small text-nowrap d-flex align-items-center">
              <AppIconStar class="app-icon-lg mt-0 align-top mr-2 text-warning" aria-label={this.translation('lbl_rating')} />
              {this.demandRequesterRating.grade} <span aria-hidden="true">({this.demandRequesterRating.ratingsCount})</span>
            </span>
          }
          {this.demandRequesterRating.ratingsCount === 0 &&
            <span class="small text-nowrap d-flex align-items-center">
              <b-icon-star class="app-icon-lg mt-0 align-top text-gray-400 mr-2" aria-hidden="true" />
              {this.translation('lbl_no_rating')}
            </span>
          }
        </div>
        <hr class="w-100 mt-0 mb-6" />
        {this.proposal.demand.requestedAtClientPlace &&
          <div class="d-flex mb-4">
            <div class="flex-grow-0 mr-3">
              <b-icon icon={this.isProvider ? 'geo-alt' : 'geo-alt-fill'} class={`app-icon-lg align-top mt-0${this.isProvider ? ' text-gray-500' : ' text-success'}`} aria-hidden={true} />
            </div>
            <div>
              <p class="small mb-0 font-weight-bold">{this.translation('lbl_address_of_service')}</p>
              <span>
                {`${this.proposal.demand.location.address.address}, ${this.proposal.demand.location.address.zipCode} ${this.proposal.demand.location.address.cityOrCounty}`}
              </span>
            </div>
          </div>
        }
        {!this.proposal.demand.requestedAtClientPlace &&
          <div class="d-flex mb-4">
            <div class="flex-grow-0 mr-3">
              <b-icon icon={this.isProvider ? 'geo-alt-fill' : 'geo-alt'} class={`app-icon-lg align-top mt-0${this.isProvider ? ' text-success' : ' text-gray-500'}`} aria-hidden={true} />
            </div>
            <div>
              <p class="small mb-0 font-weight-bold">{this.translation('lbl_distance')}</p>
              {this.proposalDraft &&
                <span>
                  {distanceFormatter(getDistance(this.proposal.demand.location.coordinates, this.proposal.service.location!.coordinates), this.translation('text_m'), this.translation('text_km'))}. {this.translation('msg_service_at_provider_location')}
                </span>
              }
              {!this.proposalDraft &&
                <span>
                  {distanceFormatter(Number(this.proposal.service.distanceInMeters), this.translation('text_m'), this.translation('text_km'))}. {this.translation('msg_service_at_provider_location')}
                </span>
              }
            </div>
          </div>
        }
        <div class="d-flex mb-4">
          <div class="flex-grow-0 mr-3">
            <b-icon icon={'chat-dots'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
          </div>
          <div>
            <p class="small mb-0 font-weight-bold">{this.translation('lbl_languages')}</p>
            <span>
              {this.proposal.demand.languages.map((lang, index) => {
                return (
                  lang === Language.ASE ?
                    <span>
                      <b-link class="text-primary" v-b-tooltip={{title: this.translation('enum_language_user_speak_ase')}}>
                        {this.translation('enum_language_user_speak_short_' + lang.toLowerCase())}
                      </b-link>{index !== this.proposal.demand.languages.length - 1 ? ', ' : ''}
                    </span> :
                    <span>
                      {this.translation('enum_language_user_speak_short_' + lang.toLowerCase())}{index !== this.proposal.demand.languages.length - 1 ? ', ' : ''}
                    </span>
                )
              })}
            </span>
          </div>
        </div>
        <div class="d-flex mb-4">
          <div class="flex-grow-0 mr-3">
            <AppIconGenderAmbiguous class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
          </div>
          <div>
            <p class="small mb-0 font-weight-bold">{this.translation('lbl_requester_sex')}</p>
            <span>{this.translation(`enum_sex_type_${this.proposal.requester.sex?.toLowerCase()}`)}</span>
          </div>
        </div>
        <div class="d-flex mb-4">
          <div class="flex-grow-0 mr-3">
            <AppIconGenderAmbiguous class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
          </div>
          <div>
            <p class="small mb-0 font-weight-bold">{this.translation('lbl_client_sex')}</p>
            <span>{this.translation(`enum_sex_type_${this.proposal.demand.clientSex?.toLowerCase()}`)}</span>
          </div>
        </div>
        {this.proposal.demand.clientWeight !== undefined &&
          <div class="d-flex mb-4">
            <div class="flex-grow-0 mr-3">
              <AppIconScale class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
            </div>
            <div class="flex-fill">
              <p class="small mb-0 font-weight-bold">{this.translation('lbl_client_weight')}</p>
              <span>{this.translation(`shared.enum-client-weight-${this.proposal.demand.clientWeight}`)}</span>
            </div>
          </div>
        }
        <div class="d-flex mb-4">
          <div class="flex-grow-0 mr-3">
            <b-icon icon={'tag'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
          </div>
          <div>
            <p class="small mb-0 font-weight-bold">{this.translation('lbl_preliminary_budget')}</p>
            <span>{this.formattedPrice}</span>
          </div>
        </div>
      </div>
    )
  }
}
