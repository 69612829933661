import {Component, Ref, Watch} from "vue-property-decorator"
import {VNode} from "vue"
import {Validations} from "vuelidate-property-decorators"
import {required, requiredIf, sameAs} from "vuelidate/lib/validators"

import FormMixin from "@/mixins/FormMixin"

import CheckBox from "@/components/form/CheckBox"
import TextInput from "@/components/form/TextInput"
import Button from "@/components/form/Button"
import RequestRequesterDetails from "@/_modules/request/components/RequestRequesterDetails"
import RequestProviderDetails from "@/_modules/request/components/RequestProviderDetails"
import DealLayout from "@/components/layout/DealLayout"
import CompactScheduleCalendar, { TasksCalendarInterface } from "@/components/calendar/CompactScheduleCalendar"
import ScheduleList from "@/components/calendar/ScheduleList"
import Messenger from "@/components/Messenger"
import {BIconCalendar4Week, BIconExclamationCircle, BIconExclamationTriangleFill, BIconListUl, BIconPlusCircle} from "bootstrap-vue"
import LoadingIndicator from "@/components/LoadingIndicator"

import moment from "moment"

import {requestServiceStore} from "@/_modules/request/store/request-service-store"
import {userStore} from "@/store/user-store"

import * as MainRoutes from "@/router/routes"
import {PaymentAgreement} from "@/router/routes"
import * as ActivityRoutes from "@/_modules/activity/router/routes"
import * as ServicesRoutes from "@/_modules/service/router/routes"

import {ActorRole, BusyTimePayload, Currency, DealStatus, DealType, GetRatingPayload, InitialTaskData, LockState, PaymentMethods, RequestPaymentPayload} from '@/types'
import {AcceptReqVersionPayload, Message, NewRequest, RatedBy, RequestDetails, RequestDetailsValidation, RequestNewMessagePayload, RequestPriceAndTasks, RequestTaskAsCompleteOrConfirmPayload, RequestUpdatePayload, TaskDefinition} from '@/_modules/request/types'

import {
  addDealDataToTaskOccurrences,
  addOrUpdateEvent,
  convertTasksIntoServerFormat,
  convertTasksSeriesToInternalUse,
  removeEvent
} from '@/_modules/shared-builders'
import {buildRequestData, onCreateRequestPayload, onRequestAcceptToDiscussion, onRequestCancelByProvider, onRequestCancelByRequester, onRequestCurrentVersionAcceptByProvider, onRequestCurrentVersionAcceptByRequester, onRequestRateByProvider, onRequestRateByRequester, onRequestUpdateByProvider, onRequestUpdateByRequester} from "@/_modules/request/data-builders"
import {formatCountDown} from "@/utils/string-utils"

import {BasicErrorHandler} from "@/utils/errorHandler"
import * as ResponseError from "@/utils/errors"
import {BackendUrls, ErrorCodes} from "@/constants/APIconstants"
import DataBoundaries, {isEmpty, nonNegativeCurrencyValidator} from "@/constants/DataBoundaries"

import {buildValidationRules, ValidationObject, ValidationRuleSet} from "@/utils/vuelidate-extension"
import {buildBusyTimeSchedule, checkIfWindowWasUnfocused} from '@/utils/array-utils'
import {RecursivePartial} from "@/utils/typescript-library-extensions";
import {scrollListner} from "@/utils/scroll-listner";
import {proposalDemandStore} from "@/_modules/proposal/store/proposal-demand-store";
import {authStore} from "@/store";
import {EventBus} from "@/main";
import {CustomEvents} from "@/constants/ComponentEvents";
import {BadgeEvent, badgesStore} from "@/store/badges-store";
import {webSocketHandler} from "@/ws/ws-handler";
import {ChatConnPayload, chatStore} from "@/_modules/message-center/store/chat-store";
import Link from "@/components/Link";
import MultiLangTabs from '@/components/MultiLangTabs'
import ServiceDetailsClientParameters from '@/_modules/service/components/ServiceDetailsClientParameters'
import DemandDetailsClientParameters from "@/_modules/demand/components/DemandDetailsClientParameters";
import _ from 'lodash'
import {serverDateFormat} from '@/constants/ApplicationConfiguration'
import Diary from '@/components/diary/Diary'
import PageHeader from "@/components/layout/PageHeader"
import CollapseSection from "@/components/layout/CollapseSection"
import Notice from "@/components/layout/Notice"
import AppIconClose from "@/components/icons/AppIconClose"
import ContentSection from "@/components/layout/ContentSection"
import InfoNotice from "@/components/layout/InfoNotice"
import ConfirmPopup from "@/components/layout/ConfirmPopup"

enum ActionType {
  Create = "Create",
  Update = "Update",
  Cancel = "Cancel",
  AcceptToDiscussion = "AcceptToDiscussion",
  AcceptCurrentVersion = "AcceptCurrentVersion",
  Pay = "Pay",
  Rate = "Rate"
}

interface ComponentValidation {
  scheduleList: ValidationRuleSet;
  request: ValidationObject<RequestDetailsValidation>;
  // TODO: restore donation. step 6
  //initialDonationAmount: ValidationRuleSet;
  termsAccepted: ValidationRuleSet;
  paymentTermsAccepted: ValidationRuleSet;
}

export class RequestErrorHandler extends BasicErrorHandler {

  protected async handleBadRequest(e: ResponseError.BadRequestError): Promise<void> {
    switch (e.code) {
      case ErrorCodes.ObjectParsingError:
        this.errorMessageKey = 'err_object_parsing_error'
        break
      default:
        await super.handleBadRequest(e)
    }
  }

  protected async handleBackendError(e: ResponseError.BackendError): Promise<void> {
    switch(e.code) {
      case ErrorCodes.ServiceTermsAreNotAccepted:
        this.errorMessageKey = 'err_service_terms_are_not_accepted'
        break
      case ErrorCodes.ServiceRequestStatusIncorrect:
        this.errorMessageKey = "err_service_request_status_incorrect"
        break
      case ErrorCodes.ServiceRequestNotFound:
        this.errorMessageKey = "err_service_request_not_found"
        break
      case ErrorCodes.UserDoesNotHaveProfile:
        this.errorMessageKey = "err_user_does_not_have_profile"
        break
      case ErrorCodes.OperationIsNotAuthorized:
        this.errorMessageKey = "err_operation_is_not_authorized"
        break
      case ErrorCodes.UserDoesNotHaveRightToAccess:
        this.errorMessageKey = "err_user_does_not_have_rights_to_access"
        break
      case ErrorCodes.ServiceNotFound:
        this.errorMessageKey = "err_service_not_found"
        break
      case ErrorCodes.MKTransactionAlreadyInProgress:
        this.errorMessageKey = "err_mk_transaction_already_exist"
        break
      case ErrorCodes.ServiceRequestIsNotLocked:
        this.errorMessageKey = "err_service_request_not_locked"
        break
      case ErrorCodes.CannotCreateDealWithYourself:
        this.errorMessageKey = 'err_cannot_create_request_for_self'
        break
      case ErrorCodes.TaskRecurrenceRuleIncorrect:
        this.errorMessageKey = 'err_task_recurrence_rule_incorrect'
        break
      case ErrorCodes.ServiceRequestIsLocked:
        this.errorMessageKey = undefined
        break
      default:
        await super.handleBackendError(e)
        break
    }
  }
}
const requestErrorHandler = new RequestErrorHandler()

@Component({
  name: 'Request',
  components: {
    BIconCalendar4Week,
    BIconExclamationCircle,
    BIconExclamationTriangleFill,
    BIconListUl,
    BIconPlusCircle
  }
})
export default class Request extends FormMixin {

  @Ref('tasksCalendar') public readonly tasksCalendar!: TasksCalendarInterface

  private paymentMethods: PaymentMethods = {
    bankLinks: []
  }
  private dismissCountDown = 0
  private windowFocusHistory: boolean[] = []

  private actor: ActorRole | string = '' //init as empty string to save reactivity

  private redirectAfterConfirm = false

  private request: RequestDetails = buildRequestData()
  private requestLoaded: boolean = false

  private messages: Message[] = []

  private scheduleList: TaskDefinition[] = []
  private selectedTask: TaskDefinition | {} = {}

  private showRejectAttentionPopup = false
  private termsAccepted = false
  private paymentTermsAccepted = false

  private selectedCalendarWeek = moment().startOf('isoWeek')
  private busyScheduleList: TaskDefinition[] = []
  private showCalendarComponent: boolean = true;

  @Validations()
  protected validations(): RecursivePartial<ComponentValidation> {
    return {
      paymentTermsAccepted: this.request.status === DealStatus.DealConcluded || this.request.status === DealStatus.PaymentFailed || this.request.status === DealStatus.PaymentStarted ? {sameAs: sameAs(() => true)} : {},
      termsAccepted: this.request.status === DealStatus.UnderDiscussion && this.request.lockState.status === LockState.NotLocked ? {sameAs: sameAs(() => true)} : {},

      scheduleList: {required},
      request: {
        price: {
          servicePrice: {
            amount: {
              required,
              nonNegativeCurrency: nonNegativeCurrencyValidator,
              ...buildValidationRules(DataBoundaries.currency),
              ...buildValidationRules(DataBoundaries.currencyNonNegative)
            }
          }
        },
        requester: {
          location: {
            address: {
              address: {requiredIf: requiredIf(() => {return this.request.service.providedAtClientPlace})},
              zipCode: {requiredIf: requiredIf(() => {return this.request.service.providedAtClientPlace})},
              cityOrCounty: {requiredIf: requiredIf(() => {return this.request.service.providedAtClientPlace})}
            }
          },
          languages: {required}
        },
        clientWeight: {required},
        clientSex: {required}
        /*clientWeight: {
          ...buildValidationRules(DataBoundaries.weight),
          ...buildValidationRules(DataBoundaries.weightLength)
        }*/
      }
    }
  }
  public eventsWatcherLoopId: ReturnType<typeof setTimeout> | undefined = undefined

  public async mounted(): Promise<void> {
    await this.fetchRequest()
    this.showCalendarComponent = !this.shouldShowCalendarDetails;

    if (this.request.status === DealStatus.Completed) {
      this.redirectAfterConfirm = true
    }
  }

  public beforeDestroy(): void {
    clearTimeout(this.eventsWatcherLoopId!)
  }

  @Watch('dismissCountDown')
  public async syncTime(): Promise<void> {
    this.windowFocusHistory.push(document.hasFocus())
    this.windowFocusHistory.reverse()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const doc = document as any
    const result = checkIfWindowWasUnfocused(this.windowFocusHistory)
    if (result !== undefined) {
      const resp = await this.withRequest(requestServiceStore.getRequestById(this.request.id!), requestErrorHandler, true)
      this.dismissCountDown = resp.lockState.timeLeftInSeconds
      this.windowFocusHistory = []
    }

    if (this.dismissCountDown <= 0) {
      doc.body.classList.remove('has-count-down')
    }
  }

  @Watch('request.status')
  public async startPollMessages(): Promise<void> {
    const payload: ChatConnPayload = {
      dealId: this.request.id!,
      dealType: DealType.Request,
      userId: authStore.authInfo!.userId!
    }
    await webSocketHandler.wsStreamSingleChatUp(payload)
    setTimeout(() => {
      const btn = document.getElementById('btn-rate')
      if (btn !== null) {
        btn.scrollIntoView({behavior: "auto", block: "center"})
      }
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, 1000)
  }

  public get allowFetchPriceAndTasks(): boolean {
    return (this.requestDraft
      || this.request.status === DealStatus.New
      || this.request.status === DealStatus.UnderDiscussion)
  }

  private async fetchPriceAndTasks(): Promise<void> {
    if (this.allowFetchPriceAndTasks) {
      const tasks: InitialTaskData[] = convertTasksIntoServerFormat(this.scheduleList)

      const requestId = this.$route.params.requestId
      if (requestId) {

        const tasksAndPrice: RequestPriceAndTasks = {
          requestId: requestId,
          servicePrice: !this.isRequester ? this.request.price.servicePrice : undefined,
          calculatePrice: !this.isRequester ? this.request.calculatePrice : undefined,
          tasks: tasks
        }
        await this.withRequest(requestServiceStore.updatePriceAndTasksById(tasksAndPrice), requestErrorHandler, true)
          .then((r) => {
            this.request.price = {
              servicePrice: r.servicePrice,
              tax: r.tax,
              total: r.total,
              paymentFee: r.platformFee,
              platformFee: r.platformFee
            }

            this.request.price.servicePrice = r.servicePrice
            this.scheduleList = convertTasksSeriesToInternalUse(r.tasks)
            this.scheduleList = addDealDataToTaskOccurrences(this.scheduleList, this.request)
            this.setCalendarWeek()
          })
          .catch(() => {
            this.scheduleList = this.scheduleList.filter(task => {
              return task.occurrences.find(o => o.bgColor !== '#a1b56c')
            })
          })

      } else {
        const tasksAndPrice: RequestPriceAndTasks = {
          serviceRate: {
            type: this.request.service.rate.type,
            price: {
              currency: Currency.EUR,
              amount: this.request.service.rate.price.amount /*calculatePriceWithoutPercentage(this.request.service.rate.price, configStore.serverConfig)*/
            }
          },
          tasks: tasks
        }

        await this.withRequest(requestServiceStore.updatePriceAndTasksDraft(tasksAndPrice), requestErrorHandler, true)
          .then((r) => {
            this.request.price = {
              servicePrice: r.servicePrice,
              tax: r.tax,
              total: r.total,
              paymentFee: r.platformFee,
              platformFee: r.platformFee
            }
            this.scheduleList = convertTasksSeriesToInternalUse(r.tasks)
            this.scheduleList = addDealDataToTaskOccurrences(this.scheduleList, this.request)
            this.setCalendarWeek()
          })
          .catch((e) => {
            console.log(e.code)
            this.scheduleList = this.scheduleList.filter(task => {
              return task.occurrences.find(o => o.bgColor !== '#a1b56c')
            })
          })
      }

      if (this.redirectAfterConfirm) {
        const notConfirmedTasks = this.scheduleList.filter(it => {
          return it.occurrences?.find(t => t.state?.confirmed === false)
        })
        if (notConfirmedTasks.length === 0) {
          await this.onSubmit(ActionType.Rate)
        }
      }
    }
  }

  protected get isRequester(): boolean {
    const userId = authStore.authInfo?.userId
    return (isEmpty(this.request.status) || this.request.service.provider.userId !== userId)
  }

  protected get showLockBtn(): boolean {
    return (
      (this.request.lockState.status === LockState.NotLocked
      && (this.request.status === DealStatus.UnderDiscussion
      || this.request.status === DealStatus.New))
    )
  }

  protected get requestDraft(): boolean {
    return isEmpty(this.request.status)
  }

  protected get allowChanges(): boolean {
    return (
      ((this.request.lockState.status === LockState.LockedByYou
      || this.request.lockState.status === LockState.AlreadyLockedByYou)
      && (this.request.status === DealStatus.New
      || this.request.status === DealStatus.UnderDiscussion))
      || this.requestDraft
    )
  }

  protected get showMessenger(): boolean {
    return (!this.requestDraft && this.request.status !== DealStatus.New)
  }

  protected get requestIsLocked(): boolean {
    return (this.request.lockState.status === LockState.LockedByYou
      || this.request.lockState.status === LockState.AlreadyLockedByYou
      || this.request.lockState.status === LockState.AlreadyLockedByOther)
  }

  protected get dealFinished() : boolean {
    return (this.request.status === DealStatus.PaidToProvider || this.request.status === DealStatus.Confirmed || this.request.status === DealStatus.Canceled || this.request.status === DealStatus.Declined);
  }

  private async fetchRequest(silentUpdate?: boolean): Promise<void> {

    setTimeout(() => {
      const requestId = this.$route.params.requestId
      const badges: BadgeEvent[] = badgesStore.getBadgeEvents
      const relativeBadge = badges.find(it => it.docId === requestId)
      if (relativeBadge !== undefined && requestId !== undefined) {
        this.withRequest(badgesStore.deleteBadgeCall(relativeBadge.id), undefined, true).then(() => {
          badgesStore.removeInternalBadgeEvent(relativeBadge)
        })
      }
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, 5000)

    const serviceId = this.$route.params.serviceId
    if (serviceId !== undefined) {

      const resp = await this.withRequest(requestServiceStore.getServiceDetailsById(serviceId), requestErrorHandler)
      const settings = await this.withRequest(requestServiceStore.getSettings(), requestErrorHandler)
      /*const serviceProviderQualificationPayload: ServiceProviderQualificationPayload = {
        userId: resp.service.userId!,
        category: resp.service.category
      }*/
      //this.request.service.category = resp.service.category
      this.request.title = resp.service.title!
      this.request.service.provider = resp.provider
      this.request.service.title = resp.service.title
      this.request.service.providedAtClientPlace = resp.service.providedAtClientPlace
      this.request.service.provider.userId = resp.service.userId!
      // this.request.service.category = resp.service.category
      this.request.service.provider.sex = resp.provider.sex
      this.request.service.rate = {
        type: resp.service.rate.type,
        price: {
          currency: Currency.EUR,
          amount: resp.service.rate.price.amount
        }
      }
      this.request.service.languages = resp.service.languages
      //this.request.service.qualification = await this.withRequest(userStore.getServiceProviderQualification(serviceProviderQualificationPayload))
      this.request.service.provider.rating = resp.provider.rating
      this.request.service.location = resp.service.location

      this.request.service.description_ru = resp.service.description_ru
      this.request.service.description_et = resp.service.description_et
      this.request.service.description_en = resp.service.description_en

      this.request.service.scheduleInfo_ru = resp.service.scheduleInfo_ru
      this.request.service.scheduleInfo_et = resp.service.scheduleInfo_et
      this.request.service.scheduleInfo_en = resp.service.scheduleInfo_en

      this.request.service.clientWeight = resp.service.clientWeight
      this.request.service.clientAge = resp.service.clientAge
      this.request.service.clientSex = resp.service.clientSex
      this.request.service.clientPhysicalActivity = resp.service.clientPhysicalActivity
      this.request.service.clientMentalAbility = resp.service.clientMentalAbility
      this.request.service.clientDisease = resp.service.clientDisease

      this.request.service.competencesWithParent = resp.competencesWithParent

      this.request.requester.name = settings.profile.name
      this.request.requester.verified = settings.profile.verified!
      this.request.requester.sex = settings.profile.sex
      this.request.requester.name = settings.profile.name
      //this.request.clientSex = settings.profile.sex TODO: it can be preset as "unset"

      this.request.requester.location = {
        address: settings.profile.location.address,
        coordinates: {
          longitude: settings.profile.location.coordinates.longitude,
          latitude: settings.profile.location.coordinates.latitude
        }
      }

      const demandId = this.$route.query.demandId
      if (demandId !== undefined) {
        const demand = await this.withRequest(proposalDemandStore.getDemandDetailsById(demandId as string))

        this.request.requester.location = demand.demand.location
        this.request.requester.languages = demand.demand.languages
        this.request.clientSex = demand.demand.clientSex
        this.request.clientWeight = demand.demand.clientWeight
        this.request.demandDescription_ru = demand.demand.demandDescription_ru
        this.request.demandDescription_et = demand.demand.demandDescription_et
        this.request.demandDescription_en = demand.demand.demandDescription_en

        this.request.clientPhysicalActivity = demand.demand.clientPhysicalActivity
        this.request.clientMentalAbility = demand.demand.clientMentalAbility
        this.request.clientDisease = demand.demand.clientDisease
        this.request.clientAge = demand.demand.clientAge

        this.request.clientRequirementsWithParent = demand.clientRequirementsWithParent
      }

      const requesterRatingPayload: GetRatingPayload = {
        userId: settings.profile.id!,
        role: ActorRole.Requester
      }
      const requesterRating = await this.withRequest(userStore.getUserRating(requesterRatingPayload), requestErrorHandler, true)
      this.request.requester.rating = {
        grade: requesterRating.grade,
        ratingsCount: requesterRating.ratingsCount
      }
      this.requestLoaded = true
    }

    const requestId = this.$route.params.requestId
    if (requestId !== undefined) {
      const request = await this.withRequest(requestServiceStore.getRequestById(requestId), requestErrorHandler, silentUpdate)
      this.dismissCountDown = request.lockState.timeLeftInSeconds

      this.request = request
      this.requestLoaded = true
      this.scheduleList = convertTasksSeriesToInternalUse(request.tasks)
      this.scheduleList = addDealDataToTaskOccurrences(this.scheduleList, this.request)
      this.setCalendarWeek()

      if (this.showMessenger) {
        this.messages = chatStore.getChatMessages
      }
    }

    if (this.isRequester) {
      this.actor = ActorRole.Requester
    } else {
      this.actor = ActorRole.Provider
    }

    if (!isEmpty(this.request.service.provider.userId)) {
      const payload: BusyTimePayload = {
        providerId: this.request.service.provider.userId!,
        startDate: this.selectedCalendarWeek.format(serverDateFormat)
      }
      const resp = await this.withRequest(userStore.getProviderBusyTime(payload), requestErrorHandler, true)
      this.busyScheduleList = buildBusyTimeSchedule(resp, this.scheduleList)
    }
  }

  private onAddCalendarTask(): void {
    let newTaskDate = moment();

    if (this.selectedCalendarWeek.isAfter(moment(), 'day')) {
      newTaskDate = this.selectedCalendarWeek;
    }

    this.tasksCalendar.addTask(newTaskDate);
  }

  private setCalendarWeek(): void {
    if (this.dealFinished && this.scheduleList.length !== 0) {
      let lastOccurrenceDate: null | moment.Moment = null;

      this.scheduleList.map(task => {
        const taskLastOccurrence = task.occurrences[task.occurrences.length - 1];
        if (lastOccurrenceDate === null || moment(taskLastOccurrence.end).isAfter(lastOccurrenceDate)) {
          lastOccurrenceDate = moment(taskLastOccurrence.end)
        }
      })

      if (lastOccurrenceDate !== null) {
        this.tasksCalendar.setSelectedWeek(lastOccurrenceDate);
      }
    }
  }

  private async onSubmit(action: ActionType, silentUpdate?: boolean): Promise<void> {
    const demandId = this.$route.query.demandId as string
    if (this.checkValidation(this.$v)) {
      const scheduleList: InitialTaskData[] = convertTasksIntoServerFormat(this.scheduleList)

      if (action === ActionType.Create) {
        const request: NewRequest = {
          serviceId: this.$route.params.serviceId,
          tasks: scheduleList,
          location: this.request.service.providedAtClientPlace ? this.request.requester.location : undefined,
          clientSex: this.request.clientSex,
          clientWeight: this.request.clientWeight !== undefined ? this.request.clientWeight : undefined,
          languages: this.request.requester.languages,
          demandId: demandId !== undefined ? demandId : undefined
        }
        const requestId = await this.withRequest(requestServiceStore.postNewRequest(request), requestErrorHandler)
        await this.$router.push({
          name: MainRoutes.DealAnnouncer.name,
          params: onCreateRequestPayload(this.request, requestId)
        })
      }

      if (action === ActionType.Update) {
        if (this.isRequester) {
          const payload: RequestUpdatePayload = {
            requestId: this.$route.params.requestId,
            tasks: scheduleList
            //donate: this.request.price.donate
          }
          if (silentUpdate) {
            await this.withRequest(requestServiceStore.updateRequestByRequester(payload), requestErrorHandler, silentUpdate)
            await this.fetchRequest(true)
          } else {
            await this.withRequest(requestServiceStore.updateRequestByRequester(payload), requestErrorHandler)
            await this.$router.push({
              name: MainRoutes.DealAnnouncer.name,
              params: onRequestUpdateByRequester(this.request)
            })
          }
        } else {
          const payload: RequestUpdatePayload = {
            requestId: this.$route.params.requestId,
            tasks: scheduleList,
            price: this.request.price.servicePrice,
            calculatePrice: this.request.calculatePrice
          }
          if (silentUpdate) {
            await this.withRequest(requestServiceStore.updateRequestByProvider(payload), requestErrorHandler, silentUpdate)
            await this.fetchRequest(true)
          } else {
            await this.withRequest(requestServiceStore.updateRequestByProvider(payload), requestErrorHandler)
            await this.$router.push({
              name: MainRoutes.DealAnnouncer.name,
              params: onRequestUpdateByProvider(this.request)
            })
          }
        }
      }

      if (action === ActionType.AcceptToDiscussion) {
        const requestId = this.$route.params.requestId
        await this.withRequest(requestServiceStore.acceptRequestToDiscussion(requestId), requestErrorHandler)
        await this.$router.push({
          name: MainRoutes.DealAnnouncer.name,
          params: onRequestAcceptToDiscussion(this.request)
        })
      }

      if (action === ActionType.AcceptCurrentVersion) {
        const payload: AcceptReqVersionPayload = {
          requestId: this.$route.params.requestId,
          reqVersion: this.request.version!,
          termsAccepted: this.termsAccepted
        }
        await this.withRequest(requestServiceStore.acceptCurrentVersion(payload), requestErrorHandler)
          .then(resp => {
            if (this.isRequester) {
              this.$router.push({
                name: MainRoutes.DealAnnouncer.name,
                params: onRequestCurrentVersionAcceptByRequester(this.request, resp.data.dealConcluded)
              })
            } else {
              this.$router.push({
                name: MainRoutes.DealAnnouncer.name,
                params: onRequestCurrentVersionAcceptByProvider(this.request, resp.data.dealConcluded)
              })
            }
          })
          .catch(err => {
            if (err.code === ErrorCodes.ServiceRequestIsLocked) {
              this.fetchRequest(true)
            }
          })
      }
      if (action === ActionType.Pay) {
        const payload: RequestPaymentPayload = {
          requestId: this.$route.params.requestId,
          paymentTermsAccepted: this.paymentTermsAccepted
        }
        this.paymentMethods = await this.withRequest(requestServiceStore.payForServiceInRequest(payload), requestErrorHandler, true)

        if (this.paymentMethods.bankLinks.length === 0 && this.request.price.total?.amount === '0.00') {
          await this.$router.push({
            path: `${MainRoutes.PaymentSuccess.path}/${this.request.id}`,
            params: {
              dealId: this.request.id!
            },
            query: {
              type: DealType.Request
            }
          })
        }
      }
    }

    if (action === ActionType.Cancel) {
      await this.withRequest(requestServiceStore.cancelRequest(this.$route.params.requestId), requestErrorHandler)
      if (this.isRequester) {
        await this.$router.push({
          name: MainRoutes.DealAnnouncer.name,
          params: onRequestCancelByRequester(this.request)
        })
      } else {
        await this.$router.push({
          name: MainRoutes.DealAnnouncer.name,
          params: onRequestCancelByProvider(this.request)
        })
      }
    }

    if (action === ActionType.Rate) {
      const requestId = this.$route.params.requestId
      if (this.isRequester) {
        await this.$router.push({
          name: MainRoutes.DealAnnouncer.name,
          params: onRequestRateByRequester(this.request, requestId)
        })
      } else {
        await this.$router.push({
          name: MainRoutes.DealAnnouncer.name,
          params: onRequestRateByProvider(this.request, requestId)
        })
      }
    }
  }

  private get onLockStateCountDown(): number {
    if (this.dismissCountDown <= 0) {
      // TODO: restore donation. step 5
      //this.initialDonationAmount = ''
      this.fetchRequest()
    }
    return this.dismissCountDown
  }

  private handleCountdownPosition(): void {
    this.$nextTick(() => {
      window.addEventListener('scroll', scrollListner)
      scrollListner()
    })
  }

  public buildCountDown(): VNode {
    const countDownTime = formatCountDown(this.dismissCountDown!)

    return (
      <div class="countdown-relative-replacer mt-4">
        <div class="count-down-popup text-center">
          <b-alert
            show={this.onLockStateCountDown}
            variant="warning"
            onDismiss-count-down={(v: number) => {this.dismissCountDown = v}}
          >
            <span>
              {this.translation(`msg_request_${this.request.lockState.status}`)}
              <span class={countDownTime!.toString().startsWith('0:') ? 'text-danger' : ''}>
                {countDownTime}
              </span>
            </span>
          </b-alert>
        </div>
        {this.handleCountdownPosition()}
      </div>
    )
  }

  public buildAttention(): VNode {
    return (
      <b-row>
        <b-col cols="12" class="text-center">
          {this.request.status === DealStatus.DealConcluded &&
            <b-alert class="mt-4" variant="success" show={true}>{this.translation(`lbl_status_${this.request.status}`)}</b-alert>
          }
          {(this.request.status === DealStatus.Canceled || this.request.status === DealStatus.Declined) &&
            <b-alert class="mt-4" variant="danger" show={true}>{this.translation(`lbl_status_${this.request.status}`)}</b-alert>
          }
          {this.request.changesToAccept !== undefined && this.request.changesToAccept.length > 0 && !(this.request.status === DealStatus.Canceled || this.request.status === DealStatus.Declined) &&
            <b-alert class="mt-4" variant="warning" show={true}>
              {this.actor === ActorRole.Requester ?
                this.translation('lbl_changes_to_accept_for_provider') : this.translation('lbl_changes_to_accept_for_requester')
              }
              {this.request.changesToAccept?.map((it, i) => {
                return <b> {this.translation(`enum_request_change_${it}`) + `${i < this.request.changesToAccept!.length-1 ? ', ' : '.'}`}</b>
              })}
            </b-alert>
          }
          {(this.request.acceptedLatestVersion && this.request.status === DealStatus.UnderDiscussion && this.request.lockState.status === LockState.NotLocked) &&
            <b-alert class="mt-4" variant="warning" show={true}>
              {this.actor === ActorRole.Requester ? this.translation('msg_awaiting_decision_by_requester') : this.translation('msg_awaiting_decision_by_provider')}
            </b-alert>
          }
        </b-col>
      </b-row>
    )
  }

  public buildDataSection(): VNode {
    const scheduleInfo = {
      scheduleInfo_ru: this.request?.service?.scheduleInfo_ru,
      scheduleInfo_et: this.request?.service?.scheduleInfo_et,
      scheduleInfo_en: this.request?.service.scheduleInfo_en
    }
    const description = {
      description_ru: this.request?.service?.description_ru,
      description_et: this.request?.service?.description_et,
      description_en: this.request?.service?.description_en
    }
    const demandDescription = {
      demandDescription_ru: this.request?.demandDescription_ru,
      demandDescription_et: this.request?.demandDescription_et,
      demandDescription_en: this.request?.demandDescription_en
    }

    return (
      <div>
        {this.buildAttention()}
        <b-row>
          <PageHeader
            headerClass="my-10 mt-lg-4"
            leadClass="text-sm"
            title={this.request.title}
            wideHeader={true}
          >
            {!this.requestDraft &&
              <span class="text-nowrap mr-4">{this.translation('lbl_service_created')}: {moment(this.request.createdAt).locale(this.$i18n.locale).format('L')}</span>
            }
            {!isEmpty(this.request.agreementNumber) &&
              <span class="text-nowrap">
                {this.translation('lbl_request_agreement_number')}
                <Link css-class="text-primary" href={`${BackendUrls.urlApi}${BackendUrls.file}/${encodeURIComponent(this.request.agreementFileId)}`} txt={this.request.agreementNumber} />
              </span>
            }
          </PageHeader>
        </b-row>
        <CollapseSection
          class="mb-6"
          title={this.translation('title_transaction_parties')}
          visible={this.requestLoaded && (this.requestDraft || this.request.status === DealStatus.New)}
        >
          {this.actor === ActorRole.Requester &&
            <Notice
              noticeClass="mt-4 mt-md-8 mb-2"
              dismissible
              noticeKey="transactionParties"
              variant="warning"
            >
              <p>{this.translation('msg_transaction_parties')}</p>
            </Notice>
          }
          <b-row>
            <b-col cols="12" md="6" order={this.actor === ActorRole.Provider ? '2' : '1'} class="mt-8 mb-n2">
              <h3 class="h6 mb-0">{this.translation(this.actor === ActorRole.Provider ? 'title_is_service_provider' : 'title_service_provider')}</h3>
              <RequestProviderDetails
                request={this.request}
                serviceProviderRating={this.request.service.provider.rating}
              />
              {this.actor === ActorRole.Requester &&
              <div>
                <b-button 
                  v-b-modal={'assistant-skills-modal'}
                  variant="outline-primary"
                  class="mt-2 mb-4"
                >
                  {this.translation('lbl_service_provider_skill')}
                </b-button>
                <b-modal
                  ok-title={this.translation('btn_close')}
                  ok-only
                  centered
                  id="assistant-skills-modal"
                  scrollable
                  size="xl"
                  title={this.translation('title_service_provider_skill_modal', [`${this.request.service.provider.name.first} ${this.request.service.provider.name.last.charAt(0)}.`])}
                  title-tag="h2"
                >
                  <template slot="modal-header-close">
                    <AppIconClose class="text-primary" />
                  </template>
                  <template slot="default">
                    <h3 class="fieldset-title">{this.translation('title_schedule_info')}</h3>
                    <MultiLangTabs
                      value={{
                        scheduleInfo_ru: scheduleInfo.scheduleInfo_ru,
                        scheduleInfo_et: scheduleInfo.scheduleInfo_et,
                        scheduleInfo_en: scheduleInfo.scheduleInfo_en
                      }}
                    />
                    <h3 class="fieldset-title">{this.translation('title_service_description')}</h3>
                    <MultiLangTabs
                      value={{
                        description_ru: description.description_ru,
                        description_et: description.description_et,
                        description_en: description.description_en
                      }}
                    />
                    <ServiceDetailsClientParameters
                      accordionTitleClass="h3"
                      value={this.request.service}
                      optionsWithCategoryName={this.request.service.competencesWithParent}
                    />
                  </template>
                </b-modal>
              </div>
              }
            </b-col>
            <b-col cols="12" md="6" order={this.actor === ActorRole.Provider ? '1' : '2'} class="mt-8 mb-n2">
              <h3 class="h6 mb-0">{this.translation(this.actor === ActorRole.Requester ? 'title_is_requester' : 'title_requester')}</h3>
              <RequestRequesterDetails
                request={this.request}
                serviceRequesterRating={this.request.requester.rating}
                v={this.$v.request}
              />
              {this.actor === ActorRole.Provider && ((!isEmpty(this.request.demandDescription_ru) || !isEmpty(this.request.demandDescription_et) || !isEmpty(this.request.demandDescription_en))) &&
              <div>
                <b-button 
                  v-b-modal={'problem-description-modal'}
                  variant="outline-primary"
                  class="mt-2 mb-4"
                >
                  {this.translation('title_description_of_problem')}
                </b-button>
                <b-modal
                  ok-title={this.translation('btn_close')}
                  ok-only
                  centered
                  id="problem-description-modal"
                  scrollable
                  size="xl"
                  title={this.translation('title_description_of_problem')}
                  title-tag="h2"
                >
                  <template slot="modal-header-close">
                    <AppIconClose class="text-primary" />
                  </template>
                  <template slot="default">
                    <MultiLangTabs
                      value={{
                        demandDescription_ru: demandDescription.demandDescription_ru,
                        demandDescription_et: demandDescription.demandDescription_et,
                        demandDescription_en: demandDescription.demandDescription_en
                      }}
                    />
                    <DemandDetailsClientParameters
                      accordionTitleClass="h3"
                      value={this.request}
                      optionsWithCategoryName={this.request.clientRequirementsWithParent}
                    />
                  </template>
                </b-modal>
              </div>
              }
            </b-col>
          </b-row>
        </CollapseSection>
      </div>
    )
  }

  public scrollToTasks(): void {
    const scheduleList = document.getElementById('request-tasks')
    if (scheduleList !== null) {
      scheduleList.scrollIntoView({behavior: "auto", block: "center"})
    }
  }

  private async onSetTaskComplete(occurrenceId: string): Promise<void> {
    const requestTaskAsCompletePayload: RequestTaskAsCompleteOrConfirmPayload = {
      requestId: this.$route.params.requestId,
      taskId: occurrenceId
    }
    await this.withRequest(requestServiceStore.setTaskComplete(requestTaskAsCompletePayload), requestErrorHandler, true)
    await this.fetchRequest(true)
    const isAllTasksCompleted = this.request.status === DealStatus.Completed
    this.messagesPayload.successMessageKey = isAllTasksCompleted ? 'msg_all_tasks_completed' : 'msg_task_completed'
    EventBus.$emit(CustomEvents.FlashMsg, this.messagesPayload)
    EventBus.$on(CustomEvents.DismissMsg, () => (this.messagesPayload.successMessageKey = null))
    if (isAllTasksCompleted) {
      this.scrollToTasks()
    }
  }

  private async onSetTaskCompleteConfirm(occurrenceId: string): Promise<void> {
    const requestTaskAsCompleteConfirmPayload: RequestTaskAsCompleteOrConfirmPayload = {
      requestId: this.$route.params.requestId,
      taskId: occurrenceId
    }
    await this.withRequest(requestServiceStore.setTaskCompleteConfirm(requestTaskAsCompleteConfirmPayload), requestErrorHandler, true)
    await this.fetchRequest(true)
    const someNotConfirmed = this.request.status === DealStatus.Completed
    const isAllTasksConfirmed = this.request.status === DealStatus.Confirmed
    this.messagesPayload.successMessageKey = isAllTasksConfirmed ? 'msg_all_tasks_confirmed' : 'msg_task_confirmed'
    EventBus.$emit(CustomEvents.FlashMsg, this.messagesPayload)
    EventBus.$on(CustomEvents.DismissMsg, () => (this.messagesPayload.successMessageKey = null))
    if (!someNotConfirmed) {
      this.scrollToTasks()
    }
  }

  private async updateBusyTime(date: Date): Promise<void> {
    this.selectedCalendarWeek = moment(date)
    if (!isEmpty(this.request.service.provider.userId)) {
      const payload: BusyTimePayload = {
        providerId: this.request.service.provider.userId!,
        startDate: moment(date).format(serverDateFormat)
      }
      const resp = await this.withRequest(userStore.getProviderBusyTime(payload), requestErrorHandler, true)
      this.busyScheduleList = buildBusyTimeSchedule(resp, this.scheduleList)
    }
  }

  private get allTasks(): TaskDefinition[] {
    return this.scheduleList.concat(this.busyScheduleList)
  }

  protected get shouldShowCalendarDetails(): boolean {
    return (this.request.status === DealStatus.Paid && !this.isRequester)
      ||
      (this.request.status === DealStatus.Completed && this.isRequester)
      ||
      (this.request.status === DealStatus.Paid && this.scheduleList.some(st => st.occurrences?.some(o => (o.state?.completed && !o.state.confirmed)) && this.isRequester)) // TODO: check
  }

  public buildScheduleSection(): VNode {
    const allowDiary = (
      this.request.status === DealStatus.Paid
      || this.request.status === DealStatus.Completed
      || this.request.status === DealStatus.Confirmed
      || this.request.status === DealStatus.PaidToProvider
    )
      && this.request.service.competencesWithParent.find(c => {
        return c.subCategories?.some(sc => sc?.category === 'NurseCareDiary')
      })
    return (
      <div>
        <ContentSection id="request-tasks" class="mb-6">
          <h2 class="fieldset-title">{this.translation('title_tasks')}</h2>
          {this.actor === ActorRole.Requester && 
            <Notice
              noticeClass="mt-4 mt-md-8 mb-2"
              dismissible
              noticeKey="transactionTasks"
              variant="warning"
            >
              <p>{this.translation('msg_transaction_tasks')}</p>
            </Notice>
          }
          <CompactScheduleCalendar
            class="mt-6"
            disabled={!this.allowChanges}
            ref="tasksCalendar"
            canComplete={this.actor === ActorRole.Provider && this.request.status === DealStatus.Paid}
            canConfirm={this.actor === ActorRole.Requester}
            selectedTask={this.selectedTask}
            showCalendarComponent={this.showCalendarComponent}
            showLockBtn={this.showLockBtn}
            title={this.request.service.title}
            value={this.allTasks}
            availableServices={this.request.service.competencesWithParent}
            onAddCalendarEvent={(event: TaskDefinition) => {
              this.scheduleList = addOrUpdateEvent(event, this.scheduleList)
              this.fetchPriceAndTasks()
            }}
            onLockToEdit={() => this.onLockAndSilentFetchRequest()}
            onRemoveCalendarEvent={(event: TaskDefinition) => {
              this.scheduleList = removeEvent(event, this.scheduleList)
              this.fetchPriceAndTasks()
            }}
            onSetTaskComplete={(occurrenceId: string) => {this.onSetTaskComplete(occurrenceId)}}
            onSetTaskCompleteConfirm={(occurrenceId: string) => {this.onSetTaskCompleteConfirm(occurrenceId)}}
            onScheduleError={(err: string) => {
              this.errorMessageKey = err
            }}
            onTaskOpened={() => (this.selectedTask = {})}
            onWeekChanged={(date: Date) => this.updateBusyTime(date)}
          >
            <template slot="headerControls">
              <b-button-group class="ml-auto mt-sm-4">
                <b-button
                  aria-label={this.translation('lbl_show_calendar')}
                  pressed={this.showCalendarComponent}
                  class="d-flex justify-content-center px-3"
                  variant={this.showCalendarComponent ? 'primary' : 'outline-primary'}
                  onClick={() => {
                    this.showCalendarComponent = true
                  }}
                >
                  <b-icon-calendar4-week class="app-icon-lg d-flex" aria-hidden="true"/>
                </b-button>
                <b-button
                  aria-label={this.translation('lbl_show_tasks_list')}
                  pressed={!this.showCalendarComponent}
                  class="d-flex justify-content-center px-3"
                  variant={this.showCalendarComponent ? 'outline-primary' : 'primary'}
                  onClick={() => {
                    this.showCalendarComponent = false
                  }}
                >
                  <b-icon-list-ul class="app-icon-lg d-flex" aria-hidden="true"/>
                </b-button>
              </b-button-group>
            </template>
          </CompactScheduleCalendar>
          {!this.showCalendarComponent &&
            <div>
              {!this.busy && this.scheduleList.length === 0 &&
                <div class="bg-gray-100 rounded overflow-hidden d-flex align-items-center justify-content-center mt-10">
                  <div class="my-8 p-6 d-flex flex-column align-items-center justify-content-center text-center no-results-found">
                    <h3 class="mb-1 h4">{this.translation('lbl_section_empty')}</h3>
                    <p class="small">{this.translation('lbl_list_of_events_empty')}</p>
                    <b-button
                      variant="primary"
                      onClick={this.onAddCalendarTask}
                    >
                      {this.translation('btn_add_task')}
                    </b-button>
                  </div>
                </div>
              }
              {this.scheduleList.length > 0 && this.tasksCalendar &&
                <div>
                  {(this.showLockBtn || this.allowChanges) &&
                    <div class="d-flex flex-wrap justify-content-between mt-4 mt-md-6">
                      {!this.showLockBtn && this.allowChanges &&
                        <b-button
                          class="d-flex"
                          variant="primary"
                          onClick={this.onAddCalendarTask}
                        >
                          <b-icon-plus-circle class="app-icon-lg mr-2" aria-hidden="true" />
                          {this.translation('btn_add_task')}
                        </b-button>
                      }
                      {this.showLockBtn &&
                        <Button class="ml-auto" variant="warning" onClick={() => {this.onLockAndSilentFetchRequest()}} label={this.translation('btn_change')}/>
                      }
                    </div>
                  }
                  <ScheduleList
                    canComplete={this.actor === ActorRole.Provider && this.request.status === DealStatus.Paid}
                    canConfirm={this.actor === ActorRole.Requester}
                    disabled={!this.allowChanges}
                    selectedWeek={this.tasksCalendar!.selectedWeekDateRange()}
                    showLockBtn={this.showLockBtn}
                    value={this.scheduleList}
                    onSetTaskComplete={(taskId: string) => {this.onSetTaskComplete(taskId)}}
                    onSetTaskCompleteConfirm={(taskId: string) => {this.onSetTaskCompleteConfirm(taskId)}}
                    onToggleTaskDetails={(task: TaskDefinition | undefined) => {
                      this.selectedTask = task ? task : {};
                    }}
                  />
                </div>
              }
            </div>
          }
        </ContentSection>
        {allowDiary &&
          <Diary
            isProvider={!this.isRequester}
            dealType={DealType.Request}
            dealId={allowDiary ? this.$route.params.requestId : undefined}
            dealStatus={this.request.status}
          />
        }
      </div>
    )
  }

  private async onSendMessage(messagePayload: Message): Promise<void> {
    const payload: RequestNewMessagePayload = {
      message: messagePayload,
      requestId: this.$route.params.requestId
    }
    const sentMsg = await this.withRequest(requestServiceStore.sendMessage(payload), requestErrorHandler, true)
    chatStore.addMessage(sentMsg)
  }

  private get otherPersonName(): string {
    const otherPerson = this.actor === ActorRole.Requester ? this.request.service.provider.name : this.request.requester.name

    return `${otherPerson.first} ${otherPerson.last}`
  }

  private deleteMsgCenterEvent(msgId: string): void {
    _.cloneDeep(chatStore.contacts).map(event => {
      event.deals.map(deal => {
        if (deal.dealId === this.request.id && Number(deal.dealUnreadedMessageCount) > 0) {
          this.messages = chatStore.getChatMessages
          chatStore.setEventAsReadedInternal(this.$route.params.requestId)
          this.withRequest(chatStore.setEventAsReaded({msgId: msgId, dealType: DealType.Request.toUpperCase()}), requestErrorHandler, true)
        }})
    })
  }

  public buildMessengerSection(): VNode {
    return (
      <ContentSection class="mb-6">
        <h2 class="fieldset-title">{this.translation('title_messages')}</h2>
        {!this.showMessenger &&
          <div class="bg-gray-100 rounded overflow-hidden d-flex align-items-center justify-content-center mt-10">
            <div class="my-8 p-6 d-flex flex-column align-items-center justify-content-center text-center no-results-found">
              <p class="small mb-0">{this.translation(`lbl_messages_disabled_description_${this.actor}`)}</p>
            </div>
          </div>
        }
        {this.showMessenger &&
          <Messenger
            onMessengerError={(err: string) => (this.errorMessageKey = err)}
            v-model={this.messages}
            viewForCurrentUserById={this.actor === ActorRole.Requester ? this.request.requester.userId : this.request.service.provider.userId}
            otherPersonName={this.otherPersonName}
            onSendMessage={(messagePayload: Message) => this.onSendMessage(messagePayload)}
            disabled={this.dealFinished}
            onChatInputFocus={(msgId: string) => this.deleteMsgCenterEvent(msgId)}
          />
        }
      </ContentSection>
    )
  }

  private async onLockAndSilentFetchRequest(): Promise<void> {
    if (this.request.lockState.status === LockState.NotLocked) {
      await this.withRequest(requestServiceStore.lockRequest(this.$route.params.requestId), requestErrorHandler, true)
      await this.fetchRequest(true)
    }
  }

  public buildRatesAndPricesSection(): VNode {
    return (
      <ContentSection>
        <h2 class="fieldset-title">
          {(this.requestDraft || this.request.status === DealStatus.New || this.request.status === DealStatus.Canceled || this.request.status === DealStatus.UnderDiscussion || this.request.status === DealStatus.Declined) && this.translation('title_preliminary_cost_of_services')}
          {(!this.requestDraft && this.request.status !== DealStatus.New && this.request.status !== DealStatus.Canceled && this.request.status !== DealStatus.UnderDiscussion && this.request.status !== DealStatus.Declined) && this.translation('title_cost_of_services')}
        </h2>
        <div class="px-4 mb-6">
          <b-row class="mt-10 pb-1 align-items-center">
            <b-col cols="12" md="7" lg="8">
              {this.request.changesToAccept !== undefined && this.request.changesToAccept.length &&
                <InfoNotice
                  class="mr-md-8 mb-md-0"
                  icon="exclamation-triangle-fill"
                  variant="warning"
                >
                  <p class="h5 m-0">{this.translation('msg_changes_to_accept_attention')}</p>
                </InfoNotice>
              }
              {((this.request.changesToAccept === undefined || this.request.changesToAccept.length === 0) && this.request.status !== DealStatus.DealConcluded && this.request.status !== DealStatus.Paid && this.request.status !== DealStatus.Completed && this.request.status !== DealStatus.Confirmed && this.request.status !== DealStatus.PaidToProvider) &&
                <InfoNotice
                  class="mr-md-8 mb-md-0"
                  icon="exclamation-circle"
                >
                  {this.translation('estimated_cost_description')}
                </InfoNotice>
              }
              {(this.request.status === DealStatus.Paid || this.request.status === DealStatus.Completed) &&
                <InfoNotice
                  class="mr-md-8 mb-md-0"
                  icon="exclamation-circle"
                >
                  {this.actor === ActorRole.Requester && this.translation('requester_cost_description')}
                  {this.actor === ActorRole.Provider && this.translation('provider_cost_description')}
                </InfoNotice>
              }
            </b-col>
            <b-col cols="12" md="5" lg="4">
              {!this.isRequester && !(this.request.status === DealStatus.UnderDiscussion || this.request.status === DealStatus.New || this.requestDraft) &&
                <div>
                  {this.translation('lbl_service_price')}:
                  <span class="font-weight-bold float-right">
                    {isEmpty(this.request.price.servicePrice.amount) ? '0.00' : this.request.price.servicePrice.amount} €
                  </span>
                </div>
              }
              {!this.isRequester && (this.request.status === DealStatus.UnderDiscussion || this.request.status === DealStatus.New || this.requestDraft) &&
                <div>
                  <CheckBox
                    disabled={!(this.request.status === DealStatus.New || this.request.status === DealStatus.UnderDiscussion) || this.request.lockState.status === LockState.AlreadyLockedByOther}
                    label={this.translation('lbl_automatic_price_calculation')}
                    checked={this.request.calculatePrice}
                    class="mb-1"
                    onChange={(v: boolean) => {
                      this.onLockAndSilentFetchRequest().then(() => {
                        this.request.calculatePrice = v
                        this.fetchPriceAndTasks().then(() => {
                          if (this.request.calculatePrice !== v) {
                            throw new Error('sync failed')
                          }
                        })
                      })
                    }}
                  />
                  <TextInput
                    disabled={(this.request.lockState.status === LockState.AlreadyLockedByOther || !(this.request.status === DealStatus.New || this.request.status === DealStatus.UnderDiscussion)) || this.request.calculatePrice}
                    label={this.translation('lbl_service_price')}
                    class="mb-3"
                    v-model={this.request.price.servicePrice.amount}
                    onFocus={() => this.onLockAndSilentFetchRequest()}
                    onBlur={() => {
                      if (this.checkValidation(this.$v.request.price!.servicePrice.amount)) {
                        this.fetchPriceAndTasks()
                      }
                    }}
                    invalid-message={this.buildInvalidMessage(this.$v.request.price!.servicePrice.amount)}
                  />
                </div>
              }
              <div>
                {this.isRequester ? this.translation('lbl_total_service_price') : this.translation('lbl_total_price_for_requester')}:
                <strong class="float-right">
                  {isEmpty(this.request.price.total?.amount) ? '0.00' : this.request.price.total!.amount} €
                </strong>
              </div>
            </b-col>
          </b-row>
        </div>
        <hr class="border-bottom border-primary mt-0 mb-6" />
        {this.buildControls()}
      </ContentSection>
    )
  }

  private async onCreateRequest(): Promise<void> {
    await this.onSubmit(ActionType.Create)
  }

  private async onUpdateRequest(silentUpdate?: boolean): Promise<void> {
    await this.onSubmit(ActionType.Update, silentUpdate)
  }

  private async onCancelRequest(): Promise<void> {
    await this.onSubmit(ActionType.Cancel)
  }

  private async onAcceptRequestToDiscussion(): Promise<void> {
    await this.onSubmit(ActionType.AcceptToDiscussion)
  }

  private async onAcceptCurrentVersion(): Promise<void> {
    await this.onSubmit(ActionType.AcceptCurrentVersion)
  }

  private async onPayForDeal(): Promise<void> {
    await this.onSubmit(ActionType.Pay)
  }

  private async onSendRating(): Promise<void> {
    await this.onSubmit(ActionType.Rate)
  }

  private async onBackClick(): Promise<void> {
    if ((this.request.lockState.status === LockState.LockedByYou || this.request.lockState.status === LockState.AlreadyLockedByYou)
      && (this.request.status === DealStatus.UnderDiscussion || this.request.status === DealStatus.New)) {
      await this.withRequest(requestServiceStore.unlockRequest(this.$route.params.requestId), requestErrorHandler, true)
    }
    this.$router.go(-1)
  }

  public buildRequesterControls(): VNode {
    return (
      <div class="w-100 order-first order-sm-1 d-flex flex-fill flex-column flex-sm-row justify-content-sm-end mr-n2">
        {(this.request.status === DealStatus.New || this.request.status === DealStatus.UnderDiscussion) &&
          <Button class="mr-2 mb-2 mb-sm-0 order-last order-sm-first" variant="outline-primary" onClick={() => {this.showRejectAttentionPopup = !this.showRejectAttentionPopup}} label={this.translation('btn_annual')} />
        }
        {((this.request.status === DealStatus.New
          || this.request.status === DealStatus.UnderDiscussion)
          && (this.request.lockState.status === LockState.LockedByYou
          || this.request.lockState.status === LockState.AlreadyLockedByYou)) &&
          <Button class="mr-2 mb-2 mb-sm-0" disabled={this.scheduleList.length === 0} variant="primary" onClick={() => this.onUpdateRequest(false)} label={this.translation('btn_ready')} />
        }
        {this.requestDraft &&
          <Button class="mr-2 mb-2 mb-sm-0" disabled={this.scheduleList.length === 0} variant="primary" onClick={() => this.onCreateRequest()} label={this.translation('btn_send_a_request')} />
        }
        {this.request.status === DealStatus.UnderDiscussion
        && this.request.lockState.status === LockState.NotLocked
        && !this.request.acceptedLatestVersion &&
          <Button class="mr-2 mb-2 mb-sm-0" variant="primary" onClick={() => this.onAcceptCurrentVersion()} label={this.translation('btn_make_a_deal')} />
        }
        {(this.request.status === DealStatus.DealConcluded
          || this.request.status === DealStatus.PaymentFailed
          || this.request.status === DealStatus.PaymentStarted)
          && this.paymentMethods.bankLinks.length === 0 &&
          <Button class="mr-2 mb-2 mb-sm-0" variant="primary" label={this.translation('btn_pay')} onClick={() => this.onPayForDeal()} />
        }
        {this.paymentMethods.bankLinks.length > 0 &&
          <div class="payment-methods mr-2 ml-sm-2 mb-2 mb-sm-0">
            <p class="mb-1 text-center text-sm-right">
              {this.translation('lbl_payment_method_opts')}
            </p>
            <div class="payment-method-links d-flex flex-wrap justify-content-center justify-content-sm-end">
              {this.paymentMethods.bankLinks.map(it => {
                return (
                  <Link class="payment-method-link" href={it.url}>
                    <template slot="label">
                      <b-img class="payment-method-logo" src={it.logo_url} />
                    </template>
                  </Link>
                )
              })}
            </div>
          </div>
        }
        {((this.request.status === DealStatus.PaidToProvider || this.request.status === DealStatus.Confirmed) && (this.request.isRatedBy === RatedBy.Nobody || this.request.isRatedBy === RatedBy.Provider)) &&
          <Button id="btn-rate" variant="primary" class="mr-2 mb-2 mb-sm-0 btn-anim-pulse-blue" onClick={() => this.onSendRating()} label={this.translation('btn_rate_provider')} />
        }
      </div>
    )
  }

  public buildProviderControls(): VNode {
    return (
      <div class="w-100 order-first order-sm-1 d-flex flex-fill flex-column flex-sm-row justify-content-sm-end mr-n2">
        {(this.request.status === DealStatus.New || this.request.status === DealStatus.UnderDiscussion) &&
          <Button class="mr-2 mb-2 mb-sm-0 order-last order-sm-first" variant="outline-primary" onClick={() => {this.showRejectAttentionPopup = !this.showRejectAttentionPopup}} label={this.translation('btn_decline')} />
        }
        {(this.request.status === DealStatus.New
          || this.request.status === DealStatus.UnderDiscussion)
          && (this.request.lockState.status === LockState.LockedByYou
          || this.request.lockState.status === LockState.AlreadyLockedByYou) &&
          <Button class="mr-2 mb-2 mb-sm-0" variant={this.scheduleList.length === 0 ? 'outline-primary' : 'primary'} onClick={() => this.onUpdateRequest(this.request.status === DealStatus.New)} label={this.translation('btn_update')} />
        }
        {this.request.status === DealStatus.New
        && this.request.lockState.status === LockState.NotLocked &&
          <Button class="mr-2 mb-2 mb-sm-0" variant="primary" onClick={() => this.onAcceptRequestToDiscussion()} label={this.translation('btn_accept')} />
        }
        {this.request.status === DealStatus.UnderDiscussion
        && this.request.lockState.status === LockState.NotLocked
        && !this.request.acceptedLatestVersion &&
          <Button class="mr-2 mb-2 mb-sm-0" variant="primary" onClick={() => this.onAcceptCurrentVersion()} label={this.translation('btn_make_a_deal')} />
        }
        {(this.request.status === DealStatus.Confirmed || this.request.status === DealStatus.PaidToProvider) && (this.request.isRatedBy === RatedBy.Requester || this.request.isRatedBy === RatedBy.Nobody) &&
          <Button id="btn-rate" variant="primary" class="mr-2 mb-2 mb-sm-0 btn-anim-pulse-blue" onClick={() => this.onSendRating()} label={this.translation('btn_rate_requester')} />
        }
      </div>
    )
  }

  public buildControls(): VNode {
    return (
      <b-row>
        <ConfirmPopup
          btnCancel={true}
          btnCancelLabel={this.translation('btn_modal_cancel')}
          btnClose={true}
          btnConfirm={true}
          btnConfirmLabel={this.translation('btn_modal_ok')}
          message={() => <span>{!this.isRequester ? this.translation('msg_attention_to_decline_request') : this.translation('msg_attention_to_cancel_request')}</span>}
          show={this.showRejectAttentionPopup}
          title={this.translation('lbl_request')}
          onConfirm={() => this.onCancelRequest()}
          onCancel={() => (this.showRejectAttentionPopup = false)}
        />
        {(((this.request.status === DealStatus.DealConcluded
          || this.request.status === DealStatus.PaymentFailed)
          && this.paymentMethods.bankLinks.length === 0) ||
          (this.request.status === DealStatus.PaymentStarted
            && this.paymentMethods.bankLinks.length === 0)) &&
        this.isRequester &&
          <b-col cols="12" class="text-md-right">
            <div class="pl-4 pr-2 pr-md-4">
              <CheckBox
                checkBoxClass="mb-0"
                v-model={this.paymentTermsAccepted}
                invalidMessage={this.buildInvalidMessage(this.$v.paymentTermsAccepted)}
              >
                <template slot="label">
                  <i18n path="lbl_consent_payment_conditions">
                    <Link class="text-primary" to={PaymentAgreement.path} target="_blank" txt={this.translation('lbl_consent_payment_conditions_link')} />
                  </i18n>
                </template>
              </CheckBox>
            </div>
          </b-col>
        }
        {this.request.status === DealStatus.UnderDiscussion
        && this.request.lockState.status === LockState.NotLocked
        && !this.request.acceptedLatestVersion &&
          <b-col cols="12" class="text-md-right">
            <div class="pl-4 pr-2 pr-md-4">
              <CheckBox
                checkBoxClass="mb-0"
                v-model={this.termsAccepted}
                invalidMessage={this.buildInvalidMessage(this.$v.termsAccepted)}
              >
                <template slot="label">
                  <i18n path="msg_contract_consent_agreement">
                    <Link class="text-primary" href={`${BackendUrls.urlApi}${BackendUrls.urlBaseWeb}/static/terms.pdf`} target="_blank" txt={this.translation('msg_contract_consent_inner_link')} />
                  </i18n>
                </template>
              </CheckBox>
            </div>
          </b-col>
        }
        <b-col cols="12">
          <div class="px-md-4 d-flex flex-column flex-sm-row justify-content-between align-items-start app-form-controls">
            <Button class="mr-2 mb-2 mb-sm-0" variant="outline-primary" onClick={() => this.onBackClick()} label={this.translation('btn_back')} />
            {this.actor === ActorRole.Requester && this.buildRequesterControls()}
            {this.actor === ActorRole.Provider && this.buildProviderControls()}
          </div>
        </b-col>
        {((((this.request.status === DealStatus.New
            || this.request.status === DealStatus.UnderDiscussion)
            && (this.request.lockState.status === LockState.LockedByYou
            || this.request.lockState.status === LockState.AlreadyLockedByYou)) || this.requestDraft) && this.scheduleList.length === 0) &&
          <b-col cols="12" class="mt-2 text-sm-right small">
            <div class="px-md-4">
              <span class="text-danger-dark">{this.translation('msg_calendar_empty')}</span>
            </div>
          </b-col>
        }
      </b-row>
    )
  }

  public get navDirection(): string {
    if (this.requestDraft) {
      return `${ServicesRoutes.ServiceFind.name}`
    } else if (this.isRequester) {
      return `${ActivityRoutes.Requests.name}`
    } else {
      return `${ActivityRoutes.Services.name}`
    }
  }

  public preventDefaultEvents(e: Event): void {
    e.preventDefault()
    e.stopPropagation()
  }

  //for reactivity
  protected get getUnreadMessages(): undefined {
    // eslint-disable-next-line no-unused-expressions
    chatStore.getInitialAmountOfUnreadMessages
    return undefined
  }

  public render(): VNode {
    if (chatStore.getChatMessages.length > 0) { //firefox not rendering without this after
      this.messages = chatStore.getChatMessages
    }
    // eslint-disable-next-line no-unused-expressions
    this.getUnreadMessages
    return (
      <div>
        {this.requestIsLocked &&
        (this.request.status === DealStatus.New || this.request.status === DealStatus.UnderDiscussion) &&
          this.buildCountDown()
        }
        <b-container fluid="xl" class="request-page">
          <form onsubmit={(e: Event) => this.preventDefaultEvents(e)}>
            {this.busy && <LoadingIndicator />}
            {!this.busy &&
              <DealLayout
                dataSection={this.buildDataSection}
                scheduleSection={this.buildScheduleSection}
                messengerSection={this.buildMessengerSection}
                confirmationSection={this.buildRatesAndPricesSection}
                requestStatus={this.request.status}
              />
            }
          </form>
        </b-container>
      </div>
    )
  }
}
