import {Component, Prop} from 'vue-property-decorator'
import {VNode} from "vue"

import {TsxComponent} from "@/components/TsxComponent";
import AppIconClose from '../icons/AppIconClose'

interface Props {
  message?: () => VNode;
  btnConfirmLabel?: string;
  btnConfirm: boolean;
  btnCancelLabel?: string;
  btnCancel: boolean;
  btnClose: boolean;
  show: boolean;
  title?: string;
  onCancel? (e: Event): void;
  onConfirm? (e: Event): void;
}

@Component({name: "ConfirmPopup"})
export default class extends TsxComponent<Props> implements Props {

  @Prop({type: Function, required: true}) public readonly message?: () => VNode
  @Prop({type: String, required: true}) public readonly btnConfirmLabel!: string
  @Prop({type: Boolean, required: true}) public readonly btnConfirm!: boolean
  @Prop({type: String, required: true}) public readonly btnCancelLabel!: string
  @Prop({type: Boolean, required: true}) public readonly btnCancel!: boolean
  @Prop({type: Boolean, required: true}) public readonly btnClose!: boolean
  @Prop({type: Boolean, required: false, default: false}) public readonly show!: boolean
  @Prop({type: String, required: false, default: ''}) public readonly title!: string

  private confirmed: boolean = false

  public render(): VNode {
    return (
      <b-modal
        centered
        scrollable
        body-class='pt-14 px-10 pb-16 text-center'
        size="sm"
        hide-header={true}
        hide-footer={true}
        visible={this.show}
        no-close-on-backdrop={this.btnClose ? false : true} // Disable closing by clicking outside when close button is hidden
        no-close-on-esc={this.btnClose ? false : true} // Disable closing by pressing ESC when close button is hidden
        onHidden={() => {
          if (!this.confirmed) {
            this.$emit('cancel')
          } else {
            this.$emit('confirm')
          }

          this.confirmed = false
        }}
        scopedSlots={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          default: ({ hide }: { hide: any }) => {
            return (
              <div>
                {(this.btnClose) &&
                  <b-link class="modal-dialog-close d-block" aria-label={this.translation('btn_cancel')} onClick={() => {hide('forget')}}>
                    <AppIconClose class="text-primary" />
                  </b-link>
                }
                <h3 class="mt-0">{this.title}</h3>
                {this?.message && this.message()}
                <b-row class="mt-6 justify-content-center">
                  <div>
                    {this.btnCancel &&
                      <b-button class="mr-2" variant="outline-primary" onClick={() => {hide('forget')}}>
                        {this.btnCancelLabel}
                      </b-button>
                    }
                    {this.btnConfirm &&
                      <b-button
                        variant="primary"
                        onClick={() => {
                          this.confirmed = true
                          hide('forget')
                        }}
                      >
                        {this.btnConfirmLabel}
                      </b-button>
                    }
                  </div>
                </b-row>
              </div>
            )
          }
        }}
      >
      </b-modal>
    )
  }
}
