import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import {TsxComponent} from "@/components/TsxComponent";
import {DiaryDataForm, Medications, Unit} from "@/types";
import DatePickerInput from "@/components/form/DatePickerInput";
import moment from "moment";
import {serverDateTimeFormat} from "@/constants/ApplicationConfiguration";
import TextInput from "@/components/form/TextInput";
import Button from "@/components/form/Button";
import TextAreaInput from "@/components/form/TextAreaInput";
import {TextInputType} from "@/constants/Elements";
import {castIter, ValidationInstance, ValidationInstanceIter} from "@/utils/vuelidate-extension";
import {DiaryDataValidation} from "@/components/diary/Diary";
import { BIconXCircleFill } from "bootstrap-vue";
import BloodPressureInput from "./form/BloodPressureInput";
import TemperatureInput from "./form/TemperatureInput";
import TableComponent from "../TableComponent";
import { FieldLike, TableRow } from "@/utils/vue-bootstrap-types";

interface Props {
  value: DiaryDataForm;
  onReportSend?: () => void;
  onAddMedicineNote?: () => void;
  onRemoveMedicineNote?: (index: number) => void;
  disabled?: boolean;
  time?: string;
  medicineNote?: string;
  onMedicineNoteInput?: (v: string) => void;
  onTimeInput?: (v: string) => void;
  v: ValidationInstance<DiaryDataValidation>;
  dealStatus?: string;
}
@Component({ name: 'DiaryForm', components: { BIconXCircleFill }})
export default class DiaryForm extends TsxComponent<Props> {
  @Prop(Object) public readonly value!: DiaryDataForm
  @Prop(Boolean) public readonly disabled!: boolean
  @Prop(String) public readonly time?: string
  @Prop(String) public readonly medicineNote?: string
  @Prop(Object) public readonly v!: ValidationInstance<DiaryDataValidation>
  @Prop(String) public readonly dealStatus?: string

  public get fields(): FieldLike[] {
    return [
      {
        key: 'medication',
        label: this.translation('shared.table-header-medication'),
        class: 'medication-name'
      },
      {
        key: 'amount',
        label: this.translation('shared.table-header-amount'),
        class: 'medication-amount'
      },
      {
        key: 'unit',
        label: this.translation('shared.table-header-unit'),
        class: 'medication-unit'
      }
    ]
  }

  public render(): VNode {
    const medV: ValidationInstanceIter<Medications> = castIter(this.v.diaryData.medications)

    return (
      <div>
        {!this.disabled &&
          <h3 class="mt-6">{this.translation('shared.title-new-note')}</h3>
        }
        <div class="pt-6 pt-md-10 px-md-4">
          {!this.disabled &&
            <b-row>
              <b-col cols="12" sm="6" lg="4">
                <DatePickerInput
                  label={this.translation('shared.fld-date')}
                  noFutureDate
                  value={this.value.collectedAt !== undefined ? moment(this.value.collectedAt).toDate() : undefined}
                  onInput={(v: Date) => (this.value.collectedAt = v !== undefined ? moment(v).format(serverDateTimeFormat) : undefined)}
                  disabled={this.disabled}
                  invalidMessage={this.disabled ? undefined : this.buildInvalidMessage(this.v.diaryData.collectedAt)}
                />
              </b-col>
              <b-col cols="12" sm="6" lg="4">
                <TextInput
                  label={this.translation('shared.fld-time')}
                  v-model={this.value.time}
                  timeMask
                  disabled={this.disabled}
                  invalidMessage={this.disabled ? undefined : this.buildInvalidMessage(this.v.diaryData.time)}
                />
              </b-col>
            </b-row>
          }
          <b-row>
            <b-col cols="12" sm="6" lg="4">
              <TemperatureInput
                disabled={this.disabled}
                invalidMessage={this.disabled ? undefined : this.buildInvalidMessage(this.v.diaryData.bodyTemperature)}
                v-model={this.value.bodyTemperature}
              />
            </b-col>
            <b-col cols="12" sm="6" lg="4">
              <TextInput
                label={this.translation('shared.fld-heart-rate')}
                v-model={this.value.heartRate}
                disabled={this.disabled}
                type={TextInputType.Number}
                invalidMessage={this.disabled ? undefined : this.buildInvalidMessage(this.v.diaryData.heartRate)}
                custom-class="border-right-0"
                append={(): VNode => {
                  return (
                    <div class="input-group-text pl-0">{this.translation('shared.fld-heart-rate-append')}</div>
                  )
                }}
              />
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <BloodPressureInput 
                v-model={this.value.bloodPressure}
                disabled={this.disabled}
                invalidMessage={this.buildInvalidMessage(this.v.diaryData.bloodPressure)}
              />
            </b-col>
            <b-col cols="12" sm="6" lg="4">
              <TextInput
                label={this.translation('shared.fld-blood-sugar-level')}
                v-model={this.value.bloodSugarLevel}
                disabled={this.disabled}
                invalidMessage={this.disabled ? undefined : this.buildInvalidMessage(this.v.diaryData.bloodSugarLevel)}
                onInput={(v: string) => (this.value.bloodSugarLevel = v.replace(',', '.'))}
                custom-class="border-right-0"
                append={(): VNode => {
                  return (
                    <div class="input-group-text pl-0">{this.translation('shared.fld-blood-sugar-level-append')}</div>
                  )
                }}
              />
            </b-col>
            <b-col cols="12" sm="6" lg="4">
              <TextInput
                label={this.translation('shared.fld-saturation')}
                v-model={this.value.saturation}
                disabled={this.disabled}
                type={TextInputType.Number}
                invalidMessage={this.disabled ? undefined : this.buildInvalidMessage(this.v.diaryData.saturation)}
                custom-class="border-right-0"
                append={(): VNode => {
                  return (
                    <div class="input-group-text pl-0">{this.translation('shared.fld-saturation-append')}</div>
                  )
                }}
              />
            </b-col>
            <b-col cols="12" sm="6" lg="4">
              <TextInput
                label={this.translation('shared.fld-urine-volume')}
                v-model={this.value.urineVolume}
                disabled={this.disabled}
                type={TextInputType.Number}
                invalidMessage={this.disabled ? undefined : this.buildInvalidMessage(this.v.diaryData.urineVolume)}
                custom-class="border-right-0"
                append={(): VNode => {
                  return (
                    <div class="input-group-text pl-0">{this.translation('shared.fld-urine-volume-append')}</div>
                  )
                }}
              />
            </b-col>
          </b-row>
          {!this.disabled &&
            <b-row>
              <b-col cols="12">
                <TextInput
                  label={this.translation('shared.fld-medications')}
                  maxLength={200}
                  value={this.medicineNote}
                  onInput={(v: string) => this.$emit('medicineNoteInput', v)}
                  disabled={this.disabled}
                  customClass={!this.disabled ? 'border-right-0' : ''}
                  append={() => (
                    <Button
                      variant="primary"
                      onClick={() => this.$emit('addMedicineNote')}
                      label={this.translation('shared.btn-add-medicine-note')}
                    />
                  )}
                  invalidMessage={this.buildInvalidMessage(this.v.diaryData.medications)}
                />
              </b-col>
            </b-row>
          }
          {this.value.medications !== undefined && this.value.medications.length > 0 && typeof this.value.medications[0] !== 'string' &&
          <b-row>
            <b-col cols="12">
              <div class="px-md-3 my-1 mt-md-n3 mb-md-4">
                <TableComponent
                  bordered={false}
                  borderless
                  hover={false}
                  fields={this.fields}
                  items={this.value.medications! as Medications[]}
                  tbody-tr-class="list-row"
                  small
                  striped={false}
                  tableClass="app-list-table"
                  wrapper-class="mb-0"
                  scopedSlots={{
                    "cell(medication)": (row: TableRow<Medications>) => {
                      return (
                        <div class="d-flex mb-1 mb-md-2">
                          {!this.disabled &&
                            <b-link
                              aria-label={this.translation('btn_delete')}
                              class="text-danger mr-2"
                              target={'_self'}
                              onClick={() => this.$emit('removeMedicineNote', row.index)}
                            >
                              <b-icon-x-circle-fill aria-hidden="true" class="d-block app-icon-lg m-0" />
                            </b-link>
                          }
                          <span class="font-weight-bold">{row.item.name}</span>
                        </div>
                      )
                    },
                    "cell(amount)": (row: TableRow<Medications>) => {
                      return (
                        <TextInput
                          class="mb-2"
                          v-model={row.item.amount}
                          placeholder={this.translation('shared.table-header-amount')}
                          invalidMessage={this.buildInvalidMessage(medV.$each[row.index].amount)}
                        />
                      )
                    },
                    "cell(unit)": (row: TableRow<Medications>) => {
                      return (
                        <b-button-group class="mb-2">
                          <b-button
                            class="text-nowrap px-4"
                            pressed={row.item.unit === Unit.ml}
                            variant={row.item.unit === Unit.ml ? 'primary' : 'outline-primary'}
                            onClick={() => {
                              row.item.unit = Unit.ml;
                            }}
                          >
                            {this.translation('shared.enum-unit-ml')}
                          </b-button>
                          <b-button
                            class="text-nowrap px-4"
                            pressed={row.item.unit === Unit.mg}
                            variant={row.item.unit === Unit.mg ? 'primary' : 'outline-primary'}
                            onClick={() => {
                              row.item.unit = Unit.mg;
                            }}
                          >
                            {this.translation('shared.enum-unit-mg')}
                          </b-button>
                        </b-button-group>
                      )
                    }
                  }}
                />
              </div>
            </b-col>
          </b-row>
          }
          {this.value.medications !== undefined && this.value.medications.length > 0 && typeof this.value.medications[0] === 'string' &&
            <b-row>
              <b-col cols="12">
                <ol>
                  {(this.value.medications as string[]).map(m => (
                    <li class="mb-2">
                      {m}
                    </li>
                  ))}
                </ol>
              </b-col>
            </b-row>
          }
          <b-row>
            <b-col cols="12">
              <TextAreaInput
                label={this.translation('shared.fld-notes')}
                v-model={this.value.notes}
                disabled={this.disabled}
                rows={6}
                invalidMessage={this.disabled ? undefined : this.buildInvalidMessage(this.v.diaryData.notes)}
                textCounter
                maxLength={2000}
              />
            </b-col>
          </b-row>
          {!this.disabled &&
            <b-row class="mt-5">
              <b-col class="text-right">
                <Button
                  variant="primary"
                  label={this.translation('shared.btn-save')}
                  onClick={() => this.$emit('reportSend')}
                  disabled={this.disabled}
                />
              </b-col>
            </b-row>
          }
        </div>
      </div>
    )
  }
}
