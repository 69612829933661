import {Component, Ref, Watch} from "vue-property-decorator"
import {VNode} from "vue"
import FormMixin from "@/mixins/FormMixin"

import Button from "@/components/form/Button"
import ProposalRequesterDetails from "@/_modules/proposal/components/ProposalRequesterDetails"
import ProposalProviderDetails from "@/_modules/proposal/components/ProposalProviderDetails"
import Messenger from "@/components/Messenger"
import {BIconCalendar4Week, BIconExclamationCircle, BIconExclamationTriangleFill, BIconListUl, BIconPlusCircle} from "bootstrap-vue"
import CheckBox from "@/components/form/CheckBox"
import TextInput from "@/components/form/TextInput"

import * as MainRoutes from "@/router/routes"
import {PaymentAgreement} from "@/router/routes"

import {proposalDemandStore} from "@/_modules/proposal/store/proposal-demand-store"
import {userStore} from "@/store/user-store"

import {buildProposal, onAcceptProposalCurrentVersionByProviderPayload, onAcceptProposalCurrentVersionByRequesterPayload, onProposalAcceptToDiscussion, onProposalCancelByProviderPayload, onProposalCancelByRequesterPayload, onProposalCreatePayload, onProposalSetRatingByProviderPayload, onProposalSetRatingByRequesterPayload, onProposalUpdateByProviderPayload, onProposalUpdateByRequesterPayload} from "@/_modules/proposal/data-builders"
import {addDealDataToTaskOccurrences, addOrUpdateEvent, convertTasksIntoServerFormat, convertTasksSeriesToInternalUse, removeEvent} from "@/_modules/shared-builders"
import {formatCountDown} from "@/utils/string-utils"

import {AcceptPropVersionPayload, NewProposal, ProposalDetails, ProposalNewMessagePayload, ProposalPriceAndTasks, ProposalTaskAsCompletePayload, ProposalUpdatePayload} from "@/_modules/proposal/types"
import {ActorRole, BusyTimePayload, DealStatus, DealType, GetRatingPayload, InitialTaskData, LockState, PaymentMethods, ProposalPaymentPayload, RateType, ServiceProviderQualificationPayload} from '@/types'
import {Message, RatedBy, TaskDefinition} from '@/_modules/request/types'

import moment from "moment"
import DataBoundaries, {isEmpty, nonNegativeCurrencyValidator} from "@/constants/DataBoundaries"

import {Validations} from "vuelidate-property-decorators"
import {required, requiredIf, sameAs} from "vuelidate/lib/validators";
import {buildValidationRules, ValidationObject, ValidationRuleSet} from "@/utils/vuelidate-extension"

import {BackendUrls, ErrorCodes} from "@/constants/APIconstants"
import {BasicErrorHandler} from "@/utils/errorHandler"
import * as ResponseError from "@/utils/errors"
import {RecursivePartial} from "@/utils/typescript-library-extensions"
import LoadingIndicator from "@/components/LoadingIndicator";
import {buildBusyTimeSchedule, checkIfWindowWasUnfocused} from '@/utils/array-utils'
import {scrollListner} from "@/utils/scroll-listner";
import {requestServiceStore} from "@/_modules/request/store/request-service-store";
import {authStore} from "@/store";
import {EventBus} from "@/main";
import {CustomEvents} from "@/constants/ComponentEvents";
import {calculatePriceWithoutPercentage} from "@/utils/formatters";
import {configStore} from "@/store/config";
import DealLayout from "@/components/layout/DealLayout";
import {BadgeEvent, badgesStore} from "@/store/badges-store";
import {ChatConnPayload, chatStore} from "@/_modules/message-center/store/chat-store";
import {webSocketHandler} from "@/ws/ws-handler";
import MultiLangTabs from '@/components/MultiLangTabs'
import DemandDetailsClientParameters from "@/_modules/demand/components/DemandDetailsClientParameters";
import ServiceDetailsClientParameters from "@/_modules/service/components/ServiceDetailsClientParameters";
import {serverDateFormat} from '@/constants/ApplicationConfiguration'
import _ from 'lodash'
import Diary from "@/components/diary/Diary";
import PageHeader from "@/components/layout/PageHeader"
import CompactScheduleCalendar, { TasksCalendarInterface } from "@/components/calendar/CompactScheduleCalendar"
import CollapseSection from "@/components/layout/CollapseSection"
import AppIconClose from "@/components/icons/AppIconClose"
import ContentSection from "@/components/layout/ContentSection"
import ScheduleList from "@/components/calendar/ScheduleList"
import InfoNotice from "@/components/layout/InfoNotice"
import ConfirmPopup from "@/components/layout/ConfirmPopup"
import Notice from "@/components/layout/Notice"

interface ComponentValidation {
  scheduleList: ValidationRuleSet;
  proposal: ValidationObject<ProposalDetails>;
  //serviceProviderOwnPrice: ValidationRuleSet;
  //TODO: restore donation. step 1
  //initialDonationAmount: ValidationRuleSet;
  termsAccepted: ValidationRuleSet;
  paymentTermsAccepted: ValidationRuleSet;
}

enum ActionType {
  Create = "Create",
  Update = "Update",
  Cancel = "Cancel",
  AcceptToDiscussion = "AcceptToDiscussion",
  AcceptCurrentVersion = "AcceptCurrentVersion",
  Pay = "Pay",
  Rate = "Rate"
}

export class ProposalErrorHandler extends BasicErrorHandler {

  protected async handleBadRequest(e: ResponseError.BadRequestError): Promise<void> {
    switch (e.code) {
      case ErrorCodes.ObjectParsingError:
        this.errorMessageKey = 'err_object_parsing_error'
        break
      case ErrorCodes.ProposalStatusIncorrect:
        this.errorMessageKey = 'err_proposal_status_incorrect'
        break
      default:
        await super.handleBadRequest(e)

    }
  }

  protected async handleBackendError(e: ResponseError.BackendError): Promise<void> {
    switch (e.code) {
      case ErrorCodes.ServiceTermsAreNotAccepted:
        this.errorMessageKey = 'err_service_terms_are_not_accepted'
        break
      case ErrorCodes.ProposalRecordNotFound:
        this.errorMessageKey = 'err_proposal_record_not_found'
        break
      case ErrorCodes.ProposalStatusIncorrect:
        this.errorMessageKey = 'err_proposal_status_incorrect'
        break
      case ErrorCodes.ProposalVersionIncorrect:
        this.errorMessageKey = 'err_proposal_version_incorrect'
        break
      case ErrorCodes.ProposalIsNotLocked:
        this.errorMessageKey = 'err_proposal_is_not_locked'
        break
      case ErrorCodes.MKTransactionAlreadyInProgress:
        this.errorMessageKey = "err_mk_transaction_already_exist"
        break
      case ErrorCodes.DemandNotFound:
        this.errorMessageKey = "err_demand_not_found"
        break
      case ErrorCodes.CannotCreateDealWithYourself:
        this.errorMessageKey = 'err_cannot_create_proposal_for_self'
        break
      case ErrorCodes.TaskRecurrenceRuleIncorrect:
        this.errorMessageKey = 'err_task_recurrence_rule_incorrect'
        break
      case ErrorCodes.ProposalIsLocked:
        this.errorMessageKey = undefined
        break
      default:
        await super.handleBackendError(e)
    }
  }
}
const proposalErrorHandler = new ProposalErrorHandler()

@Component({
  name: 'Proposal',
  components: {
    BIconCalendar4Week,
    BIconExclamationCircle,
    BIconExclamationTriangleFill,
    BIconListUl,
    BIconPlusCircle
  }
})
export default class extends FormMixin{

  @Ref('tasksCalendar') public readonly tasksCalendar!: TasksCalendarInterface

  private paymentMethods: PaymentMethods = {
    bankLinks: []
  }

  private dismissCountDown = 0
  private windowFocusHistory: boolean[] = []

  private actor: ActorRole | string = ''

  private redirectAfterConfirm: boolean = false

  private proposal: ProposalDetails = buildProposal()
  private proposalLoaded: boolean = false

  private messages: Message[] = []

  private scheduleList: TaskDefinition[] = []
  private selectedTask: TaskDefinition | {} = {}

  private busyScheduleList: TaskDefinition[] = []
  private selectedCalendarWeek = moment().startOf('isoWeek')

  private showRejectAttentionPopup: boolean = false
  private termsAccepted: boolean = false
  private paymentTermsAccepted: boolean = false
  private showCalendarComponent: boolean = true;

  @Validations()
  protected validations(): RecursivePartial<ComponentValidation> {
    return {
      paymentTermsAccepted: (this.proposal.status === DealStatus.DealConcluded || this.proposal.status === DealStatus.PaymentFailed || this.proposal.status === DealStatus.PaymentStarted) ? {sameAs: sameAs(() => true)} : {},
      termsAccepted: this.proposal.status === DealStatus.UnderDiscussion && this.proposal.lockState.status === LockState.NotLocked ? {sameAs: sameAs(() => true)} : {},
      scheduleList: (this.isProvider && (this.proposalDraft || this.proposal.status === DealStatus.New)) ? {} : {required},
      proposal: {
        service: {
          languages: {required},
          location: {
            address: {
              address: {requiredIf: requiredIf(() => {return !this.proposal.demand.requestedAtClientPlace})},
              zipCode: {requiredIf: requiredIf(() => {return !this.proposal.demand.requestedAtClientPlace})},
              cityOrCounty: {requiredIf: requiredIf(() => {return !this.proposal.demand.requestedAtClientPlace})}
            }
          }
        },
        serviceRate: {
          price: {
            amount: {
              requiredIf: requiredIf(() => {return this.proposalDraft}),
              nonNegativeCurrency: nonNegativeCurrencyValidator,
              ...buildValidationRules(DataBoundaries.currency),
              ...buildValidationRules(DataBoundaries.currencyNonNegative)
            }
          }
        },
        price: {
          servicePrice: {
            amount: {
              requiredIf: requiredIf(() => {return (this.actor === ActorRole.Provider && (this.proposal.status === DealStatus.New || this.proposal.status === DealStatus.UnderDiscussion))}),
              nonNegativeCurrency: nonNegativeCurrencyValidator,
              ...buildValidationRules(DataBoundaries.currency),
              ...buildValidationRules(DataBoundaries.currencyNonNegative)
            }
          }
        }
      }
    }
  }

  public eventsWatcherLoopId: ReturnType<typeof setTimeout> | undefined = undefined

  public async mounted(): Promise<void> {
    await this.fetchProposal()
    this.showCalendarComponent = !this.shouldShowCalendarDetails;

    if (this.proposal.status === DealStatus.Completed) {
      this.redirectAfterConfirm = true
    }
  }

  public beforeDestroy(): void {
    clearTimeout(this.eventsWatcherLoopId!)
  }

  @Watch('proposal.status')
  public async startPollMessages(): Promise<void> {
    const payload: ChatConnPayload = {
      dealId: this.proposal.id!,
      dealType: DealType.Proposal,
      userId: authStore.authInfo!.userId!
    }
    await webSocketHandler.wsStreamSingleChatUp(payload)
    setTimeout(() => {
      const btn = document.getElementById('btn-rate')
      if (btn !== null) {
        btn.scrollIntoView({behavior: "auto", block: "center"})
      }
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, 1000)
  }

  @Watch('dismissCountDown')
  public async syncTime(): Promise<void> {
    this.windowFocusHistory.push(document.hasFocus())
    this.windowFocusHistory.reverse()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const doc = document as any
    const result = checkIfWindowWasUnfocused(this.windowFocusHistory)
    if (result !== undefined) {
      const resp = await this.withRequest(proposalDemandStore.getProposalById(this.proposal.id!), proposalErrorHandler, true)
      this.dismissCountDown = resp.lockState.timeLeftInSeconds
      this.windowFocusHistory = []
    }

    if (this.dismissCountDown <= 0) {
      doc.body.classList.remove('has-count-down')
    }
  }

  public get allowFetchPriceAndTasks(): boolean {
    return this.proposalDraft || this.proposal.status === DealStatus.New || this.proposal.status === DealStatus.UnderDiscussion
  }

  private async fetchPriceAndTasks(): Promise<void> {
    if (this.allowFetchPriceAndTasks) {
      const tasks: InitialTaskData[] = convertTasksIntoServerFormat(this.scheduleList)

      const proposalId = this.$route.params.proposalId
      if (proposalId) {
        if (this.isProvider) {
          const tasksAndPrice: ProposalPriceAndTasks = {
            serviceRate: this.proposal.service.rate,
            proposalId: proposalId,
            servicePrice: this.proposal.price.servicePrice,
            calculatePrice: this.proposal.calculatePrice,
            tasks: tasks
          }
          await this.withRequest(proposalDemandStore.updatePriceWithProposalIdByProvider(tasksAndPrice), proposalErrorHandler, true)
            .then((r) => {
              this.proposal.price = {
                servicePrice: r.servicePrice,
                tax: r.tax,
                total: r.total,
                paymentFee: r.platformFee,
                platformFee: r.platformFee
              }
              this.proposal.calculatePrice = r.calculatePrice
              this.scheduleList = convertTasksSeriesToInternalUse(r.tasks)
              this.scheduleList = addDealDataToTaskOccurrences(this.scheduleList, this.proposal)
              this.setCalendarWeek()
            })
            .catch(() => {
              this.scheduleList = this.scheduleList.filter(task => {
                return task.occurrences.find(o => o.bgColor !== '#a1b56c')
              })
            })
        } else {
          const tasksAndPrice: ProposalPriceAndTasks = {
            serviceRate: this.proposal.service.rate,
            proposalId: proposalId,
            tasks: tasks
          }
          await this.withRequest(proposalDemandStore.updatePriceWithProposalIdByRequester(tasksAndPrice), proposalErrorHandler, true)
            .then((r) => {
              this.proposal.price = {
                servicePrice: r.servicePrice,
                tax: r.tax,
                total: r.total,
                paymentFee: r.platformFee,
                platformFee: r.platformFee
              }
              this.scheduleList = convertTasksSeriesToInternalUse(r.tasks)
              this.scheduleList = addDealDataToTaskOccurrences(this.scheduleList, this.proposal)
              this.setCalendarWeek()
            })
            .catch(() => {
              this.scheduleList = this.scheduleList.filter(task => {
                return task.occurrences.find(o => o.bgColor !== '#a1b56c')
              })
            })
        }
      } else {
        const proposalTotalPrice: ProposalPriceAndTasks = {
          serviceRate: this.proposal.serviceRate,
          servicePrice: this.proposal.price.servicePrice,
          calculatePrice: this.proposal.calculatePrice,
          tasks: tasks
        }
        const resp = await this.withRequest(proposalDemandStore.updatePriceWithOutProposalId(proposalTotalPrice), proposalErrorHandler, true)
        this.proposal.calculatePrice = resp.calculatePrice
        this.proposal.price = resp
        this.scheduleList = convertTasksSeriesToInternalUse(resp.tasks)
        this.scheduleList = addDealDataToTaskOccurrences(this.scheduleList, this.proposal)
        this.setCalendarWeek()
      }
      if (this.redirectAfterConfirm) {
        const notConfirmedTasks = this.scheduleList.filter(it => {
          return it.occurrences.find(t => t.state?.confirmed === false)
        })
        if (notConfirmedTasks.length === 0) {
          await this.onSubmit(ActionType.Rate)
        }
      }
    }
  }

  protected get isProvider(): boolean {
    const userId = authStore.authInfo?.userId
    return (this.proposalDraft || this.proposal.service.provider.userId === userId)
  }

  protected get showLockBtn(): boolean {
    return (
      (this.proposal.lockState.status === LockState.NotLocked
      && (this.proposal.status === DealStatus.UnderDiscussion
      || this.proposal.status === DealStatus.New))
    )
  }

  protected get proposalDraft(): boolean {
    return isEmpty(this.proposal.status)
  }

  protected get allowChanges(): boolean {
    return (
      ((this.proposal.lockState.status === LockState.LockedByYou
      || this.proposal.lockState.status === LockState.AlreadyLockedByYou)
      && (this.proposal.status === DealStatus.New
      || this.proposal.status === DealStatus.UnderDiscussion))
      || this.proposalDraft
    )
  }

  protected get showMessenger(): boolean {
    return (!this.proposalDraft && this.proposal.status !== DealStatus.New)
  }

  protected get proposalIsLocked(): boolean {
    return (this.proposal.lockState.status === LockState.LockedByYou
      || this.proposal.lockState.status === LockState.AlreadyLockedByYou
      || this.proposal.lockState.status === LockState.AlreadyLockedByOther)
  }

  protected get dealFinished(): boolean {
    return (this.proposal.status === DealStatus.PaidToProvider || this.proposal.status === DealStatus.Confirmed || this.proposal.status === DealStatus.Canceled || this.proposal.status === DealStatus.Declined)
  }

  private async fetchProposal(silentUpdate?: boolean): Promise<void> {

    setTimeout(() => {
      const proposalId = this.$route.params.proposalId
      const badges: BadgeEvent[] = badgesStore.getBadgeEvents
      const result = badges.find(it => it.docId === proposalId)
      if (result !== undefined && proposalId !== undefined) {
        this.withRequest(badgesStore.deleteBadgeCall(result.id), undefined, true).then(() => {
          badgesStore.removeInternalBadgeEvent(result)
        })
      }
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, 5000)
    const demandId = this.$route.params.demandId
    if (demandId !== undefined) {
      const resp = await this.withRequest(proposalDemandStore.getDemandDetailsById(demandId), proposalErrorHandler)
      const settings = await this.withRequest(proposalDemandStore.getSettings(), proposalErrorHandler)

      const serviceProviderQualificationPayload: ServiceProviderQualificationPayload = {
        userId: settings.profile.id!,
        category: resp.demand.category
      }

      this.proposal.service.location = settings.profile.location
      this.proposal.demand.location = resp.demand.location

      this.proposal.title = resp.demand.title!
      this.proposal.requester.sex = resp.requester.sex
      this.proposal.demand.clientWeight = resp.demand.clientWeight !== undefined ? resp.demand.clientWeight : undefined
      this.proposal.demand.rate = resp.demand.budget !== undefined ? resp.demand.budget : undefined
      this.proposal.demand.languages = resp.demand.languages
      //this.proposal.demand.category = resp.demand.category
      this.proposal.demand.requestedAtClientPlace = resp.demand.requestedAtClientPlace
      this.proposal.demand.location = resp.demand.location
      this.proposal.requester.rating = resp.requester.rating

      this.proposal.demand.demandDescription_ru = resp.demand.demandDescription_ru
      this.proposal.demand.demandDescription_et = resp.demand.demandDescription_et
      this.proposal.demand.demandDescription_en = resp.demand.demandDescription_en

      this.proposal.demand.clientSex = resp.demand.clientSex
      this.proposal.demand.clientAge = resp.demand.clientAge
      this.proposal.demand.clientPhysicalActivity = resp.demand.clientPhysicalActivity
      this.proposal.demand.clientMentalAbility = resp.demand.clientMentalAbility
      this.proposal.demand.clientWeight = resp.demand.clientWeight
      this.proposal.demand.category = resp.demand.category
      this.proposal.demand.clientDisease = resp.demand.clientDisease

      this.proposal.demand.clientRequirementsWithParent = resp.clientRequirementsWithParent

      this.proposal.requester.name = resp.requester.name

      // eslint-disable-next-line @typescript-eslint/no-magic-numbers,no-mixed-operators
      if (resp.demand.budget) {
        this.proposal.serviceRate.price.amount = Number(calculatePriceWithoutPercentage(resp.demand.budget.price, configStore.serverConfig)) > 0 ?
          calculatePriceWithoutPercentage(resp.demand.budget!.price, configStore.serverConfig) : '0'
      } else {
        this.proposal.serviceRate.price.amount = '0'
      }

      this.proposal.serviceRate.type = resp.demand.budget?.type !== undefined ? resp.demand.budget!.type : RateType.FIXED_PRICE

      this.proposal.service.provider.userId = settings.profile.id!
      this.proposal.service.provider.sex = settings.profile.sex
      this.proposal.service.provider.verified = settings.profile.verified!
      this.proposal.service.provider.name = settings.profile.name
      this.proposal.service.provider.qualification = await this.withRequest(userStore.getServiceProviderQualification(serviceProviderQualificationPayload))

      const serviceId = this.$route.query.serviceId
      if (serviceId !== undefined) {
        const service = await this.withRequest(requestServiceStore.getServiceDetailsById(serviceId as string))
        this.proposal.serviceRate = service.service.rate
        this.proposal.service.location = service.service.location
        this.proposal.service.languages = service.service.languages
        this.proposal.service.description_ru = service.service.description_ru
        this.proposal.service.description_et = service.service.description_et
        this.proposal.service.description_en = service.service.description_en

        this.proposal.service.scheduleInfo_ru = service.service.scheduleInfo_ru
        this.proposal.service.scheduleInfo_et = service.service.scheduleInfo_et
        this.proposal.service.scheduleInfo_en = service.service.scheduleInfo_en

        this.proposal.service.clientWeight = service.service.clientWeight
        this.proposal.service.clientAge = service.service.clientAge
        this.proposal.service.clientSex = service.service.clientSex
        this.proposal.service.clientPhysicalActivity = service.service.clientPhysicalActivity
        this.proposal.service.clientMentalAbility = service.service.clientMentalAbility
        this.proposal.service.clientDisease = service.service.clientDisease

        this.proposal.service.competencesWithParent = service.competencesWithParent
      }

      const providerRatingPayload: GetRatingPayload = {
        userId: settings.profile.id!,
        role: ActorRole.Provider,
        category: this.proposal.demand.category
      }
      this.proposal.service.provider.rating = await this.withRequest(userStore.getUserRating(providerRatingPayload))
      this.proposalLoaded = true
    }

    const proposalId = this.$route.params.proposalId
    if (proposalId !== undefined) {
      const proposal: ProposalDetails = await this.withRequest(proposalDemandStore.getProposalById(proposalId), proposalErrorHandler, silentUpdate)
      this.dismissCountDown = proposal.lockState.timeLeftInSeconds
      this.proposal = proposal
      this.proposalLoaded = true

      this.scheduleList = convertTasksSeriesToInternalUse(proposal.tasks)
      this.scheduleList = addDealDataToTaskOccurrences(this.scheduleList, this.proposal)
      this.setCalendarWeek()

      if (this.showMessenger) {
        this.messages = chatStore.getChatMessages
      }
    }

    if (!isEmpty(this.proposal.service.provider.userId)) {
      const payload: BusyTimePayload = {
        providerId: this.proposal.service.provider.userId!,
        startDate: this.selectedCalendarWeek.format(serverDateFormat)
      }
      const resp = await this.withRequest(userStore.getProviderBusyTime(payload), proposalErrorHandler, true)
      this.busyScheduleList = buildBusyTimeSchedule(resp, this.scheduleList)
    }

    if (this.isProvider) {
      this.actor = ActorRole.Provider
    } else {
      this.actor = ActorRole.Requester
    }
  }

  private onAddCalendarTask(): void {
    let newTaskDate = moment();

    if (this.selectedCalendarWeek.isAfter(moment(), 'day')) {
      newTaskDate = this.selectedCalendarWeek;
    }

    this.tasksCalendar.addTask(newTaskDate);
  }

  private setCalendarWeek(): void {
    if (this.dealFinished && this.scheduleList.length !== 0) {
      let lastOccurrenceDate: null | moment.Moment = null;

      this.scheduleList.map(task => {
        const taskLastOccurrence = task.occurrences[task.occurrences.length - 1];
        if (lastOccurrenceDate === null || moment(taskLastOccurrence.end).isAfter(lastOccurrenceDate)) {
          lastOccurrenceDate = moment(taskLastOccurrence.end)
        }
      })

      if (lastOccurrenceDate !== null) {
        this.tasksCalendar.setSelectedWeek(lastOccurrenceDate);
      }
    }
  }

  private async onSubmit(action: ActionType, silentUpdate?: boolean): Promise<void> {
    const serviceId = this.$route.query.serviceId as string
    if (this.checkValidation(this.$v)) {
      const scheduleList: InitialTaskData[] = convertTasksIntoServerFormat(this.scheduleList)

      if (action === ActionType.Create) {
        const proposal: NewProposal = {
          demandId: this.$route.params.demandId,
          serviceRate: this.proposal.serviceRate,
          serviceLocation: this.proposal.demand.requestedAtClientPlace ? undefined : this.proposal.service.location,
          serviceLanguages: this.proposal.service.languages,
          tasks: scheduleList,
          calculatePrice: this.proposal.calculatePrice,
          price: this.proposal.price.servicePrice,
          serviceId: serviceId !== undefined ? serviceId : undefined
        }
        const proposalId = await this.withRequest(proposalDemandStore.postNewProposal(proposal), proposalErrorHandler)
        await this.$router.push({
          name: MainRoutes.DealAnnouncer.name,
          params: onProposalCreatePayload(this.proposal, proposalId)
        })
      }

      if (action === ActionType.Update) {
        if (this.isProvider) {
          const payload: ProposalUpdatePayload = {
            proposalId: this.$route.params.proposalId,
            tasks: scheduleList,
            calculatePrice: this.proposal.calculatePrice,
            price: this.proposal.price.servicePrice
          }
          if (silentUpdate) {
            await this.withRequest(proposalDemandStore.updateProposalByProvider(payload), proposalErrorHandler, silentUpdate)
            await this.fetchProposal(silentUpdate)
          } else {
            await this.withRequest(proposalDemandStore.updateProposalByProvider(payload), proposalErrorHandler)
            await this.$router.push({
              name: MainRoutes.DealAnnouncer.name,
              params: onProposalUpdateByProviderPayload(this.proposal)
            })
          }
        } else {
          const payload: ProposalUpdatePayload = {
            proposalId: this.$route.params.proposalId,
            //TODO: restore donation. step 7
            //donate: this.proposal.price.donate,
            tasks: scheduleList
          }
          if (silentUpdate) {
            await this.withRequest(proposalDemandStore.updateProposalByRequester(payload), proposalErrorHandler, silentUpdate)
            await this.fetchProposal(true)
          } else {
            await this.withRequest(proposalDemandStore.updateProposalByRequester(payload), proposalErrorHandler)
            await this.$router.push({
              name: MainRoutes.DealAnnouncer.name,
              params: onProposalUpdateByRequesterPayload(this.proposal)
            })
          }
        }
      }

      if (action === ActionType.AcceptCurrentVersion) {
        const payload: AcceptPropVersionPayload = {
          proposalId: this.$route.params.proposalId,
          propVersion: this.proposal.version!,
          termsAccepted: this.termsAccepted
        }
        await this.withRequest(proposalDemandStore.acceptCurrentVersion(payload), proposalErrorHandler)
          .then(resp => {
            if (this.isProvider) {
              this.$router.push({
                name: MainRoutes.DealAnnouncer.name,
                params: onAcceptProposalCurrentVersionByProviderPayload(this.proposal, resp.data.dealConcluded)
              })
            } else {
              this.$router.push({
                name: MainRoutes.DealAnnouncer.name,
                params: onAcceptProposalCurrentVersionByRequesterPayload(this.proposal, resp.data.dealConcluded)
              })
            }
          })
          .catch(err => {
            if (err.code === ErrorCodes.ProposalIsLocked) {
              this.fetchProposal(true)
            }
          })
      }

      if (action === ActionType.Pay) {
        const payload: ProposalPaymentPayload = {
          proposalId: this.$route.params.proposalId,
          paymentTermsAccepted: this.paymentTermsAccepted
        }
        this.paymentMethods = await this.withRequest(proposalDemandStore.payForServiceInProposal(payload), proposalErrorHandler, true)
        if (this.paymentMethods.bankLinks.length === 0 && this.proposal.price.total?.amount === '0.00') {
          await this.$router.push({
            path: `${MainRoutes.PaymentSuccess.path}/${this.proposal.id}`,
            params: {
              dealId: this.proposal.id!
            },
            query: {
              type: DealType.Proposal
            }
          })
        }
      }
    }

    if (action === ActionType.AcceptToDiscussion) {
      const proposalId = this.$route.params.proposalId
      await this.withRequest(proposalDemandStore.acceptProposalToDiscussion(proposalId), proposalErrorHandler)
      await this.$router.push({
        name: MainRoutes.DealAnnouncer.name,
        params: onProposalAcceptToDiscussion(this.proposal)
      })
      //this.fetchProposal(true)
    }

    if (action === ActionType.Cancel) {
      await this.withRequest(proposalDemandStore.cancelProposal(this.$route.params.proposalId), proposalErrorHandler)
      if (this.isProvider) {
        await this.$router.push({
          name: MainRoutes.DealAnnouncer.name,
          params: onProposalCancelByProviderPayload(this.proposal)
        })
      } else {
        await this.$router.push({
          name: MainRoutes.DealAnnouncer.name,
          params: onProposalCancelByRequesterPayload(this.proposal)
        })
      }
    }

    if (action === ActionType.Rate) {
      const proposalId = this.$route.params.proposalId
      if (this.isProvider) {
        await this.$router.push({
          name: MainRoutes.DealAnnouncer.name,
          params: onProposalSetRatingByProviderPayload(this.proposal, proposalId)
        })
      } else {
        await this.$router.push({
          name: MainRoutes.DealAnnouncer.name,
          params: onProposalSetRatingByRequesterPayload(this.proposal, proposalId)
        })
      }
    }
  }

  private get onLockStateCountDown(): number {
    if (this.dismissCountDown <= 0) {
      //TODO: restore donation. step 8
      //this.initialDonationAmount = ''
      this.fetchProposal()
    }
    return this.dismissCountDown
  }

  private handleCountdownPosition(): void {
    this.$nextTick(() => {
      window.addEventListener('scroll', scrollListner)
      scrollListner()
    })
  }

  public buildCountDown(): VNode {
    const countDownTime = formatCountDown(this.dismissCountDown!)

    return (
      <div class="countdown-relative-replacer mt-4">
        <div class="count-down-popup text-center">
          <b-alert
            show={this.onLockStateCountDown}
            variant="warning"
            onDismiss-count-down={(v: number) => {this.dismissCountDown = v}}
          >
            <span>
              {this.translation(`msg_proposal_${this.proposal.lockState.status}`)}
              <span class={countDownTime!.toString().startsWith('0:') ? 'text-danger' : ''}>
                {countDownTime}
              </span>
            </span>
          </b-alert>
        </div>
        {this.handleCountdownPosition()}
      </div>
    )
  }

  public buildAttention(): VNode {
    return (
      <b-row>
        <b-col cols="12" class="text-center">
          {this.proposal.status === DealStatus.DealConcluded &&
            <b-alert class="mt-4" variant="success" show={true}>
              {this.translation(`lbl_status_${this.proposal.status}`)}
            </b-alert>
          }

          {(this.proposal.status === DealStatus.Canceled || this.proposal.status === DealStatus.Declined) &&
            <b-alert class="mt-4" variant="danger" show={true}>
              {this.translation(`lbl_status_${this.proposal.status}`)}
            </b-alert>
          }

          {(this.proposal.changesToAccept !== undefined && this.proposal.changesToAccept.length > 0) && !(this.proposal.status === DealStatus.Canceled || this.proposal.status === DealStatus.Declined) &&
            <b-alert class="mt-4" variant="warning" show={true}>
              {this.actor === ActorRole.Requester ?
                this.translation('msg_proposal_changes_by_provider')
                : this.translation('msg_proposal_changes_by_requester')
              }
              {this.proposal.changesToAccept?.map((it, i) => {
                return <b>{this.translation(`enum_proposal_change_${it}`) + `${i < this.proposal.changesToAccept!.length-1 ? ', ' : '.'}`}</b>
              })}
            </b-alert>
          }

          {(this.proposal.acceptedLatestVersion && this.proposal.lockState.status === LockState.NotLocked && this.proposal.status === DealStatus.UnderDiscussion) &&
            <b-alert class="mt-4" variant="warning" show={true}>
              {this.isProvider ? this.translation('msg_awaiting_decision_by_provider') : this.translation('msg_awaiting_decision_by_requester')}
            </b-alert>
          }
        </b-col>
      </b-row>
    )
  }

  public buildDataSection(): VNode {
    const demandDescription = {
      demandDescription_ru: this.proposal?.demand.demandDescription_ru,
      demandDescription_et: this.proposal?.demand.demandDescription_et,
      demandDescription_en: this.proposal?.demand.demandDescription_en
    }
    const scheduleInfo = {
      scheduleInfo_ru: this.proposal?.service?.scheduleInfo_ru,
      scheduleInfo_et: this.proposal?.service?.scheduleInfo_et,
      scheduleInfo_en: this.proposal?.service.scheduleInfo_en
    }
    const description = {
      description_ru: this.proposal?.service?.description_ru,
      description_et: this.proposal?.service?.description_et,
      description_en: this.proposal?.service?.description_en
    }
    return (
      <div>
        {this.buildAttention()}
        <b-row>
          <PageHeader
            headerClass="my-10 mt-lg-4"
            leadClass="text-sm"
            title={this.proposal.title}
            wideHeader={true}
          >
            {!this.proposalDraft &&
              <span class="text-nowrap mr-4">{this.translation('lbl_service_created')}: {moment(this.proposal.createdAt).locale(this.$i18n.locale).format('L')}</span>
            }
            {!isEmpty(this.proposal.agreementNumber) &&
              <span class="text-nowrap">
                {this.translation('lbl_proposal_agreement_number')}
                <b-link class="text-primary" href={`${BackendUrls.urlApi}${BackendUrls.file}/${encodeURIComponent(this.proposal.agreementFileId)}`}>{this.proposal.agreementNumber}</b-link>
              </span>
            }
          </PageHeader>
        </b-row>
        <CollapseSection
          class="mb-6"
          title={this.translation('title_transaction_parties')}
          visible={this.proposalLoaded && (this.proposalDraft || this.proposal.status === DealStatus.New)}
        >
          {this.actor === ActorRole.Provider &&
            <Notice
              noticeClass="mt-4 mt-md-8 mb-2"
              dismissible
              noticeKey="proposalTransactionParties"
              variant="warning"
            >
              <p>{this.translation('msg_proposal_transaction_parties')}</p>
            </Notice>
          }
          <b-row>
            <b-col cols="12" md="6" order={this.actor === ActorRole.Requester ? '2' : '1'} class="mt-8 mb-n2">
              <h3 class="h6 mb-0">{this.translation(this.actor === ActorRole.Requester ? 'title_is_requester' : 'title_requester')}</h3>
              <ProposalRequesterDetails
                proposal={this.proposal}
                allowChanges={this.allowChanges}
                demandRequesterRating={this.proposal.requester.rating}
              />
              {this.actor === ActorRole.Provider && ((!isEmpty(this.proposal?.demand.demandDescription_ru) || !isEmpty(this.proposal?.demand.demandDescription_et) || !isEmpty(this.proposal?.demand.demandDescription_en))) &&
              <div>
                <b-button 
                  v-b-modal={'problem-description-modal'}
                  variant="outline-primary"
                  class="mt-2 mb-4"
                >
                  {this.translation('title_description_of_problem')}
                </b-button>
                <b-modal
                  ok-title={this.translation('btn_close')}
                  ok-only
                  centered
                  id="problem-description-modal"
                  scrollable
                  size="xl"
                  title={this.translation('title_description_of_problem')}
                  title-tag="h2"
                >
                  <template slot="modal-header-close">
                    <AppIconClose class="text-primary" />
                  </template>
                  <template slot="default">
                    <MultiLangTabs
                      value={{
                        demandDescription_ru: demandDescription.demandDescription_ru,
                        demandDescription_et: demandDescription.demandDescription_et,
                        demandDescription_en: demandDescription.demandDescription_en
                      }}
                    />
                    <DemandDetailsClientParameters
                      accordionTitleClass="h3"
                      value={this.proposal.demand}
                      optionsWithCategoryName={this.proposal.demand.clientRequirementsWithParent}
                    />
                  </template>
                </b-modal>
              </div>
              }
            </b-col>
            <b-col cols="12" md="6" order={this.actor === ActorRole.Requester ? '1' : '2'} class="mt-8 mb-n2">
              <h3 class="h6 mb-0">{this.translation(this.actor === ActorRole.Provider ? 'title_is_service_provider' : 'title_service_provider')}</h3>
              <ProposalProviderDetails
                proposal={this.proposal}
                v={this.$v.proposal}
                allowChanges={this.allowChanges}
                demandProviderRating={this.proposal.service.provider.rating}
                onPriceChanged={() => {
                  if (this.checkValidation(this.$v.proposal!.serviceRate!.price.amount)) { //TODO: ??
                    this.fetchPriceAndTasks()
                  }
                }}
              />
              {this.actor === ActorRole.Requester && ((!isEmpty(this.proposal.service.description_ru) || !isEmpty(this.proposal.service.description_et) || !isEmpty(this.proposal.service.description_en))) &&
              <div>
                <b-button 
                  v-b-modal={'assistant-skills-modal'}
                  variant="outline-primary"
                  class="mt-2 mb-4"
                >
                  {this.translation('lbl_service_provider_skill')}
                </b-button>
                <b-modal
                  ok-title={this.translation('btn_close')}
                  ok-only
                  centered
                  id="assistant-skills-modal"
                  scrollable
                  size="xl"
                  title={this.translation('title_service_provider_skill_modal', [`${this.proposal.service.provider.name.first} ${this.proposal.service.provider.name.last.charAt(0)}.`])}
                  title-tag="h2"
                >
                  <template slot="modal-header-close">
                    <AppIconClose class="text-primary" />
                  </template>
                  <template slot="default">
                    <h3 class="fieldset-title">{this.translation('title_schedule_info')}</h3>
                    <MultiLangTabs
                      value={{
                        scheduleInfo_ru: scheduleInfo.scheduleInfo_ru,
                        scheduleInfo_et: scheduleInfo.scheduleInfo_et,
                        scheduleInfo_en: scheduleInfo.scheduleInfo_en
                      }}
                    />
                    <h3 class="fieldset-title">{this.translation('title_service_description')}</h3>
                    <MultiLangTabs
                      value={{
                        description_ru: description.description_ru,
                        description_et: description.description_et,
                        description_en: description.description_en
                      }}
                    />
                    <ServiceDetailsClientParameters
                      accordionTitleClass="h3"
                      value={this.proposal.service}
                      optionsWithCategoryName={this.proposal.service.competencesWithParent}
                    />
                  </template>
                </b-modal>
              </div>
              }
            </b-col>
          </b-row>
        </CollapseSection>
      </div>
    )
  }

  public scrollToTasks(): void {
    const scheduleList = document.getElementById('proposal-tasks')
    if (scheduleList !== null) {
      scheduleList.scrollIntoView({behavior: "auto", block: "center"})
    }
  }

  private async onSetTaskComplete(taskId: string): Promise<void> {
    const taskAsCompletePayload: ProposalTaskAsCompletePayload = {
      proposalId: this.$route.params.proposalId,
      taskId: taskId
    }
    await this.withRequest(proposalDemandStore.setTaskComplete(taskAsCompletePayload), proposalErrorHandler, true)
    await this.fetchProposal(true)
    const isAllTasksCompleted = this.proposal.status === DealStatus.Completed
    this.messagesPayload.successMessageKey = isAllTasksCompleted ? 'msg_all_tasks_completed' : 'msg_task_completed'
    EventBus.$emit(CustomEvents.FlashMsg, this.messagesPayload)
    EventBus.$on(CustomEvents.DismissMsg, () => (this.messagesPayload.successMessageKey = null))
    if (isAllTasksCompleted) {
      this.scrollToTasks()
    }
  }

  private async onSetTaskCompleteConfirm(taskId: string): Promise<void> {
    const taskAsCompleteConfirmPayload: ProposalTaskAsCompletePayload = {
      proposalId: this.$route.params.proposalId,
      taskId: taskId
    }
    await this.withRequest(proposalDemandStore.setTaskCompleteConfirm(taskAsCompleteConfirmPayload), proposalErrorHandler, true)
    await this.fetchProposal(true)
    const someNotConfirmed = this.scheduleList.some(st => st.occurrences?.some(o => o.state?.completed && !o.state?.confirmed))
    const isAllTasksConfirmed = this.scheduleList.every(st => st.occurrences.every(o => o.state?.confirmed))
    this.messagesPayload.successMessageKey = isAllTasksConfirmed ? 'msg_all_tasks_confirmed' : 'msg_task_confirmed'
    EventBus.$emit(CustomEvents.FlashMsg, this.messagesPayload)
    EventBus.$on(CustomEvents.DismissMsg, () => (this.messagesPayload.successMessageKey = null))
    if (!someNotConfirmed) {
      this.scrollToTasks()
    }
  }

  private async updateBusyTime(date: Date): Promise<void> {
    this.selectedCalendarWeek = moment(date)
    if (!isEmpty(this.proposal.service.provider.userId)) {
      const payload: BusyTimePayload = {
        providerId: this.proposal.service.provider.userId!,
        startDate: moment(date).format(serverDateFormat)
      }
      const resp = await this.withRequest(userStore.getProviderBusyTime(payload), proposalErrorHandler, true)
      this.busyScheduleList = buildBusyTimeSchedule(resp, this.scheduleList)
    }
  }

  private get allTasks(): TaskDefinition[] {
    return this.scheduleList.concat(this.busyScheduleList)
  }

  protected get shouldShowCalendarDetails(): boolean {
    return (this.proposal.status === DealStatus.Paid && this.isProvider)
      ||
      (this.proposal.status === DealStatus.Completed && !this.isProvider)
      ||
      (this.proposal.status === DealStatus.Paid && this.scheduleList.some(st => st.occurrences.some(o => (o.state?.completed && !o.state.confirmed))) && !this.isProvider)
  }

  public buildScheduleSection(): VNode {
    const allowDiary = (
      this.proposal.status === DealStatus.Paid 
      || this.proposal.status === DealStatus.Completed 
      || this.proposal.status === DealStatus.Confirmed 
      || this.proposal.status === DealStatus.PaidToProvider
    ) 
      && this.proposal.demand.clientRequirementsWithParent.find(c => {
        return c.subCategories?.some(sc => sc?.category === 'NurseCareDiary')
      })

    return (
      <div>
        <ContentSection id="proposal-tasks" class="mb-6">
          <h2 class="fieldset-title">{this.translation('title_tasks')}</h2>
          {this.actor === ActorRole.Requester &&
          <Notice
            noticeClass="mt-4 mt-md-8 mb-2"
            dismissible
            noticeKey="transactionTasks"
            variant="warning"
          >
            <p>{this.translation('msg_transaction_proposals')}</p>
          </Notice>
          }
          <CompactScheduleCalendar
            class="mt-6"
            disabled={!this.allowChanges}
            ref="tasksCalendar"
            canComplete={this.actor === ActorRole.Provider && this.proposal.status === DealStatus.Paid}
            canConfirm={this.actor === ActorRole.Requester}
            selectedTask={this.selectedTask}
            showCalendarComponent={this.showCalendarComponent}
            showLockBtn={this.showLockBtn}
            title={this.proposal.demand.title}
            value={this.allTasks}
            availableServices={this.proposal.service.competencesWithParent}
            onAddCalendarEvent={(event: TaskDefinition) => {
              this.scheduleList = addOrUpdateEvent(event, this.scheduleList)
              this.fetchPriceAndTasks()
            }}
            onLockToEdit={() => this.onLockAndSilentFetchProposal()}
            onRemoveCalendarEvent={(event: TaskDefinition) => {
              this.scheduleList = removeEvent(event, this.scheduleList)
              this.fetchPriceAndTasks()
            }}
            onSetTaskComplete={(occurrenceId: string) => {this.onSetTaskComplete(occurrenceId)}}
            onSetTaskCompleteConfirm={(occurrenceId: string) => {this.onSetTaskCompleteConfirm(occurrenceId)}}
            onScheduleError={(err: string) => {
              this.errorMessageKey = err
            }}
            onTaskOpened={() => (this.selectedTask = {})}
            onWeekChanged={(date: Date) => this.updateBusyTime(date)}
          >
            <template slot="headerControls">
              <b-button-group class="ml-auto mt-sm-4">
                <b-button
                  aria-label={this.translation('lbl_show_calendar')}
                  pressed={this.showCalendarComponent}
                  class="d-flex justify-content-center px-3"
                  variant={this.showCalendarComponent ? 'primary' : 'outline-primary'}
                  onClick={() => {
                    this.showCalendarComponent = true
                  }}
                >
                  <b-icon-calendar4-week class="app-icon-lg d-flex" aria-hidden="true"/>
                </b-button>
                <b-button
                  aria-label={this.translation('lbl_show_tasks_list')}
                  pressed={!this.showCalendarComponent}
                  class="d-flex justify-content-center px-3"
                  variant={this.showCalendarComponent ? 'outline-primary' : 'primary'}
                  onClick={() => {
                    this.showCalendarComponent = false
                  }}
                >
                  <b-icon-list-ul class="app-icon-lg d-flex" aria-hidden="true"/>
                </b-button>
              </b-button-group>
            </template>
          </CompactScheduleCalendar>
          {!this.showCalendarComponent &&
            <div>
              {!this.busy && this.scheduleList.length === 0 &&
                <div class="bg-gray-100 rounded overflow-hidden d-flex align-items-center justify-content-center mt-10">
                  <div class="my-8 p-6 d-flex flex-column align-items-center justify-content-center text-center no-results-found">
                    <h3 class="mb-1 h4">{this.translation('lbl_section_empty')}</h3>
                    <p class="small">{this.translation('lbl_list_of_events_empty')}</p>
                    <b-button
                      variant="primary"
                      onClick={this.onAddCalendarTask}
                    >
                      {this.translation('btn_add_task')}
                    </b-button>
                  </div>
                </div>
              }
              {this.scheduleList.length > 0 && this.tasksCalendar &&
                <div>
                  {(this.showLockBtn || this.allowChanges) &&
                    <div class="d-flex flex-wrap justify-content-between mt-4 mt-md-6">
                      {!this.showLockBtn && this.allowChanges &&
                        <b-button
                          class="d-flex"
                          variant="primary"
                          onClick={this.onAddCalendarTask}
                        >
                          <b-icon-plus-circle class="app-icon-lg mr-2" aria-hidden="true" />
                          {this.translation('btn_add_task')}
                        </b-button>
                      }
                      {this.showLockBtn &&
                        <Button class="ml-auto" variant="warning" onClick={() => {this.onLockAndSilentFetchProposal()}} label={this.translation('btn_change')}/>
                      }
                    </div>
                  }
                  <ScheduleList
                    canComplete={this.actor === ActorRole.Provider && this.proposal.status === DealStatus.Paid}
                    canConfirm={this.actor === ActorRole.Requester}
                    disabled={!this.allowChanges}
                    selectedWeek={this.tasksCalendar!.selectedWeekDateRange()}
                    showLockBtn={this.showLockBtn}
                    value={this.scheduleList}
                    onSetTaskComplete={(taskId: string) => {this.onSetTaskComplete(taskId)}}
                    onSetTaskCompleteConfirm={(taskId: string) => {this.onSetTaskCompleteConfirm(taskId)}}
                    onToggleTaskDetails={(task: TaskDefinition | undefined) => {
                      this.selectedTask = task ? task : {};
                    }}
                  />
                </div>
              }
            </div>
          }
        </ContentSection>
        {allowDiary &&
          <Diary
            isProvider={this.isProvider}
            dealType={DealType.Proposal}
            dealId={allowDiary ? this.$route.params.proposalId : undefined}
            dealStatus={this.proposal.status}
          />
        }
      </div>
    )
  }

  private async onSendMessage(messagePayload: Message): Promise<void> {
    const payload: ProposalNewMessagePayload = {
      message: messagePayload,
      proposalId: this.$route.params.proposalId
    }
    const sentMsg = await this.withRequest(proposalDemandStore.sendMessage(payload), proposalErrorHandler, true)
    chatStore.addMessage(sentMsg)
  }

  private get otherPersonName(): string {
    const otherPerson = this.actor === ActorRole.Requester ? this.proposal.service.provider.name : this.proposal.requester.name

    return `${otherPerson.first} ${otherPerson.last}`
  }

  private deleteMsgCenterEvent(msgId: string): void {
    _.cloneDeep(chatStore.contacts).map(event => {
      event.deals.map(deal => {
        if (deal.dealId === this.proposal.id && Number(deal.dealUnreadedMessageCount) > 0) {
          this.messages = chatStore.getChatMessages
          chatStore.setEventAsReadedInternal(this.$route.params.proposalId)
          this.withRequest(chatStore.setEventAsReaded({msgId: msgId, dealType: DealType.Proposal.toUpperCase()}), proposalErrorHandler, true)
        }})
    })
  }

  public buildMessengerSection(): VNode {
    return (
      <ContentSection class="mb-6">
        <h2 class="fieldset-title">{this.translation('title_messages')}</h2>
        {!this.showMessenger &&
          <div class="bg-gray-100 rounded overflow-hidden d-flex align-items-center justify-content-center mt-10">
            <div class="my-8 p-6 d-flex flex-column align-items-center justify-content-center text-center no-results-found">
              <p class="small mb-0">{this.translation(`lbl_messages_disabled_description_${this.actor}`)}</p>
            </div>
          </div>
        }
        {this.showMessenger &&
          <Messenger
            onMessengerError={(err: string) => (this.errorMessageKey = err)}
            v-model={this.messages}
            viewForCurrentUserById={this.actor === ActorRole.Requester ? this.proposal.requester.userId : this.proposal.service.provider.userId}
            otherPersonName={this.otherPersonName}
            onSendMessage={(messagePayload: Message) => this.onSendMessage(messagePayload)}
            disabled={this.dealFinished}
            onChatInputFocus={(msgId: string) => this.deleteMsgCenterEvent(msgId)}
          />
        }
      </ContentSection>
    )
  }

  private async onLockAndSilentFetchProposal(): Promise<void> {
    if (this.proposal.lockState.status === LockState.NotLocked) {
      await this.withRequest(proposalDemandStore.lockProposal(this.$route.params.proposalId), proposalErrorHandler, true)
      await this.fetchProposal(true)
    }
  }

  public buildRatesAndPricesSection(): VNode {
    return (
      <ContentSection>
        <h2 class="fieldset-title">
          {(this.proposalDraft || this.proposal.status === DealStatus.New || this.proposal.status === DealStatus.Canceled || this.proposal.status === DealStatus.UnderDiscussion || this.proposal.status === DealStatus.Declined) && this.translation('title_preliminary_cost_of_services')}
          {(!this.proposalDraft && this.proposal.status !== DealStatus.New && this.proposal.status !== DealStatus.Canceled && this.proposal.status !== DealStatus.UnderDiscussion && this.proposal.status !== DealStatus.Declined) && this.translation('title_cost_of_services')}
        </h2>
        <div class="px-4 mb-6">
          <b-row class="mt-10 pb-1 align-items-center">
            <b-col cols="12" md="7" lg="8">
              {this.proposal.changesToAccept !== undefined && this.proposal.changesToAccept.length > 0 &&
                <InfoNotice
                  class="mr-md-8 mb-md-0"
                  icon="exclamation-triangle-fill"
                  variant="warning"
                >
                  <p class="h5 m-0">{this.translation('msg_changes_to_accept_attention')}</p>
                </InfoNotice>
              }
              {((this.proposal.changesToAccept === undefined || this.proposal.changesToAccept.length === 0) && this.proposal.status !== DealStatus.DealConcluded && this.proposal.status !== DealStatus.Paid && this.proposal.status !== DealStatus.Completed && this.proposal.status !== DealStatus.Confirmed && this.proposal.status !== DealStatus.PaidToProvider) &&
                <InfoNotice
                  class="mr-md-8 mb-md-0"
                  icon="exclamation-circle"
                >
                  {this.translation('estimated_cost_description')}
                </InfoNotice>
              }
              {(this.proposal.status === DealStatus.Paid || this.proposal.status === DealStatus.Completed) &&
                <InfoNotice
                  class="mr-md-8 mb-md-0"
                  icon="exclamation-circle"
                >
                  {this.actor === ActorRole.Requester && this.translation('requester_cost_description')}
                  {this.actor === ActorRole.Provider && this.translation('provider_cost_description')}
                </InfoNotice>
              }
            </b-col>
            <b-col cols="12" md="5" lg="4">
              {((this.isProvider && (this.proposal.status !== DealStatus.New && this.proposal.status !== DealStatus.UnderDiscussion && !this.proposalDraft))) &&
                <div>
                  {this.translation('lbl_service_price')}:
                  <span class="font-weight-bold float-right">
                    {isEmpty(this.proposal.price.servicePrice.amount) ? '0.00' : this.proposal.price.servicePrice.amount} €
                  </span>
                </div>
              }
              {this.isProvider && (this.proposal.status === DealStatus.New || this.proposal.status === DealStatus.UnderDiscussion || this.proposalDraft) &&
                <div>
                  <CheckBox
                    disabled={!(this.proposal.status === DealStatus.New || this.proposal.status === DealStatus.UnderDiscussion || this.proposalDraft)}
                    label={this.translation('lbl_automatic_price_calculation')}
                    checked={this.proposal.calculatePrice}
                    class="mb-1"
                    onChange={(v: boolean) => {
                      this.onLockAndSilentFetchProposal().then(() => {
                        this.proposal.calculatePrice = v
                        this.fetchPriceAndTasks().then(() => {
                          if (this.proposal.calculatePrice !== v) {
                            throw new Error("sync failed")
                          }
                        })
                      })
                    }}
                  />
                  <TextInput
                    disabled={this.proposal.lockState.status === LockState.AlreadyLockedByOther || this.proposal.calculatePrice}
                    label={this.translation('lbl_service_price')}
                    class="mb-3"
                    v-model={this.proposal.price.servicePrice.amount}
                    onFocus={() => this.onLockAndSilentFetchProposal()}
                    //onInput={(v: string) => {this.serviceProviderOwnPrice = v}}
                    onBlur={() => {
                      if (this.checkValidation(this.$v.proposal.price!.servicePrice.amount)) {
                        //this.servicePriceOverridden = Number(this.proposal.price.servicePrice.amount) !== Number(this.serviceProviderOwnPrice)
                        this.fetchPriceAndTasks()
                      }
                    }}
                    invalidMessage={this.buildInvalidMessage(this.$v.proposal.price!.servicePrice.amount)}
                  />
                </div>
              }
              <div>
                {this.isProvider ? this.translation('lbl_total_price_for_requester') : this.translation('lbl_total_service_price')}:
                <strong class="float-right">
                  {isEmpty(this.proposal.price.total?.amount) ? '0.00' : this.proposal.price.total!.amount} €
                </strong>
              </div>
            </b-col>
          </b-row>
        </div>
        <hr class="border-bottom border-primary mt-0 mb-6" />
        {this.buildControls()}
      </ContentSection>
    )
  }

  private async onCreateProposal(): Promise<void> {
    await this.onSubmit(ActionType.Create)
  }

  private async onUpdateProposal(silentUpdate?: boolean): Promise<void> {
    await this.onSubmit(ActionType.Update, silentUpdate)
  }

  private async onCancelProposal(): Promise<void> {
    await this.onSubmit(ActionType.Cancel)
  }

  private async onAcceptProposalToDiscussion(): Promise<void> {
    await this.onSubmit(ActionType.AcceptToDiscussion)
  }

  private async onAcceptCurrentVersion(): Promise<void> {
    await this.onSubmit(ActionType.AcceptCurrentVersion)
  }

  private async onPayForDeal(): Promise<void> {
    await this.onSubmit(ActionType.Pay)
  }

  private async onSendRating(): Promise<void> {
    await this.onSubmit(ActionType.Rate)
  }

  private async onBackClick(): Promise<void> {
    if ((this.proposal.lockState.status === LockState.LockedByYou || this.proposal.lockState.status === LockState.AlreadyLockedByYou)
    && (this.proposal.status === DealStatus.UnderDiscussion || this.proposal.status === DealStatus.New)) {
      await this.withRequest(proposalDemandStore.unlockProposal(this.$route.params.proposalId), proposalErrorHandler, true)
    }
    this.$router.go(-1)
  }

  public buildRequesterControls(): VNode {
    return (
      <div class="w-100 order-first order-sm-1 d-flex flex-fill flex-column flex-sm-row justify-content-sm-end mr-n2">
        {(this.proposal.status === DealStatus.New || this.proposal.status === DealStatus.UnderDiscussion) &&
          <Button class="mr-2 mb-2 mb-sm-0 order-last order-sm-first" variant="outline-primary" onClick={() => {this.showRejectAttentionPopup = !this.showRejectAttentionPopup}} label={this.translation('btn_decline')} />
        }
        {((this.proposal.status === DealStatus.New || this.proposal.status === DealStatus.UnderDiscussion)
        && (this.proposal.lockState.status === LockState.LockedByYou || this.proposal.lockState.status === LockState.AlreadyLockedByYou)) &&
          <Button class="mr-2 mb-2 mb-sm-0" disabled={this.scheduleList.length === 0} variant="primary" onClick={() => this.onUpdateProposal(this.proposal.status === DealStatus.New)} label={this.translation('btn_ready')} />
        }
        {this.proposal.status === DealStatus.New
          && this.proposal.lockState.status === LockState.NotLocked && this.scheduleList.length > 0 &&
          <Button class="mr-2 mb-2 mb-sm-0" variant="primary" onClick={() => this.onAcceptProposalToDiscussion()} label={this.translation('btn_accept')} />
        }
        {this.proposal.status === DealStatus.UnderDiscussion
        && this.proposal.lockState.status === LockState.NotLocked
        && !this.proposal.acceptedLatestVersion &&
          <Button class="mr-2 mb-2 mb-sm-0" variant="primary" onClick={() => this.onAcceptCurrentVersion()} label={this.translation('btn_make_a_deal')} />
        }
        {(this.proposal.status === DealStatus.DealConcluded
          || this.proposal.status === DealStatus.PaymentFailed
          || this.proposal.status === DealStatus.PaymentStarted)
          && this.paymentMethods.bankLinks.length === 0 &&
          <Button class="mr-2 mb-2 mb-sm-0" variant="primary" label={this.translation('btn_pay')} onClick={() => this.onPayForDeal()} />
        }
        {this.paymentMethods.bankLinks.length > 0 &&
          <div class="payment-methods mr-2 ml-sm-2 mb-2 mb-sm-0">
            <p class="mb-1 text-center text-sm-right">
              {this.translation('lbl_payment_method_opts')}
            </p>
            <div class="payment-method-links d-flex flex-wrap justify-content-center justify-content-sm-end">
              {this.paymentMethods.bankLinks.map(it => {
                return (
                  <b-link class="payment-method-link" href={it.url}>
                    <b-img class="payment-method-logo" src={it.logo_url} />
                  </b-link>
                )
              })}
            </div>
          </div>
        }
        {(this.proposal.status === DealStatus.Confirmed || this.proposal.status === DealStatus.PaidToProvider) && (this.proposal.isRatedBy === RatedBy.Provider || this.proposal.isRatedBy === RatedBy.Nobody) &&
          <Button id="btn-rate" variant="primary" class="mr-2 mb-2 mb-sm-0 btn-anim-pulse-blue" onClick={() => this.onSendRating()} label={this.translation('btn_rate_provider')} />
        }
      </div>
    )
  }

  public buildProviderControls(): VNode {
    return (
      <div class="w-100 order-first order-sm-1 d-flex flex-fill flex-column flex-sm-row justify-content-sm-end mr-n2">
        {(this.proposal.status === DealStatus.New || this.proposal.status === DealStatus.UnderDiscussion) &&
          <Button class="mr-2 mb-2 mb-sm-0 order-last order-sm-first" variant="outline-primary" onClick={() => {this.showRejectAttentionPopup = !this.showRejectAttentionPopup}} label={this.translation('btn_annual')} />
        }
        {(this.proposal.status === DealStatus.New
          || this.proposal.status === DealStatus.UnderDiscussion)
          && (this.proposal.lockState.status === LockState.LockedByYou
          || this.proposal.lockState.status === LockState.AlreadyLockedByYou) &&
          <Button class="mr-2 mb-2 mb-sm-0" variant={this.scheduleList.length === 0 ? 'outline-primary' : 'primary'} onClick={() => this.onUpdateProposal(false)} label={this.translation('btn_update')} />
        }
        {this.proposalDraft &&
          <Button class="mr-2 mb-2 mb-sm-0" variant="primary" onClick={() => this.onCreateProposal()} label={this.translation('btn_send_a_proposal')} />
        }
        {this.proposal.status === DealStatus.UnderDiscussion
          && this.proposal.lockState.status === LockState.NotLocked
          && !this.proposal.acceptedLatestVersion &&
          <Button class="mr-2 mb-2 mb-sm-0" variant="primary" onClick={() => this.onAcceptCurrentVersion()} label={this.translation('btn_make_a_deal')} />
        }
        {(this.proposal.status === DealStatus.Confirmed || this.proposal.status === DealStatus.PaidToProvider) && (this.proposal.isRatedBy === RatedBy.Requester || this.proposal.isRatedBy === RatedBy.Nobody) &&
          <Button id="btn-rate" variant="primary" class="mr-2 mb-2 mb-sm-0 btn-anim-pulse-blue" onClick={() => this.onSendRating()} label={this.translation('btn_rate_requester')} />
        }
      </div>
    )
  }

  public buildControls(): VNode {
    return (
      <b-row>
        <ConfirmPopup
          btnCancel={true}
          btnCancelLabel={this.translation('btn_modal_cancel')}
          btnClose={true}
          btnConfirm={true}
          btnConfirmLabel={this.translation('btn_modal_ok')}
          message={() => <span>{this.isProvider ? this.translation('msg_attention_to_cancel_proposal') : this.translation('msg_attention_to_decline_proposal')}</span>}
          show={this.showRejectAttentionPopup}
          title={this.translation('lbl_proposal')}
          onConfirm={() => this.onCancelProposal()}
          onCancel={() => (this.showRejectAttentionPopup = false)}
        />
        {(((this.proposal.status === DealStatus.DealConcluded
        || this.proposal.status === DealStatus.PaymentFailed)
        && this.paymentMethods.bankLinks.length === 0) ||
        (this.proposal.status === DealStatus.PaymentStarted
        && this.paymentMethods.bankLinks.length === 0)) &&
        !this.isProvider &&
          <b-col cols="12" class="text-md-right">
            <div class="pl-4 pr-2 pr-md-4">
              <CheckBox
                checkBoxClass="mb-0"
                v-model={this.paymentTermsAccepted}
                invalidMessage={this.buildInvalidMessage(this.$v.paymentTermsAccepted)}
              >
                <template slot="label">
                  <i18n path="lbl_consent_payment_conditions">
                    <b-link class="text-primary" to={PaymentAgreement.path} target="_blank">{this.translation('lbl_consent_payment_conditions_link')}</b-link>
                  </i18n>
                </template>
              </CheckBox>
            </div>
          </b-col>
        }
        {this.proposal.status === DealStatus.UnderDiscussion
        && this.proposal.lockState.status === LockState.NotLocked
        && !this.proposal.acceptedLatestVersion &&
          <b-col cols="12" class="text-md-right">
            <div class="pl-4 pr-2 pr-md-4">
              <CheckBox
                checkBoxClass="mb-0"
                v-model={this.termsAccepted}
                invalidMessage={this.buildInvalidMessage(this.$v.termsAccepted)}
              >
                <template slot="label">
                  <i18n path="msg_contract_consent_agreement">
                    <b-link class="text-primary" href={`${BackendUrls.urlApi}${BackendUrls.urlBaseWeb}/static/terms.pdf`} target="_blank">{this.translation('msg_contract_consent_inner_link')}</b-link>
                  </i18n>
                </template>
              </CheckBox>
            </div>
          </b-col>
        }
        <b-col cols="12">
          <div class="px-md-4 d-flex flex-column flex-sm-row justify-content-between align-items-start app-form-controls">
            <Button class="mr-2 mb-2 mb-sm-0" variant="outline-primary" onClick={() => this.onBackClick()} label={this.translation('btn_back')} />
            {this.actor === ActorRole.Requester && this.buildRequesterControls()}
            {this.actor === ActorRole.Provider && this.buildProviderControls()}
          </div>
        </b-col>
        {((((this.proposal.status === DealStatus.New
            || this.proposal.status === DealStatus.UnderDiscussion)
            && (this.proposal.lockState.status === LockState.LockedByYou
            || this.proposal.lockState.status === LockState.AlreadyLockedByYou))) && this.scheduleList.length === 0) &&
          <b-col cols="12" class="mt-2 text-sm-right small">
            <div class="px-md-4">
              <span class="text-danger-dark">{this.translation('msg_calendar_empty')}</span>
            </div>
          </b-col>
        }
      </b-row>
    )
  }

  public preventDefaultEvents(e: Event): void {
    e.preventDefault()
    e.stopPropagation()
  }

  //for reactivity
  protected get getUnreadMessages(): undefined {
    // eslint-disable-next-line no-unused-expressions
    chatStore.getInitialAmountOfUnreadMessages
    return undefined
  }

  public render(): VNode {
    if (chatStore.getChatMessages.length > 0) { //firefox not rendering without this after
      this.messages = chatStore.getChatMessages
    }
    // eslint-disable-next-line no-unused-expressions
    this.getUnreadMessages
    return (
      <div>
        {this.proposalIsLocked &&
        (this.proposal.status === DealStatus.New || this.proposal.status === DealStatus.UnderDiscussion) &&
          this.buildCountDown()
        }
        <b-container fluid="xl" class="proposal-page">
          {this.busy && <LoadingIndicator />}
          {!this.busy &&
            <form onsubmit={(e: Event) => this.preventDefaultEvents(e)}>
              <DealLayout
                dataSection={this.buildDataSection}
                scheduleSection={this.buildScheduleSection}
                messengerSection={this.buildMessengerSection}
                confirmationSection={this.buildRatesAndPricesSection}
              />
            </form>
          }
        </b-container>
      </div>
    )
  }
}
