import {Component, Prop, Ref} from "vue-property-decorator"
import {VNode} from 'vue'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: No typescript declarations
import DatePicker from 'vuejs-datepicker'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: No typescript declarations
import { en, ee, ru } from 'vuejs-datepicker/dist/locale/index'

import {DatePickerInterface, DealStatus, DealType, DiaryDataForm, Medications, NurseDiaryReportPayload, OrderDirection, Unit} from "@/types";
import {requestServiceStore} from "@/_modules/request/store/request-service-store";
import {BIcon, BIconCalendar4Week, BIconChevronLeft, BIconClock, BIconCloudDownload, BIconGraphUp, BIconListUl, BIconPieChart, BIconSortDown, BIconSortDownAlt} from "bootstrap-vue";
import moment from "moment";
import {serverDateTimeFormat} from "@/constants/ApplicationConfiguration";
import {appStore} from "@/store";
import {convertToI18nLang} from "@/store/app";
import {buildDiaryFormInitialState} from "@/_modules/shared-builders";
import {Validations} from "vuelidate-property-decorators";
import {buildValidationRules, inferValidationInstance, ValidationInstance, ValidationObject, ValidationRuleSet} from "@/utils/vuelidate-extension";
import DiaryForm from "@/components/diary/DiaryForm";
import DiaryEntry from "@/components/diary/DiaryEntry";
import DataBoundaries, {isEmpty, isNumericEmpty, oneDigitAfterDot} from "@/constants/DataBoundaries";
import FormMixin from "@/mixins/FormMixin";
import {decimal, integer, required} from "vuelidate/lib/validators";
import {proposalDemandStore} from "@/_modules/proposal/store/proposal-demand-store";
import {BasicErrorHandler} from "@/utils/errorHandler";
import * as ResponseError from "@/utils/errors"
import {ErrorCodes} from "@/constants/APIconstants";
import DiaryChart from "@/components/DiaryChart";
import { formatDate, getDatepickerDisplayDateFormat } from "@/utils/formatters";
import { AppLang } from "@/i18n";
import Notice from "../layout/Notice";
import CollapseSection from "../layout/CollapseSection";

function parseTime(time: string, date: string): Date {
  const [hours, minutes] = time.split(':')
  const selectedTime = moment(date)
  selectedTime.set('hour', Number(hours))
  selectedTime.set('minutes', Number(minutes))
  return selectedTime.toDate()
}

export interface DiaryDataValidation {
  diaryData: ValidationObject<DiaryDataForm>;
  requestedPeriod: ValidationObject<{ start: ValidationRuleSet; end: ValidationRuleSet }>;
}

export class DiaryErrorHandler extends BasicErrorHandler {
  protected async handleBackendError(e: ResponseError.BadRequestError): Promise<void> {

    switch(e.code) {
      case ErrorCodes.ServiceRequestStatusIncorrect:
        this.errorMessageKey = 'err_service_request_status_incorrect'
        break
      case ErrorCodes.ProposalStatusIncorrect:
        this.errorMessageKey = 'err_proposal_status_incorrect'
        break
      default:
        await super.handleBackendError(e)
        break
    }
  }
}

const diaryErrorHandler = new DiaryErrorHandler()

@Component({
  name: 'Diary',
  components: {
    BIcon,
    BIconCalendar4Week,
    BIconChevronLeft,
    BIconClock,
    BIconCloudDownload,
    BIconGraphUp,
    BIconListUl,
    BIconPieChart,
    BIconSortDown,
    BIconSortDownAlt
  }
})
export default class Diary extends FormMixin {
  @Prop(Boolean) public readonly isProvider!: boolean
  @Prop(String) public readonly dealId!: string
  @Prop(String) public readonly dealType!: DealType
  @Prop(String) public readonly dealStatus!: string

  @Ref('datePicker') public readonly datePicker!: DatePickerInterface

  private diaryList: DiaryDataForm[] = []
  private diaryData: DiaryDataForm = buildDiaryFormInitialState()
  private dateRange: {to: Date; from: Date} = {
    to: moment().startOf('isoWeek').toDate(),
    from: moment().endOf('isoWeek').toDate()
  }
  private requestedPeriod: {start: Date; end: Date} = {
    start: moment().startOf('isoWeek').toDate(),
    end: moment().endOf('isoWeek').toDate()
  }
  private selectedView: 'chart' | 'list' = 'list'
  private diaryListOrderDirection: OrderDirection = OrderDirection.DESC

  @Validations()
  protected validations(): DiaryDataValidation {
    function anyFieldIsFilled(v: string, scope: object): boolean {
      const s = (scope as DiaryDataForm)
      return !isEmpty(s.notes) ||
        !isEmpty(s.bodyTemperature) ||
        !isNumericEmpty(s.heartRate) ||
        !isEmpty(s.bloodPressure) ||
        !isEmpty(s.bloodSugarLevel) ||
        !isNumericEmpty(s.saturation) ||
        !isNumericEmpty(s.urineVolume) ||
        s.medications?.length !== 0
    }

    function startDateEarlyEndDate(v: string, requestedPeriod: object): boolean {
      return moment(v).diff((requestedPeriod as {start: Date; end: Date}).end) < 0
    }

    return {
      diaryData: {
        time: {required},
        id: {},
        heartRate: {
          integer,
          ...buildValidationRules(DataBoundaries.heartRate),
          required: anyFieldIsFilled
        },
        bodyTemperature: {
          decimal,
          oneDigitAfterDot,
          ...buildValidationRules(DataBoundaries.bodyTemperature),
          required: anyFieldIsFilled
        },
        bloodPressure: {
          ...buildValidationRules(DataBoundaries.bloodPressure),
          required: anyFieldIsFilled
        },
        bloodSugarLevel: {
          decimal,
          ...buildValidationRules(DataBoundaries.bloodSugarLevel),
          required: anyFieldIsFilled
        },
        saturation: {
          integer,
          ...buildValidationRules(DataBoundaries.percentage),
          required: anyFieldIsFilled
        },
        urineVolume: {
          integer,
          ...buildValidationRules(DataBoundaries.urineVolume),
          required: anyFieldIsFilled
        },
        medications: {
          required: anyFieldIsFilled,
          $each: {
            name: {required},
            amount: {
              required,
              decimal,
              ...buildValidationRules(DataBoundaries.medicineAmount)
            }
          }
        },
        notes: {
          ...buildValidationRules(DataBoundaries.longDescription),
          required: anyFieldIsFilled
        },
        collectedAt: {required}
      },
      requestedPeriod: {
        start: !this.isProvider ? {required, startDateEarlyEndDate} : {},
        end: !this.isProvider ? {required} : {}
      }
    }
  }

  private async getListOfReports(): Promise<void> {
    if (this.dealType === DealType.Request && this.dealId !== undefined) {
      this.diaryList = await this.withRequest(requestServiceStore.getRequestNurseDiaryReportList(this.dealId), diaryErrorHandler)
    }
    if (this.dealType === DealType.Proposal && this.dealId !== undefined) {
      this.diaryList = await this.withRequest(proposalDemandStore.getRequestNurseDiaryReportList(this.dealId), diaryErrorHandler)
    }

    // Sort most recent entry first
    this.diaryList.sort((entry1, entry2) => {
      return moment(entry2.collectedAt).valueOf() - moment(entry1.collectedAt).valueOf()
    });
  }

  private get filteredDiaryList(): DiaryDataForm[] {
    // Filter diary entries for selected period
    return this.diaryList.filter((entry) => {
      return moment(entry.collectedAt).isBetween(this.requestedPeriod.start, this.requestedPeriod.end)
    });
  }

  public get datepickerLanguage(): object {
    switch (this.$i18n.locale) {
      case AppLang.ET:
        return ee
      case AppLang.RU:
        return ru
      default:
        return en
    }
  }

  public async mounted(): Promise<void> {
    await this.getListOfReports()

    // Diary entries are ordered by date descending by default
    const firstDiaryEntry = this.diaryList.slice(-1)[0];
    const lastDiaryEntry = this.diaryList.slice(0)[0];

    // Set requested period to the last diary entry for requester if available
    if (!this.isProvider && lastDiaryEntry !== undefined) {
      this.requestedPeriod = {
        start: moment(lastDiaryEntry.collectedAt).startOf('isoWeek').toDate(),
        end: moment(lastDiaryEntry.collectedAt).endOf('isoWeek').toDate()
      }
    }

    this.dateRange = {
      to: firstDiaryEntry !== undefined ? moment(firstDiaryEntry.collectedAt).startOf('isoWeek').toDate() : this.dateRange.to,
      from: lastDiaryEntry !== undefined ? moment(lastDiaryEntry.collectedAt).endOf('isoWeek').toDate() : this.dateRange.from
    }
  }

  private convertMedicationsIntoString(): string[] {
    return (this.diaryData.medications! as Medications[]).map(it => `${it.name} ${it.amount} ${it.unit}`)
  }

  private convertDataIntoServerFormat(form: DiaryDataForm): DiaryDataForm {
    return {
      collectedAt: form.collectedAt,
      id: !isEmpty(form.id) ? form.id : undefined,
      heartRate: !isNumericEmpty(form.heartRate) ? form.heartRate : undefined,
      bodyTemperature: !isEmpty(form.bodyTemperature) ? form.bodyTemperature : undefined,
      bloodPressure: !isEmpty(form.bloodPressure) ? form.bloodPressure : undefined,
      bloodSugarLevel: !isEmpty(form.bloodSugarLevel) ? form.bloodSugarLevel : undefined,
      saturation: !isNumericEmpty(form.saturation) ? form.saturation : undefined,
      urineVolume: !isNumericEmpty(form.urineVolume) ? form.urineVolume : undefined,
      notes: !isEmpty(form.notes) ? form.notes : undefined
    }
  }

  private async sendNurseDiaryReport(): Promise<void> {
    if (this.checkValidation(this.$v.diaryData)) {
      this.$v.$reset()
      this.convertDataIntoServerFormat(this.diaryData)
      const t = parseTime(this.diaryData.time!, this.diaryData.collectedAt!)
      this.diaryData.collectedAt = moment(t).format(serverDateTimeFormat)
      this.medicineNote = ''

      const data = {
        ...this.convertDataIntoServerFormat(this.diaryData),
        medications: this.diaryData.medications!.length === 0 ? undefined : this.convertMedicationsIntoString()
      }

      const payload: NurseDiaryReportPayload = {
        dealId: this.dealId,
        diary: {
          ...data,
          time: undefined
        }
      }
      if (this.dealType === DealType.Request) {
        await this.withRequest(requestServiceStore.sendNurseDiaryReport(payload), diaryErrorHandler).finally(() => {
          this.diaryData = buildDiaryFormInitialState()
        })
      }
      if (this.dealType === DealType.Proposal) {
        await this.withRequest(proposalDemandStore.sendNurseDiaryReport(payload), diaryErrorHandler).finally(() => {
          this.diaryData = buildDiaryFormInitialState()
        })
      }
      await this.getListOfReports()
    }
  }

  private medicineNote: string = ''

  /*public onlyTimeFromDate(value: Date): string {
    const formattedHours = (moment(value).toObject().hours).toString().length === 1 ? `0${moment(value).toObject().hours}` : `${moment(value).toObject().hours}`
    const formattedMinutes = (moment(value).toObject().minutes).toString().length === 1 ? `0${moment(value).toObject().minutes}` : `${moment(value).toObject().minutes}`
    return `${formattedHours}:${formattedMinutes}`
  }*/

  private addMedicineNote(): void {
    if (this.medicineNote.length !== 0) {
      (this.diaryData.medications! as Medications[]).push({
        name: this.medicineNote,
        amount: '',
        unit: Unit.ml
      })

      this.medicineNote = ''
    }
  }

  private removeMedicineNote(index: number): void {
    this.diaryData.medications!.splice(index, 1)
  }

  private onRequestedPeriodClick(): string {
    if (this.checkValidation(this.$v.requestedPeriod)) {
      this.requestedPeriod.start.setHours(0); this.requestedPeriod.start.setMinutes(0); this.requestedPeriod.start.setSeconds(0)
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      this.requestedPeriod.end.setHours(23); this.requestedPeriod.end.setMinutes(59); this.requestedPeriod.end.setSeconds(59)
      if (this.dealType === DealType.Request) {
        const payload: {start: string; end: string; lang: string; requestId: string} = {
          start: moment(this.requestedPeriod.start).format(serverDateTimeFormat),
          end: moment(this.requestedPeriod.end).format(serverDateTimeFormat),
          lang: convertToI18nLang(appStore.locale).toUpperCase(),
          requestId: this.dealId
        }
        this.withRequest(requestServiceStore.getDiaryListDownload(payload), diaryErrorHandler)
      }
      if (this.dealType === DealType.Proposal) {
        const payload: {start: string; end: string; lang: string; proposalId: string} = {
          start: moment(this.requestedPeriod.start).format(serverDateTimeFormat),
          end: moment(this.requestedPeriod.end).format(serverDateTimeFormat),
          lang: convertToI18nLang(appStore.locale).toUpperCase(),
          proposalId: this.dealId
        }
        this.withRequest(proposalDemandStore.getDiaryListDownload(payload), diaryErrorHandler)
      }
    }
    return ''
  }

  public render(): VNode {
    const validator: ValidationInstance<DiaryDataValidation> = inferValidationInstance(this.$v)

    if (this.isProvider && (this.dealStatus === DealStatus.Confirmed || this.dealStatus === DealStatus.PaidToProvider) && this.filteredDiaryList.length === 0) {
      return <div />
    }

    return (
      <CollapseSection
        class="mb-6"
        title={this.translation('title_diary')}
        visible={true}
      >
        <div class="diary">
          {!this.isProvider && this.diaryList.length === 0 &&
            <div class="bg-gray-100 rounded overflow-hidden d-flex align-items-center justify-content-center mt-10">
              <div class="my-8 p-6 d-flex flex-column align-items-center justify-content-center text-center no-results-found">
                <h3 class="mb-1 h4">{this.translation('lbl_section_empty')}</h3>
                <p class="small mb-0">{this.translation('shared.diary-list-empty')}</p>
              </div>
            </div>
          }
          {this.isProvider && !(this.dealStatus === DealStatus.Confirmed || this.dealStatus === DealStatus.PaidToProvider) &&
            <DiaryForm
              value={this.diaryData}
              onReportSend={() => this.sendNurseDiaryReport()}
              onAddMedicineNote={() => this.addMedicineNote()}
              onRemoveMedicineNote={(index: number) => this.removeMedicineNote(index)}
              medicineNote={this.medicineNote}
              onMedicineNoteInput={(v: string) => (this.medicineNote = v)}
              v={validator}
              dealStatus={this.dealStatus}
            />
          }
          {this.isProvider && !(this.dealStatus === DealStatus.Confirmed || this.dealStatus === DealStatus.PaidToProvider) && this.filteredDiaryList.length !== 0 && <hr class="my-10" />}
          {this.filteredDiaryList.length !== 0 &&
            <div class="d-flex flex-column flex-sm-row align-items-sm-start mt-8">
              {this.isProvider && 
                <h3 class="mb-6 my-sm-auto mr-auto text-nowrap">
                  {this.translation('shared.previous-entries')}
                </h3>
              }
              {!this.isProvider &&
                <div class="d-flex flex-column flex-lg-row align-items-sm-start align-items-lg-center mr-sm-auto">
                  <DatePicker
                    monday-first={true}
                    language={this.datepickerLanguage}
                    format={getDatepickerDisplayDateFormat()}
                    highlighted={{
                      from: this.requestedPeriod.start,
                      to: this.requestedPeriod.end
                    }}
                    ref="datePicker"
                    typeable={false}
                    placeholder={`${formatDate(this.requestedPeriod.start)}-${formatDate(this.requestedPeriod.end)}`}
                    bootstrap-styling={true}
                    calendar-class='app-datepicker-calendar'
                    input-class='schedule-datepicker-input border-right-0 pr-0 bg-white'
                    disabledDates={this.dateRange}
                    onInput={(date: Date | null) => {
                      if (date instanceof Date) {
                        const momentDate = moment(date);
                        this.requestedPeriod.start = momentDate.startOf('isoWeek').toDate();
                        this.requestedPeriod.end = momentDate.endOf('isoWeek').toDate();
                      }
                      // Reset selected date. Placeholder is used to display date range
                      this.datePicker.selectedDate = null;
                    }}
                  >
                    <template slot="afterDateInput">
                      <span class="input-group-append" onClick={() => {this.datePicker.showCalendar()}}>
                        <span class="input-group-text px-3">
                          <b-icon-calendar4-week variant="primary" aria-hidden="true" />
                        </span>
                      </span>
                    </template>
                  </DatePicker>
                  {this.filteredDiaryList.length !== 0 &&
                    <b-button
                      variant="outline-primary"
                      class="text-nowrap mt-4 mt-lg-0 ml-lg-4"
                      onClick={() => this.onRequestedPeriodClick()}
                    >
                      <b-icon-cloud-download aria-hidden="true" class="app-icon-lg align-bottom mr-2" />
                      {this.translation('shared.download-file')}
                    </b-button>
                  }
                </div>
              }
              <div class="d-flex flex-column flex-md-row align-items-sm-end align-items-md-center">
                {!this.isProvider && this.selectedView === 'list' &&
                  <b-link
                    class="text-primary text-nowrap py-1 my-4 my-md-0 mx-auto mx-md-8 order-sm-last order-md-first"
                    onClick={() => {
                      this.diaryListOrderDirection = this.diaryListOrderDirection === OrderDirection.ASC ? OrderDirection.DESC : OrderDirection.ASC;
                      this.diaryList = this.diaryList.reverse();
                    }}
                  >
                    <b-icon icon={this.diaryListOrderDirection === OrderDirection.ASC ? 'sort-down' : 'sort-down-alt'} aria-hidden="true" class="app-icon-lg align-bottom my-0 mr-2"/>
                    {this.translation(`shared.order-direction-time-${this.diaryListOrderDirection === OrderDirection.ASC ? OrderDirection.DESC : OrderDirection.ASC}`)}
                  </b-link>
                }
                <b-button-group>
                  <b-button
                    aria-label={this.translation('lbl_show_calendar')}
                    pressed={this.selectedView === 'list'}
                    class="d-flex justify-content-center px-3"
                    variant={this.selectedView === 'list' ? 'primary' : 'outline-primary'}
                    onClick={() => {
                      this.selectedView = 'list'
                    }}
                  >
                    <b-icon-list-ul class="app-icon-lg d-flex" aria-hidden="true"/>
                  </b-button>
                  <b-button
                    aria-label={this.translation('lbl_show_tasks_list')}
                    pressed={this.selectedView === 'chart'}
                    class="d-flex justify-content-center px-3"
                    variant={this.selectedView === 'chart' ? 'primary' : 'outline-primary'}
                    onClick={() => {
                      this.selectedView = 'chart'
                    }}
                  >
                    <b-icon-graph-up class="app-icon-lg d-flex" aria-hidden="true"/>
                  </b-button>
                </b-button-group>
              </div>
            </div>
          }
          {this.isProvider && this.diaryList.length !== 0 && 
            <Notice
              noticeClass="mt-6 mt-md-10 mb-2"
              dismissible
              noticeKey="diaryList"
              variant="warning"
            >
              <p>{this.translation('shared.provider-diary-list-notice')}</p>
            </Notice>
          }
          {this.selectedView === 'chart' && this.filteredDiaryList.length !== 0 &&
            <DiaryChart
              value={this.filteredDiaryList}
            />
          }
          {this.selectedView === 'list' && this.filteredDiaryList.length !== 0 && 
            <div class="accordion border-top mt-6 mt-md-10">
              {this.filteredDiaryList.map((item, index) => (
                <div class="border-bottom">
                  <h4 class="my-0" role="tab">
                    <b-link class="d-flex p-4" href="#" v-b-toggle={`${index}-dl-accordion-item`}>
                      <time class="flex-fill" datetime={moment(item.collectedAt).format(serverDateTimeFormat)}>
                        <span class="d-flex text-md" aria-hidden="true">
                          {formatDate(item.collectedAt)}
                          <b-icon-clock class="app-icon-lg text-gray-500 ml-2 ml-sm-3 mr-1 mr-sm-2" />
                          <span class="font-weight-normal">{moment(item.collectedAt).format('HH:mm')}</span>
                        </span>
                      </time>
                      <b-icon-chevron-left aria-hidden="true" variant="primary" class="app-icon-lg mt-0 ml-1" />
                    </b-link>
                  </h4>
                  <b-collapse id={`${index}-dl-accordion-item`} accordion={`${index}-dl-accordion-item`} role="tabpanel">
                    <DiaryEntry value={item} />
                  </b-collapse>
                </div>
              ))}
            </div>
          }
        </div>
      </CollapseSection>
    )
  }
}
