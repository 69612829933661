import { appStore } from "@/store";
import { ApexOptions } from "apexcharts";
import moment from "moment";

export function buildInitialSerieState(): [{name: string; data: []}] {
  return [{name: '', data: []}]
}

// Add custom prop to y axis tooltip options to set the unit for tooltip
export type ChartOptions = ApexOptions & {
  tooltip: {
    y: {
      dataUnit: string;
    };
  };
}

export function buildChartSettings(): ChartOptions {
  return {
    title: {
      text: '',
      offsetX: 6,
      style: {
        fontSize: '24px',
        fontWeight: 'bold'
      }
    },
    chart: {
      defaultLocale: 'en',
      locales: [{
        name: 'en',
        options: {
          months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
          shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        }
      }, {
        name: 'ru',
        options: {
          months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
          shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Нов', 'Дек'],
          days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
          shortDays: ['Вс', 'Пон', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          toolbar: {
            selectionZoom: "Выбор с увеличением",
            zoomIn: "Увеличить",
            zoomOut: "Уменьшить",
            pan: "Перемещение",
            reset: "Сбросить увеличение",
            exportToCSV: ''
          }
        }
      }, {
        name: 'et',
        options: {
          months: ['Jaanuar', 'Veebruar', 'Märts', 'Aprill', 'Mai', 'Juuni', 'Juuli', 'August', 'September', 'Oktoober', 'November', 'Detsember'],
          shortMonths: ['Jaan', 'Vebr', 'Märts', 'Apr', 'Mai', 'Juuni', 'Juuli', 'Aug', 'Sept', 'Okt', 'Nov', 'Dets'],
          days: ['Pühapäev', 'Esmaspäev', 'Teisipäev', 'Kolmapäev', 'Neljapäev', 'Reede', 'Laupäev'],
          shortDays: ['P', 'E', 'T', 'K', 'N', 'R', 'L'],
          toolbar: {
            selectionZoom: 'Suurenda valikut',
            zoomIn: 'Suurenda',
            zoomOut: 'Vähenda',
            pan: 'Panoreeri',
            reset: 'Lähtesta suum',
            exportToCSV: ''
          }
        }
      }],
      animations: {
        enabled: false
      },
      fontFamily: 'inherit',
      foreColor: '#222222',
      height: 375,
      toolbar: {
        tools: {
          download: false,
          pan: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M8.295 5.482A1.125 1.125 0 007.17 6.607v3.875a.75.75 0 11-1.5 0V9.149l-1.504.3a.75.75 0 00-.599.819l.518 4.657c.04.357.165.7.364.999l2.15 3.225a.75.75 0 00.624.335H16.8a.75.75 0 00.651-.378l2.093-3.664a3.75 3.75 0 00.475-1.486l.408-4.072a1.502 1.502 0 00-1.492-1.65h-.515v1.5a.75.75 0 11-1.5 0v-2.02a6.326 6.326 0 00-.3-.39c-.28-.333-.552-.574-.729-.645-.186-.074-.588-.094-1.062-.058l-.159.015v2.348a.75.75 0 11-1.5 0V5.96c0-.25-.11-.408-.225-.47a2.487 2.487 0 00-.672-.273c-.268-.053-1.224-.134-1.595.085.273.367.242.808.242.808v2.872a.75.75 0 11-1.5 0V6.607a1.125 1.125 0 00-1.125-1.125zm1.246-1.199c-.547-.258-.853-.3-1.246-.3A2.625 2.625 0 005.67 6.607v1.01l-1.798.36a2.25 2.25 0 00-1.796 2.454l.518 4.66a3.75 3.75 0 00.607 1.665l2.15 3.224a2.25 2.25 0 001.872 1.003H16.8a2.25 2.25 0 001.953-1.134l2.093-3.662a5.25 5.25 0 00.666-2.084l.408-4.072a3 3 0 00-2.985-3.299h-.873a7.804 7.804 0 00-.293-.372c-.286-.343-.765-.852-1.32-1.074-.546-.218-1.269-.197-1.737-.161l-.198.018a1.89 1.89 0 00-.84-.963 3.952 3.952 0 00-1.107-.432c-.465-.094-.815-.07-1.575-.07s-1.451.605-1.451.605z" clip-rule="evenodd"/></svg>',
          reset: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M15.661 15.663a1.5 1.5 0 012.123 0l5.775 5.775a1.5 1.5 0 01-2.121 2.122l-5.775-5.775a1.5 1.5 0 010-2.122h-.002z" clip-rule="evenodd"/><path fill="currentColor" fill-rule="evenodd" d="M9.75 18a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5zm9.75-8.25a9.75 9.75 0 11-19.5 0 9.75 9.75 0 0119.5 0z" clip-rule="evenodd"/><path stroke="currentColor" stroke-linecap="round" stroke-width="1.5" d="M6 6l4 4m0 0l4 4m-4-4l4-4m-4 4l-4 4"/></svg>',
          selection: false,
          zoom: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-width="1.5" d="M.75 5.75h22.5v12.5H.75z"/><path stroke="currentColor" stroke-dasharray="2 3" stroke-linecap="round" stroke-width="1.5" d="M6 1h14v22H6z"/><path fill="currentColor" d="M13.75 9a.75.75 0 00-1.5 0h1.5zm-1.5 6a.75.75 0 001.5 0h-1.5zM16 12.75a.75.75 0 000-1.5v1.5zm-6-1.5a.75.75 0 000 1.5v-1.5zM12.25 9v3h1.5V9h-1.5zm0 3v3h1.5v-3h-1.5zm.75.75h3v-1.5h-3v1.5zm0-1.5h-3v1.5h3v-1.5z"/></svg>',
          zoomin: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-zoom-in" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/><path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"/><path fill-rule="evenodd" d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"/></svg>',
          zoomout: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-zoom-out" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/><path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"/><path fill-rule="evenodd" d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/></svg>'
        }
      }
    },
    colors: ['#004bcc', '#069964'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
      width: 1
    },
    markers: {
      size: 6,
      hover: {
        size: 8
      }
    },
    tooltip: {
      followCursor: false,
      shared: false,
      theme: 'light',
      style: {
        fontSize: '15px'
      },
      x: {
        show: true,
        format: 'dd.MM.yyyy HH:mm',
        formatter: (v: number) => {
          return moment(v).format('DD.MM.yyyy HH:mm')
        }
      },
      marker: {
        show: false
      },
      y: {
        formatter: (v: number, opts) => {
          // Use custom dataUnit prop from y axis tooltip options
          return Number(v).toLocaleString(appStore.locale).toString() + opts.w.config.tooltip.y.dataUnit
        },
        title: {
          // Disable y axis title in tooltip
          formatter: () => ''
        },
        dataUnit: ''
      }
    },
    grid: {
      show: true,
      borderColor: '#dedede',
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
      padding: {
        left: 20,
        right: 16
      }
    },
    legend: {
      show: false
    },
    yaxis: {
      labels: {
        formatter: (v: number) => {
          return Number(v).toLocaleString(appStore.locale)
        },
        offsetX: 0,
        offsetY: 4,
        style: {
          fontSize: '18px'
        }
      },
      axisTicks: {
        show: true,
        color: '#dedede',
        width: 12,
        offsetX: 11,
        offsetY: -3
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        trim: false,
        show: true,
        showDuplicates: false,
        format: "dd.MM",
        hideOverlappingLabels: true,
        offsetY: 4,
        style: {
          fontSize: '18px',
          fontWeight: '400'
        }
      },
      tickPlacement: 'between',
      tickAmount: 'dataPoints',
      tooltip: {
        enabled: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#dedede',
        height: 12
      }
    }
  }
}
