import {Component, Prop, Ref, Watch} from 'vue-property-decorator'
import {VNode} from 'vue'
import {Validations} from 'vuelidate-property-decorators'
import VueMultiSelect from "vue-multiselect"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: No typescript declarations
import DatePicker from 'vuejs-datepicker'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: No typescript declarations
import { en, ee, ru } from 'vuejs-datepicker/dist/locale/index'

import FormMixin from '@/mixins/FormMixin'

import TextInput from '@/components/form/TextInput'
import TextAreaInput from '@/components/form/TextAreaInput'
import DatePickerInput from '@/components/form/DatePickerInput'
import Button from '@/components/form/Button'
import {BIconArrowLeft, BIconArrowRight, BIconCalendar4Week, BIconCheckCircle, BIconClock, BIconExclamationCircle, BIconLock, BIconPlusCircle} from 'bootstrap-vue'


import {
  buildTaskFromCalendarEvent,
  EventFromCalendarWithTaskData,
  InternalTaskData,
  TaskInternalOccurrence
} from '@/_modules/request/types'
import {isEmpty} from '@/constants/DataBoundaries'

import moment from 'moment'

import SelectInput from '@/components/form/SelectInput'
import I18nOptions, { SelectOption } from '@/utils/I18nOptions'
import {ActorRole, DatePickerInterface, DealStatus, ReminderOptions, RepeatBy, RepeatOptions} from '@/types'
import _ from 'lodash'
import {calendarValidation} from '@/components/calendar/validation'
import {
  buildFormInitialState,
  calendarErrors,
  CalendarForm,
  FormValidation,
  getDealStatusColor,
  getTaskStateLabelTranslation,
  IncomeDataFromCalendar,
  SelectedPeriod,
  TzDate
} from '@/components/calendar/mixin'
import RadioGroupInput from '@/components/form/RadioGroupInput'
import {inferValidationInstance} from '@/utils/vuelidate-extension'
import {authStore} from "@/store";
import { AppLang } from '@/i18n'
import { serverDateFormat, serverDateTimeFormat } from '@/constants/ApplicationConfiguration'
import { formatDate, getDatepickerDisplayDateFormat } from '@/utils/formatters'
import AppIconClose from '@/components/icons/AppIconClose'
import InfoNotice from '@/components/layout/InfoNotice'
import { timeBetween } from '@/utils/string-utils'
import { RecursivePartial } from '@/utils/typescript-library-extensions'
import { ServiceType } from '@/_modules/service/types'
import ServiceTypeSelectInput from '@/components/form/ServiceTypeSelectInput'


export interface ComponentValidation {
  form: FormValidation;
}

export interface TasksCalendarInterface extends Vue {
  addTask(momentDate: moment.Moment): void;
  selectedWeekDateRange(): Date[];
  setSelectedWeek(momentDate: moment.Moment): void;
  weekStartDate(): Date;
  filteredTasks: InternalTaskData[];
}

export function parseTime(time: string, dpDate: Date): Date {
  const [hours, minutes] = time.split(':')
  const selectedTime = moment(dpDate)
  selectedTime.set('hour', Number(hours))
  selectedTime.set('minutes', Number(minutes))
  return selectedTime.toDate()
}

enum CalendarActorRole {
  All = "All",
  Requester = "Requester",
  Provider = "Provider"
}

interface Props {
  availableServices?: RecursivePartial<ServiceType>[];
  canComplete: boolean;
  canConfirm: boolean;
  dayHeaderDateFormat: string;
  disabled: boolean;
  isOverview?: boolean;
  selectedTask?: InternalTaskData;
  showCalendarComponent: boolean;
  showLockBtn: boolean;
  title: string;
  value: InternalTaskData[];
}

@Component({
  name: 'CompactScheduleCalendar',
  components: {
    BIconArrowLeft,
    BIconArrowRight,
    BIconCalendar4Week,
    BIconCheckCircle,
    BIconClock,
    BIconExclamationCircle,
    BIconLock,
    BIconPlusCircle
  }
})
export default class CompactScheduleCalendar extends FormMixin implements Props {
  @Prop({type: Array, required: false, default: () => []}) public readonly availableServices!: RecursivePartial<ServiceType>[]
  @Prop(Boolean) public readonly canComplete!: boolean
  @Prop(Boolean) public readonly canConfirm!: boolean
  @Prop({type: String, required: false, default: 'DD.MM'}) public readonly dayHeaderDateFormat!: string
  @Prop(Boolean) public readonly disabled!: boolean
  @Prop(Boolean) public readonly isOverview?: boolean
  @Prop(Object) public readonly selectedTask?: InternalTaskData
  @Prop({type: Boolean, required: false, default: true}) public readonly showCalendarComponent!: boolean
  @Prop(Boolean) public readonly showLockBtn!: boolean
  @Prop(String) public readonly title!: string
  @Prop(Array) public readonly value!: InternalTaskData[]

  @Ref('scheduleDatePicker') public readonly scheduleDatePicker!: DatePickerInterface

  private showEditTaskModal: boolean = false
  private showViewTaskModal: boolean = false
  private calendarActorRole: CalendarActorRole = CalendarActorRole.All

  public selectedPeriod: SelectedPeriod = {
    start: moment().startOf('isoWeek').toDate(),
    end: moment().endOf('isoWeek').toDate()
  }

  public scheduleList: InternalTaskData[] = []
  public form: CalendarForm = buildFormInitialState()

  @Validations()
  public validations(): ComponentValidation {
    return calendarValidation(this.form)
  }

  public onClickNav(direction: string): void {
    const newDate = moment(this.selectedPeriod.start);
    if (direction === 'prev') {
      newDate.subtract(1, 'week')
    } else {
      newDate.add(1, 'week')
    }

    this.setSelectedWeek(newDate)
  }

  public onClickToday(): void {
    this.setSelectedWeek(moment())
  }

  public setSelectedWeek(momentDate: moment.Moment): void {
    // Clone date, don't modify the original
    const newMomentDate = momentDate.clone();

    this.selectedPeriod.start = newMomentDate.startOf('isoWeek').toDate();
    this.selectedPeriod.end = newMomentDate.endOf('isoWeek').toDate();
    this.$emit('weekChanged', this.weekStartDate())
  }

  public selectedWeekDateRange(): Date[] {
    const startDate = moment(this.selectedPeriod.start)
    const endDate = moment(this.selectedPeriod.end)
    const diff = endDate.diff(startDate, 'days')
    const selectedWeekDateRange: Date[] = []
    selectedWeekDateRange.push(startDate.toDate());

    for (let index = 0; index < diff; index++) {
      selectedWeekDateRange.push(startDate.add(1, 'days').toDate())
    }

    return selectedWeekDateRange
  }

  public weekStartDate(): Date {
    return this.selectedPeriod.start
  }

  public getTaskFromOccurrence(occurrence: TaskInternalOccurrence): InternalTaskData | undefined {
    return this.value.find(task => task.id === occurrence?.state?.parentId);
  }

  public getOccurrenceById(occurrenceId: string): TaskInternalOccurrence | undefined {
    const occurrences: TaskInternalOccurrence[] = _.flatten(this.value.map(it => it.occurrences || []));
    return occurrences.find(occurrence => occurrence.id === occurrenceId);
  }

  public getFormattedTaskDuration(): string {
    const taskDuration = timeBetween(this.form.task.end, this.form.task.start);
    const formattedDuration = [];

    if (taskDuration.hours() !== 0) {
      formattedDuration.push(`${taskDuration.hours()}${this.translation('enum_datetime_h')}`);
    }

    if (taskDuration.minutes() !== 0) {
      formattedDuration.push(`${taskDuration.minutes()}${this.translation('enum_datetime_m')}`);
    }

    return formattedDuration.join(' ');
  }

  public getFormattedOccurrenceTitle(occurrence: TaskInternalOccurrence): string {
    // TODO: Use task serviceCategories for task title in calendar when server support for serviceCategories is added
    // const task = this.getTaskFromOccurrence(occurrence)!;

    // if (task?.serviceCategories) {

    // }

    return occurrence.title!;
  }

  public get lang(): object {
    switch (this.$i18n.locale) {
      case AppLang.ET:
        return ee
      case AppLang.RU:
        return ru
      default:
        return en
    }
  }

  private get allowAddNewTask(): boolean {
    return !this.isOverview && !this.showLockBtn && !this.disabled;
  }

  private get calendarActorRoleOptions(): SelectOption<string>[] {
    return I18nOptions.buildEnumOptions(this.$i18n, Object.values(CalendarActorRole), 'shared.enum-calendar-actor-role-', true);
  }

  public get filteredTasks(): InternalTaskData[] {
    const userId = authStore.authInfo?.userId
    if (userId !== undefined) {
      if (this.calendarActorRole === CalendarActorRole.Requester) {
        return this.value.filter(task => task.requesterUserId === userId)
      }

      if (this.calendarActorRole === CalendarActorRole.Provider) {
        return this.value.filter(task => task.providerUserId === userId)
      }
    }

    return this.value;
  }

  @Watch('form.repeatBy')
  public onRepeatByChange(): void {
    // Reset validator when repeatBy changes.
    this.$v.$reset()

    if (this.form.repeatBy === RepeatBy.COUNT) {
      this.form.task.recurrenceRule!.repeatUntil = undefined

    } else if (this.form.repeatBy === RepeatBy.UNTIL) {
      this.form.task.recurrenceRule!.repeatCount = undefined
    }
  }

  @Watch('form.task.recurrenceRule.repeatType')
  public onRepeatTypeChange(): void {
    if (this.form.task.recurrenceRule?.repeatType === RepeatOptions.ONCE) {
      this.form.task.recurrenceRule.repeatCount = undefined
      this.form.task.recurrenceRule.repeatUntil = undefined
      this.form.repeatBy = undefined

    } else {
      if (this.form.task.recurrenceRule?.repeatUntil !== undefined) {
        this.form.repeatBy = RepeatBy.UNTIL
      }

      if (this.form.task.recurrenceRule?.repeatCount !== undefined) {
        this.form.repeatBy = RepeatBy.COUNT
      }
    }
  }

  private setTaskComplete(occurrenceId: string): void {
    const occurrence = this.getOccurrenceById(occurrenceId);
    const calendarTask = this.getTaskFromOccurrence(occurrence!);

    this.showViewTaskModal = false;

    this.$emit('setTaskComplete', occurrenceId, calendarTask);
  }

  private setTaskConfirmed(occurrenceId: string): void {
    const occurrence = this.getOccurrenceById(occurrenceId);
    const calendarTask = this.getTaskFromOccurrence(occurrence!);

    this.showViewTaskModal = false;

    this.$emit('setTaskCompleteConfirm', occurrenceId, calendarTask);
  }

  protected getActorRoleForOccurrence(occurrence: TaskInternalOccurrence): ActorRole | undefined {
    const userId = authStore.authInfo?.userId
    const task = this.getTaskFromOccurrence(occurrence);

    if (userId === task?.providerUserId) {
      return ActorRole.Provider;
    } else if (userId === task?.requesterUserId) {
      return ActorRole.Requester;
    }

    return undefined;
  }

  @Watch('selectedTask')
  public toggleDetails(): void {
    if(this.selectedTask?.start !== undefined) {
      const occurrence = this.selectedTask?.occurrences?.[0];
      const schedule: IncomeDataFromCalendar = {
        id: this.selectedTask.id,
        body: occurrence!.body,
        start: {_date: moment(occurrence!.start).toDate()},
        end: {_date: moment(occurrence!.end).toDate()},
        title: occurrence!.title,
        bgColor: '#8ECD16',
        dealStatus: occurrence!.state.dealStatus!,
        recurrenceRule: occurrence!.recurrenceRule,
        occurrences: this.selectedTask.occurrences!,
        state: occurrence!.state
      }

      this.viewTask(schedule, false)
      this.$emit('taskOpened')
    }
  }

  public viewTask(schedule: IncomeDataFromCalendar, showPreviewModal: boolean = true): void {
    if ((schedule.bgColor === '#8ECD16' && !isEmpty(schedule.title)) || this.isOverview) {
      this.form = buildFormInitialState()
      if (schedule.recurrenceRule?.repeatType !== RepeatOptions.ONCE) {
        this.form.repeatBy = schedule.recurrenceRule.repeatCount ? RepeatBy.COUNT : RepeatBy.UNTIL
      }

      schedule.end = this.setEndDateEqualStartDate(schedule.start, schedule.end)
      this.form.task.recurrenceRule = _.cloneDeep(schedule.recurrenceRule)
      this.form.task.start = _.cloneDeep(schedule.start._date)
      this.form.task.end = _.cloneDeep(schedule.end._date)
      this.form.task.id = _.cloneDeep(schedule.id)
      this.form.task.state!.parentId = _.cloneDeep(schedule.state.parentId)
      this.form.task.title = _.cloneDeep(schedule.title)
      this.form.task.body = _.cloneDeep(schedule.body)
      this.form.task.occurrences = _.cloneDeep(schedule.occurrences)
      this.form.timeFrom = _.cloneDeep(this.onlyTimeFromDate(schedule.start._date))
      this.form.timeTo = _.cloneDeep(this.onlyTimeFromDate(schedule.end._date))
      this.form.task.state = _.cloneDeep(schedule.state)
      this.form.task.state.reminder = schedule.state.reminder !== undefined ? _.cloneDeep(schedule.state.reminder) : ReminderOptions.NONE

      if (showPreviewModal) {
        this.showViewTaskModal = true
      } else {
        this.showEditTaskModal = true
      }
    }
  }

  public mounted(): void {
    this.$emit('currentWeek', this.weekStartDate())
  }

  public onlyTimeFromDate(value: Date): string {
    const formattedHours = (moment(value).toObject().hours).toString().length === 1 ? `0${moment(value).toObject().hours}` : `${moment(value).toObject().hours}`
    const formattedMinutes = (moment(value).toObject().minutes).toString().length === 1 ? `0${moment(value).toObject().minutes}` : `${moment(value).toObject().minutes}`
    return `${formattedHours}:${formattedMinutes}`
  }

  public isTimeAvailable(startDate: Date): boolean {
    if (moment().isBefore(moment(startDate))) {
      return true
    } else {
      this.$emit('scheduleError', calendarErrors.noDateBeforeNow)
      return false
    }
  }

  protected setEndDateEqualStartDate(start: TzDate, end: TzDate): TzDate {
    const hoursAndMinutes = [end._date.getHours(), end._date.getMinutes()]
    if (hoursAndMinutes[0] === 0 && hoursAndMinutes[1] === 0) {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      end._date.setHours(23)
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      end._date.setMinutes(59)
    }
    end._date.setDate(start._date.getDate())
    return end
  }

  public addTask(taskDate: moment.Moment): void {
    let taskStartMoment = taskDate;
    const defaultTaskDuration = 60;
    const currentDateTime = moment();

    // If adding task for today
    if (taskStartMoment.isSame(currentDateTime, 'day')) {
      
      // Remove seconds and milliseconds from start time
      taskStartMoment = currentDateTime.seconds(0).milliseconds(0);

      // Round time up to next 30 minute interval
      const interval = 30;
      const remainder = interval - (currentDateTime.minute() % interval);

      taskStartMoment.add(remainder, 'minutes');
    }

    const taskStartTZDate: TzDate = {
      _date: taskStartMoment.toDate()
    };
    let taskEndTZDate: TzDate = {
      _date: taskStartMoment.clone().add(defaultTaskDuration, 'minutes').toDate()
    };

    taskEndTZDate = this.setEndDateEqualStartDate(taskStartTZDate, taskEndTZDate)

    if (!this.disabled) {
      if (this.isTimeAvailable(taskStartTZDate._date)) {

        this.form = buildFormInitialState()

        this.form.task.title = _.cloneDeep(this.title)
        this.form.task.start = _.cloneDeep(taskStartTZDate._date)
        this.form.task.end = _.cloneDeep(taskEndTZDate._date)

        this.showEditTaskModal = true
      }
    } else {
      this.$emit('scheduleError', calendarErrors.notAllowedToEdit)
      this.showEditTaskModal = false
    }
  }

  public onSaveClick(e: Event): void {
    e.preventDefault()

    if (this.checkValidation(this.$v.form)) {
      const start = parseTime(this.form.timeFrom!, this.form.task.start!)
      const end = parseTime(this.form.timeTo!, this.form.task.end!)

      this.form.task.start = start
      this.form.task.end = end
      this.form.task.end.setDate(this.form.task.start.getDate())
      this.form.task.end.setMonth(this.form.task.start.getMonth())
      this.form.task.end.setFullYear(this.form.task.start.getFullYear())

      if (this.isTimeAvailable(start)) {
        this.$emit('addCalendarEvent', buildTaskFromCalendarEvent(this.form.task as EventFromCalendarWithTaskData))
      } else {
        this.$emit('scheduleError', 'err_schedule_invalid_date_on_save')
      }

      // Reset validator
      this.$v.$reset();

      this.showEditTaskModal = false
    }
  }

  public onRemoveClick(e: Event): void {
    e.preventDefault()
    this.$emit('removeCalendarEvent', buildTaskFromCalendarEvent(this.form.task as EventFromCalendarWithTaskData))
    this.showEditTaskModal = false
  }

  private redirectToDeal(): void {
    const res = _.flatten(this.value.map(it => it.occurrences)).filter(o => {
      return o?.id === this.form.task.id ? o : undefined
    })

    if (res.length > 0) {
      const dealType = res[0]!.state.dealType!.toLowerCase()
      if (dealType === 'request') {
        this.$router.push({
          name: `from_overview_${dealType}-${res[0]!.state.requesterUserId === authStore.authInfo?.userId ? 'requester' : 'provider'}`,
          params: {requestId: res[0]!.state.dealId!}
        })
      } if (dealType === 'proposal') {
        this.$router.push({
          name: `from_overview_${dealType}-${res[0]!.state.requesterUserId === authStore.authInfo?.userId ? 'requester' : 'provider'}`,
          params: {proposalId: res[0]!.state.dealId!}
        })
      }
    }
  }

  private onClickScheduleDatepicker(): void {
    this.scheduleDatePicker.showCalendar()
  }

  private onStartTimeBlur(): void {
    if (isEmpty(this.form.timeTo) && !isEmpty(this.form.timeFrom) && this.form.task.start !== undefined) {
      const startTime = moment(parseTime(this.form.timeFrom!, this.form.task.start!));
      startTime.add(1, 'hour');

      this.form.timeTo = this.onlyTimeFromDate(startTime.toDate());
    }
  }

  public renderEditTaskModal(): VNode {
    const v = inferValidationInstance<ComponentValidation>(this.$v)

    return (
      <b-modal
        centered
        scrollable
        size="lg"
        title={this.translation('title_task_modal_add')}
        title-tag="h2"
        v-model={this.showEditTaskModal}
      >
        <template slot="modal-header-close">
          <AppIconClose class="text-primary" />
        </template>
        <template slot="default">
          <form>
            <InfoNotice>{this.translation('lbl_schedule_calendar_time_notice')}</InfoNotice>
            <b-row class="justify-content-center">
              <b-col cols="12" md="6">
                <DatePickerInput
                  noPastDate
                  v-model={this.form.task.start}
                  disabled={this.disabled}
                  invalidMessage={this.buildInvalidMessage(v.form.task.start)}
                  onInput={(start: Date) => (this.form.task.end = start)}
                >
                  <template slot="label">
                    {this.form.task.recurrenceRule?.repeatType === RepeatOptions.ONCE ? this.translation('lbl_request_service_date_from') : this.translation('lbl_request_service_begin_date')}
                  </template>
                </DatePickerInput>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  label-for={'task-start-time'}
                  label={this.translation('lbl_schedule_calendar_time')}
                >
                  <div class="time-input-group">
                    <TextInput
                      id="task-start-time"
                      aria-label={this.translation('lbl_schedule_calendar_time_from')}
                      v-model={this.form.timeFrom}
                      disabled={this.disabled}
                      timeMask
                      placeholder="00:00"
                      onBlur={this.onStartTimeBlur}
                      invalid-message={this.buildInvalidMessage(v.form.timeFrom)}
                      class="mb-0"
                    />
                    <span class="mx-2">-</span>
                    <TextInput
                      aria-label={this.translation('lbl_schedule_calendar_time_to')}
                      value={this.form.timeTo}
                      onInput={(time: string) => {this.form.timeTo = time === '00:00' ? '23:59' : time}}
                      disabled={this.disabled}
                      timeMask
                      placeholder="00:00"
                      invalid-message={this.buildInvalidMessage(v.form.timeTo)}
                      class="mb-0"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <SelectInput
                  label={this.translation('shared.lbl-repeat-task')}
                  options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(RepeatOptions), 'shared.enum-repeat-task-', true)}
                  value={this.form.task.recurrenceRule!.repeatType}
                  onInput={(type: RepeatOptions) => {
                    if (this.form.task.recurrenceRule?.repeatType !== type) {
                      this.form.task.recurrenceRule!.repeatType = type
                    }
                  }}
                  disabled={this.disabled}
                />
                {this.form.task.recurrenceRule?.repeatType !== RepeatOptions.ONCE &&
                  <RadioGroupInput
                    class="ml-6 ml-md-8"
                    stacked={true}
                    options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(RepeatBy), 'shared.enum-repeat-task-type-', true)}
                    value={String(this.form.repeatBy)}
                    onInput={(type: RepeatBy) => (this.form.repeatBy = type)}
                    invalidMessage={this.buildInvalidMessage(v.form.repeatBy)}
                    disabled={this.disabled}
                  />
                }
                {(this.form.repeatBy === RepeatBy.COUNT || this.form.task.recurrenceRule?.repeatCount !== undefined) &&
                  <TextInput
                    aria-label={this.translation('shared.enum-repeat-task-type-COUNT')}
                    class="ml-12 ml-md-16"
                    v-model={this.form.task.recurrenceRule!.repeatCount}
                    disabled={this.disabled}
                    invalidMessage={this.buildInvalidMessage(v.form.task.recurrenceRule.repeatCount)}
                    custom-class="border-right-0 pr-0"
                    append={() => {
                      return (
                        <div class="input-group-text px-3">
                          {this.translation('shared.repeat-task-type-COUNT-append')}
                        </div>
                      )
                    }}
                  />
                }
                {(this.form.repeatBy === RepeatBy.UNTIL || this.form.task.recurrenceRule?.repeatUntil !== undefined) &&
                  <DatePickerInput
                    aria-label={this.translation('shared.enum-repeat-task-type-UNTIL')}
                    class="ml-12 ml-md-16"
                    value={this.form.task.recurrenceRule!.repeatUntil !== undefined ? moment(this.form.task.recurrenceRule!.repeatUntil).toDate() : undefined}
                    onInput={(d: Date) => {
                      if (d !== null && d !== undefined) {
                        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                        d.setHours(23); d.setMinutes(59); d.setSeconds(59)
                        this.form.task.recurrenceRule!.repeatUntil = moment(d).toDate()
                      } else {
                        this.form.task.recurrenceRule!.repeatUntil = undefined
                      }
                    }}
                    invalidMessage={this.buildInvalidMessage(v.form.task.recurrenceRule.repeatUntil)}
                    disableEarlyThenDate={this.form.task.start}
                    hideLabel
                    disabled={this.disabled}
                  />
                }
              </b-col>
              <b-col cols="12" md="6">
                <SelectInput
                  label={this.translation('shared.lbl-remind')}
                  options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(ReminderOptions), 'shared.enum-remind-', true)}
                  v-model={this.form.task.state!.reminder}
                  disabled={this.disabled}
                />
              </b-col>
              <b-col cols="12" class="position-static mb-4">
                <ServiceTypeSelectInput
                  options={this.availableServices}
                  label={this.translation('fld_filter_by_service_type')}
                  placeholder={this.translation(`shared.selected_count_alt`, [this.form.task.serviceCategories!.length])}
                  value={this.form.task.serviceCategories || []}
                  onChange={(values) => (this.form.task.serviceCategories = values)}
                />
                <TextAreaInput
                  label={this.translation('lbl_request_service_description')}
                  placeholder={this.translation('fld_request_service_description_placeholder')}
                  v-model={this.form.task.body}
                  invalid-message={this.buildInvalidMessage(v.form.task.body!)}
                  maxLength={1000}
                  textCounter
                  disabled={this.disabled}
                />
              </b-col>
            </b-row>
          </form>
        </template>
        <template slot="modal-footer">
          {(!this.isOverview && !this.disabled && this.form.task.state!.parentId) &&
            <Button variant="danger" onClick={this.onRemoveClick} class="order-last order-sm-first mr-sm-auto">
              <template slot="label">
                {this.translation('btn_delete_task')}
              </template>
            </Button>
          }
          <Button
            variant="outline-primary"
            onClick={() => {
              this.$v.$reset()
              this.showEditTaskModal = false
            }}
          >
            <template slot="label">
              {this.translation('btn_reject')}
            </template>
          </Button>
          {(!this.isOverview && this.showLockBtn) &&
            <Button label={this.translation('btn_change')} variant="warning" onClick={() => {this.$emit('lockToEdit')}} />
          }
          {!this.isOverview &&
            <Button disabled={this.disabled} variant="primary" onClick={this.onSaveClick} class="order-first order-sm-last">
              <template slot="label">
                {this.form.task.state!.parentId && this.translation('btn_edit')}
                {!this.form.task.state!.parentId && this.translation('btn_add_task')}
              </template>
            </Button>
          }
          {this.isOverview &&
            <Button variant={'primary'} onClick={() => this.redirectToDeal()} class="order-first order-sm-last">
              <template slot="label">
                {this.translation('btn_to_contract')}
              </template>
            </Button>
          }
        </template>
      </b-modal>
    )
  }

  public renderViewTaskModal(): VNode {
    const lastOccurrence = this.form.task.occurrences?.slice(-1)[0];
    const currentDateTime = moment();
    const isPastOccurrence = moment(this.form.task.start).isBefore(currentDateTime, 'second');
    const taskStateLabel = getTaskStateLabelTranslation(this.form.task.state!, this.canConfirm);
    const userId = authStore.authInfo?.userId;
    let taskActorRole = ActorRole.Provider;

    if (userId === this.form.task.state?.requesterUserId) {
      taskActorRole = ActorRole.Requester;
    }

    return (
      <b-modal
        body-class='pt-md-8 px-md-10 pb-md-12'
        header-class='py-md-4 pl-md-10'
        centered
        scrollable
        size="sm"
        title={this.translation('title_task_modal_view')}
        title-tag="h2"
        v-model={this.showViewTaskModal}
      >
        <template slot="modal-header-close">
          <AppIconClose class="text-primary" />
        </template>
        <template slot="default">
          <div class="d-flex align-items-end flex-wrap ">
            <h3 class="mr-3 mb-1">{formatDate(this.form.task.start)}</h3>
            <div class="d-flex mb-1">
              <b-icon-clock class="app-icon-lg text-gray-500 mr-2" aria-hidden="true" />
              {this.form.timeFrom}-{this.form.timeTo} ({this.getFormattedTaskDuration()})
            </div>
          </div>
          <div class="small mb-4">
            {taskActorRole === ActorRole.Requester && this.form.task.state?.providerUserName &&
              <p class="mb-0 font-weight-bold">{this.form.task.state?.providerUserName}</p>
            }
            {taskActorRole === ActorRole.Provider && this.form.task.state?.requesterUserName &&
              <p class="mb-0 font-weight-bold">{this.form.task.state?.requesterUserName}</p>
            }
            {this.form.task.title &&
              <p class="mb-0">{this.form.task.title}</p>
            }
            {(this.form.task.recurrenceRule?.repeatType && this.form.task.recurrenceRule?.repeatType !== RepeatOptions.ONCE && lastOccurrence) && 
              <p class="mb-0">
                {this.translation('shared.msg_formatted_task_recurrence', [this.translation(`shared.enum-repeat-task-${this.form.task.recurrenceRule!.repeatType}`).toLowerCase(), formatDate(lastOccurrence.start)])}
              </p>
            }
            {this.form.task.state!.reminder && this.form.task.state!.reminder !== ReminderOptions.NONE && 
              <p class="mb-0">
                {this.translation('shared.lbl-remind')} {this.translation(`shared.enum-remind-${this.form.task.state!.reminder}`)}.
              </p>
            }
          </div>
          <hr />
          {this.form.task.body && 
            <div class="mt-8">
              <h4>{this.translation('lbl_request_service_description')}</h4>
              <p>{this.form.task.body}</p>
            </div>
          }
        </template>
        <template slot="modal-footer">
          <div class="d-flex flex-column flex-sm-row align-items-center w-100">
            <div class="mr-auto">
              {taskStateLabel.length !== 0 &&
                <div class="d-flex flex-column mr-2">
                  <span class="small mb-1">{this.translation('lbl_task_status')}</span>
                  <strong>
                    {getTaskStateLabelTranslation(this.form.task.state!, this.canConfirm)}
                  </strong>
                </div>
              }
            </div>
            <div class="mt-4 mt-sm-0">
              {((!this.isOverview && this.form.task.state!.parentId) && !this.disabled) &&
                <Button
                  disabled={this.disabled}
                  variant="primary"
                  onClick={() => {
                    this.showViewTaskModal = false;
                    this.showEditTaskModal = true;
                  }}
                  class="order-first order-sm-last"
                >
                  <template slot="label">
                    {this.form.task.state!.parentId && this.translation('btn_edit')}
                  </template>
                </Button>
              }
              {(this.canConfirm && isPastOccurrence && !this.form.task.state!.confirmed && this.form.task.state!.completed) &&
                <b-button
                  variant="primary"
                  onClick={() => this.setTaskConfirmed(this.form.task.id as string)}
                >
                  {this.translation('btn_task_completed_not_confirmed')}
                </b-button>
              }
              {(this.canComplete && isPastOccurrence && !this.form.task.state!.completed) &&
                <b-button
                  variant="primary"
                  onClick={() => this.setTaskComplete(this.form.task.id as string)}
                >
                  {this.translation('btn_task_set_complete')}
                </b-button>
              }
              {this.isOverview &&
                <Button variant={'primary'} onClick={() => this.redirectToDeal()} class="order-first order-sm-last">
                  <template slot="label">
                    {this.translation('btn_to_contract')}
                  </template>
                </Button>
              }
            </div>
          </div>
        </template>
      </b-modal>
    )
  }

  public renderCalendarDayTasks(momentDate: moment.Moment, dateTasks: TaskInternalOccurrence[], showPlaceholder: boolean): VNode {
    const currentDateTime = moment();

    return (
      <div class="compact-schedule-tasks ml-2 ml-lg-0 mb-n1">
        {dateTasks.map(occurrence => {
          if ((occurrence.bgColor === '#8ECD16' && !isEmpty(occurrence.title)) || this.isOverview) {
            const isPastOccurrence = moment(occurrence.start).isBefore(currentDateTime, 'second');
            const occurrenceActorRole = this.getActorRoleForOccurrence(occurrence);
            const canCompleteTask = (this.canComplete || (occurrenceActorRole === ActorRole.Provider && occurrence.state.dealStatus === DealStatus.Paid)) && isPastOccurrence && !occurrence.state!.completed;
            const canConfirmTask = (this.canConfirm || occurrenceActorRole === ActorRole.Requester) && isPastOccurrence && !occurrence.state!.confirmed && occurrence.state!.completed;

            return (
              <b-link
                class={[
                  'compact-schedule-task py-2 pl-2 pr-3 mb-1',
                  (isPastOccurrence && !canCompleteTask && !canConfirmTask) ? 'is-past' : '',
                  (isPastOccurrence && (canCompleteTask || canConfirmTask)) ? 'is-past-actionable' : '',
                  `task-bg-${getDealStatusColor(occurrence.state?.dealStatus)}`
                ]}
                onClick={() => {
                  const task = this.getTaskFromOccurrence(occurrence)!;
                  const schedule: IncomeDataFromCalendar = {
                    id: occurrence.id!,
                    body: occurrence.body,
                    start: {_date: moment(occurrence.start).toDate()},
                    end: {_date: moment(occurrence.end).toDate()},
                    title: occurrence.title,
                    bgColor: occurrence.bgColor,
                    dealStatus: occurrence.state.dealStatus!,
                    recurrenceRule: occurrence.recurrenceRule,
                    occurrences: task.occurrences!,
                    state: occurrence.state
                  }
                  this.viewTask(schedule, true)
                }}
              >
                {occurrenceActorRole === ActorRole.Requester && occurrence.state?.providerUserName &&
                  <p class="mb-0 text-xs">{occurrence.state?.providerUserName}</p>
                }
                {occurrenceActorRole === ActorRole.Provider && occurrence.state?.requesterUserName &&
                  <p class="mb-0 text-xs">{occurrence.state?.requesterUserName}</p>
                }
                {occurrence.title &&
                  <p class="compact-schedule-task-title">{this.getFormattedOccurrenceTitle(occurrence)}</p>
                }
                <div>
                  <time datetime={moment(occurrence.start).format(serverDateTimeFormat)}>{moment(occurrence.start).format('HH:mm')}</time>-<time datetime={moment(occurrence.end).format(serverDateTimeFormat)}>{moment(occurrence.end).format('HH:mm')}</time>
                </div>
                <p class="mb-0 text-xs">{getTaskStateLabelTranslation(occurrence.state!, this.canConfirm)}</p>
                
                {canConfirmTask &&
                  <b-button
                    class="mt-2"
                    variant="secondary"
                    size="sm"
                    onClick={(event: Event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      this.setTaskConfirmed(occurrence.id as string)
                    }}
                  >
                    {this.translation('btn_task_completed_not_confirmed')}
                  </b-button>
                }
                {canCompleteTask &&
                  <b-button
                    class="mt-2"
                    variant="secondary"
                    size="sm"
                    onClick={(event: Event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      this.setTaskComplete(occurrence.id as string)
                    }}
                  >
                    {this.translation('btn_task_set_complete')}
                  </b-button>
                }
              </b-link>
            )
          } else {
            return (
              <div class="compact-schedule-task-busy mb-1">
                <b-icon-lock class="app-icon-sm text-gray-500 mr-1" aria-label={this.translation('lbl_busy')} /><time datetime={moment(occurrence.start).format(serverDateTimeFormat)}>{moment(occurrence.start).format('HH:mm')}</time>-<time datetime={moment(occurrence.end).format(serverDateTimeFormat)}>{moment(occurrence.end).format('HH:mm')}</time>
              </div>
            )
          }
        })}
        {momentDate.isSameOrAfter(currentDateTime, 'day') && this.allowAddNewTask &&
          <b-link
            class="compact-schedule-add-task mb-1"
            aria-label={this.translation('lbl_add_task')}
            onClick={() => {
              this.addTask(momentDate.clone())
            }}
          >
            <b-icon-plus-circle class="app-icon-lg d-flex" aria-hidden="true" />
          </b-link>
        }
        {showPlaceholder &&
          <div class="compact-schedule-task-placeholder mb-1"></div>
        }
      </div>
    )
  }

  public renderCalendar(): VNode {
    const currentDateTime = moment();
    const occurrences: TaskInternalOccurrence[] = _.flatten(this.filteredTasks.map(it => it.occurrences || []));

    // Sort occurrences by start time
    occurrences.sort((occurrence1, occurrence2) => {
      return moment(occurrence1.start).valueOf() - moment(occurrence2.start).valueOf()
    });

    let mostTasksPerDay = 0;

    // Get maximum task count per day
    this.selectedWeekDateRange().map((date: Date) => {
      const momentDate = moment(date);
      const dateTasks = occurrences.filter(occurrence => moment(occurrence!.start).isSame(momentDate, 'day'));
      const dateTaskCount = dateTasks.length;

      if (dateTaskCount > mostTasksPerDay) {
        mostTasksPerDay = dateTaskCount;
      }
    });

    return (
      <div class="compact-schedule-calendar mx-n1 mt-8 mb-n2 mb-lg-n4" aria-live="polite">
        {this.selectedWeekDateRange().map((date: Date) => {
          const momentDate = moment(date);
          const dateTasks = occurrences.filter(occurrence => moment(occurrence!.start).isSame(momentDate, 'day'));
          const dateTaskCount = dateTasks.length;
          let showPlaceholder = momentDate.isBefore(currentDateTime, 'day') || !this.allowAddNewTask;

          // Hide placeholder for date with the most tasks in current week
          if (dateTaskCount > 0 && dateTaskCount === mostTasksPerDay) {
            showPlaceholder = false;
          }

          return (
            <div
              class={[
                'compact-schedule-day px-1 mb-4',
                momentDate.isBefore(currentDateTime, 'day') ? 'is-past' : '',
                momentDate.isSame(currentDateTime, 'day') ? 'is-today' : ''
              ]}
            >
              <time class="compact-schedule-day-header px-3 py-2 pt-lg-0 pb-lg-1 mb-auto mb-lg-4" datetime={momentDate.format(serverDateFormat)}>
                <span class="h3 my-0 mr-1" aria-hidden="true">{momentDate.locale(this.$i18n.locale).format('dd')}</span><span class="compact-schedule-date" aria-hidden="true">{momentDate.format(this.dayHeaderDateFormat)}</span>
              </time>
              {this.renderCalendarDayTasks(momentDate, dateTasks, showPlaceholder)}
            </div>
          )
        })}
      </div>
    )
  }

  public render(): VNode {
    return (
      <div>
        <b-row>
          {(this.showCalendarComponent || this.isOverview) && 
            <b-col cols="12" class={`compact-schedule-controls mt-sm-n4${!this.isOverview ? ' flex-md-nowrap' : ''}`}>
              <DatePicker
                monday-first={true}
                language={this.lang}
                format={getDatepickerDisplayDateFormat()}
                highlighted={{
                  from: this.selectedPeriod.start,
                  to: this.selectedPeriod.end
                }}
                ref="scheduleDatePicker"
                typeable={false}
                placeholder={`${formatDate(this.selectedPeriod.start)}-${formatDate(this.selectedPeriod.end)}`}
                bootstrap-styling={true}
                class="mt-sm-4"
                calendar-class='app-datepicker-calendar'
                input-class='schedule-datepicker-input border-right-0 pr-0 bg-white'
                onInput={(date: Date | null) => {
                  if (date instanceof Date) {
                    this.setSelectedWeek(moment(date));
                  }
                  // Reset selected date. Placeholder is used to display date range
                  this.scheduleDatePicker.selectedDate = null;
                }}
              >
                <template slot="afterDateInput">
                  <span class="input-group-append" onClick={this.onClickScheduleDatepicker}>
                    <span class="input-group-text px-3">
                      <b-icon-calendar4-week variant="primary" aria-hidden="true" />
                    </span>
                  </span>
                </template>
              </DatePicker>
              <div class="d-flex justify-content-center my-2 mt-sm-4 mb-sm-0 mx-sm-4">
                <b-link class="text-primary d-flex p-3" onClick={() => this.onClickNav('prev')} aria-label={this.translation('lbl_previous_week')}>
                  <b-icon-arrow-left class="app-icon-lg mt-0" aria-hidden="true" />
                </b-link>
                <b-link class="text-primary d-flex p-3" onClick={() => this.onClickNav('next')} aria-label={this.translation('lbl_next_week')}>
                  <b-icon-arrow-right class="app-icon-lg mt-0" aria-hidden="true" />
                </b-link>
              </div>
              <b-button class="text-nowrap d-none d-md-inline-block mt-sm-4 mr-4" variant="outline-primary" onClick={() => this.onClickToday()}>
                {this.translation('lbl_current_week')}
              </b-button>
              {this.isOverview &&
                <VueMultiSelect
                  class="custom-control-single-select mt-sm-4 ml-xl-auto"
                  multiple={false}
                  close-on-select={true}
                  preselect-first={true}
                  searchable={false}
                  show-labels={false}
                  placeholder=""
                  options={this.calendarActorRoleOptions}
                  value={this.calendarActorRoleOptions.find(item => item.value === this.calendarActorRole)}
                  track-by="value"
                  label="text"
                  onInput={(value: SelectOption<CalendarActorRole>) => {
                    this.calendarActorRole = value.value!;
                  }}
                  scopedSlots={{
                    singleLabel: ({ option }: { option: SelectOption<CalendarActorRole> }): VNode => {
                      return (
                        <span>{this.translation(`shared.enum-calendar-actor-role-${option.value}`)}</span>
                      )
                    }
                  }}
                />
              }
              {this.$slots.headerControls !== undefined && this.$slots.headerControls}
            </b-col>
          }
          {!this.showCalendarComponent && !this.isOverview && 
            <b-col cols="12" class="compact-schedule-controls mt-sm-n4">
              {this.$slots.headerControls !== undefined && this.$slots.headerControls}
            </b-col>
          }
        </b-row>
        {(this.showCalendarComponent && this.showLockBtn) &&
          <b-row class="mt-4 mt-md-6">
            <b-col class="text-right">
              <Button variant="warning" onClick={() => {this.$emit('lockToEdit')}} label={this.translation('btn_edit')}/>
            </b-col>
          </b-row>
        }
        {this.showCalendarComponent && (!this.isOverview || (this.isOverview && this.filteredTasks.length !== 0)) && this.renderCalendar()}
        {this.isOverview && this.filteredTasks.length === 0 &&
          <InfoNotice
            class="mt-8 align-items-center"
            icon="exclamation-circle"
          >
            {this.translation('msg_overview_calendar_empty')}
          </InfoNotice>
        }
        {this.renderEditTaskModal()}
        {this.renderViewTaskModal()}
      </div>
    )
  }
}
