import { Component, Prop, Watch } from "vue-property-decorator"
import { VNode } from "vue"
import { BIconClock } from "bootstrap-vue"

import { InternalTaskData, TaskDefinition, TaskInternalOccurrence } from '@/_modules/request/types'
import { timeBetween } from "@/utils/string-utils"
import moment from "moment"
import { isEmpty } from "@/constants/DataBoundaries";
import { TsxComponent } from '@/components/TsxComponent'
import { serverDateFormat, serverDateTimeFormat } from "@/constants/ApplicationConfiguration"
import { flatten } from "lodash"
import { ActorRole, ReminderOptions, RepeatOptions } from "@/types"
import { formatDate } from "@/utils/formatters"
import { getTaskStateLabelTranslation } from "./mixin"
import { authStore } from "@/store"

interface GroupedOccurrences {
  [key: string]: TaskInternalOccurrence[];
}

interface Props {
  canComplete: boolean;
  canConfirm: boolean;
  disabled: boolean;
  isOverview?: boolean;
  selectedWeek: Date[];
  showLockBtn: boolean;
  value: TaskDefinition[] | InternalTaskData[];
  onSetTaskComplete?: (taskId: string) => void;
  onSetTaskCompleteConfirm?: (taskId: string) => void;
  onToggleTaskDetails?: (e: TaskDefinition) => void;
}

@Component({name: 'ScheduleList', components: {BIconClock}})
export default class ScheduleList extends TsxComponent<Props> {
  @Prop(Boolean) public readonly canComplete!: boolean
  @Prop(Boolean) public readonly canConfirm!: boolean
  @Prop(Boolean) public readonly disabled!: boolean
  @Prop({type: Boolean, default: false}) public readonly isOverview?: boolean
  @Prop(Array) public readonly selectedWeek!: Date[]
  @Prop(Boolean) public readonly showLockBtn!: boolean
  @Prop(Array) public readonly value!: TaskDefinition[]

  @Watch('canConfirm')
  @Watch('canComplete')
  public scrollToTask(): void {
    if (this.canConfirm || this.canComplete) {
      const scrollDelay = 1000;

      if (!isEmpty(this.$route.hash)) {
        const id = this.$route.hash.substring(1, this.$route.hash.length)
        setTimeout(() => {
          const taskScrollTo = document.getElementById(id)
          if (taskScrollTo !== null) {
            taskScrollTo.scrollIntoView({behavior: "auto", block: "start"})
          }
        }, scrollDelay)
      } else {
        if (this.canComplete) {
          const task = this.occurrences.sort((a, b) => {
            return moment(a.start).valueOf() - moment(b.start).valueOf()
          }).find(it => {
            return !it.state!.completed
          })
          if (task !== undefined) {
            const taskPos = document.getElementById(task.id!)
            if (taskPos !== null) {
              setTimeout(() => {
                taskPos.scrollIntoView({behavior: "auto", block: "start"})
              }, scrollDelay)
            }
          }
        }
        if (this.canConfirm) {
          const task = this.occurrences.sort((a, b) => {
            return moment(a.start).valueOf() - moment(b.start).valueOf()
          }).find(it => {
            return it.state.completed && !it.state.confirmed
          })
          if (task !== undefined) {
            const taskPos = document.getElementById(task.id!)
            if (taskPos !== null) {
              setTimeout(() => {
                taskPos.scrollIntoView({behavior: "auto", block: "start"})
              }, scrollDelay)
            }
          }
        }
      }
    }
  }

  public getTaskFromOccurrence(occurrence: TaskInternalOccurrence): TaskDefinition | undefined {
    return this.value.find(task => task.id === occurrence?.state?.parentId);
  }
  
  public formattedLocalTime(value: moment.MomentInput): string {
    return moment(value).locale(this.$i18n.locale).format('LT')
  }

  public getFormattedDuration(occurrence: TaskInternalOccurrence): string {
    const taskDuration = timeBetween(occurrence.end, occurrence.start);
    const formattedDuration = [];

    if (taskDuration.hours() !== 0) {
      formattedDuration.push(`${taskDuration.hours()}${this.translation('enum_datetime_h')}`);
    }

    if (taskDuration.minutes() !== 0) {
      formattedDuration.push(`${taskDuration.minutes()}${this.translation('enum_datetime_m')}`);
    }

    return formattedDuration.join(' ');
  }

  private setTaskComplete(item: TaskInternalOccurrence): void {
    this.$emit('setTaskComplete', item.id)
  }

  private onSetTaskConfirmed(item: TaskInternalOccurrence): void {
    this.$emit('setTaskCompleteConfirm', item.id)
  }

  private get occurrences(): TaskInternalOccurrence[] {
    return flatten(this.value.map(task => task.occurrences))
  }

  private get groupedOccurrences(): GroupedOccurrences {
    const occurrences = this.occurrences;

    // Sort occurrences by start time
    occurrences.sort((occurrence1, occurrence2) => {
      return moment(occurrence1.start).valueOf() - moment(occurrence2.start).valueOf()
    });

    // Group occurrences by date
    return occurrences.reduce((groupedOccurrences: GroupedOccurrences, occurrence) => {
      const date = moment(occurrence.start).format(serverDateFormat);

      groupedOccurrences[date] = [...groupedOccurrences[date] || [], occurrence];

      return groupedOccurrences;
    }, {});
  }

  public render(): VNode {
    const occurrenceDates = Object.keys(this.groupedOccurrences);
    const currentDateTime = moment();

    return (
      <div aria-live="polite">
        {occurrenceDates.length > 0 && Object.entries(this.groupedOccurrences).map(([occurrenceDate, occurrences]) => {
          return (
            <b-row class="row-sm-narrow mb-n2">
              <h3 class="col-12 fieldset-title mt-6 mt-md-10"><time datetime={moment(occurrenceDate).format(serverDateFormat)}>{moment(occurrenceDate).locale(this.$i18n.locale).format('L')}</time></h3>
              {occurrences.map((occurrence: TaskInternalOccurrence) => {
                const lastOccurrence = occurrence.state?.lastTaskEnd;
                const isPastOccurrence = moment(occurrence.start).isBefore(currentDateTime, 'second');
                const taskStateLabel = getTaskStateLabelTranslation(occurrence.state!, this.canConfirm);
                const userId = authStore.authInfo?.userId;
                let taskActorRole = ActorRole.Provider;
            
                if (userId === occurrence.state?.requesterUserId) {
                  taskActorRole = ActorRole.Requester;
                }

                return (
                  <b-col cols="12" md="6" class="mb-2 mb-md-4">
                    <b-card
                      class="h-100 rounded shadow-sm"
                      body-class="d-flex flex-column p-4 pt-md-8 px-md-6"
                    >
                      <div class="d-flex flex-wrap">
                        <span class="sr-only">{this.translation('lbl_task_date')}</span>
                        <b class="mr-3 mb-1">
                          <time datetime={moment(occurrence.start).format(serverDateFormat)}>{(moment(occurrence.start).locale(this.$i18n.locale).format('L'))}</time>
                        </b>
                        <div class="d-flex mb-1">
                          <b-icon-clock class="app-icon-lg text-gray-500 mt-0 mr-2" aria-label={this.translation('lbl_task_continues')} />
                          <span class="mr-1"><time datetime={moment(occurrence.start).format(serverDateTimeFormat)}>{this.formattedLocalTime(occurrence.start)}</time>-<time datetime={moment(occurrence.end).format(serverDateTimeFormat)}>{this.formattedLocalTime(occurrence.end)}</time></span>
                          ({this.getFormattedDuration(occurrence)})
                        </div>
                      </div>
                      <div class="small">
                        {taskActorRole === ActorRole.Requester && occurrence.state?.providerUserName &&
                          <p class="mb-0 font-weight-bold">{occurrence.state?.providerUserName}</p>
                        }
                        {taskActorRole === ActorRole.Provider && occurrence.state?.requesterUserName &&
                          <p class="mb-0 font-weight-bold">{occurrence.state?.requesterUserName}</p>
                        }
                        {occurrence.title &&
                          <p class="mb-0">{occurrence.title}</p>
                        }
                        {(occurrence.recurrenceRule?.repeatType && occurrence.recurrenceRule?.repeatType !== RepeatOptions.ONCE && lastOccurrence) && 
                          <p class="mb-0">
                            {this.translation('shared.msg_formatted_task_recurrence', [this.translation(`shared.enum-repeat-task-${occurrence.recurrenceRule!.repeatType}`).toLowerCase(), formatDate(lastOccurrence)])}
                          </p>
                        }
                        {occurrence.state!.reminder && occurrence.state!.reminder !== ReminderOptions.NONE && 
                          <p class="mb-0">
                            {this.translation('shared.lbl-remind')} {this.translation(`shared.enum-remind-${occurrence.state!.reminder}`)}.
                          </p>
                        }
                      </div>
                      <hr class="w-100 my-3 my-md-4" />
                      <div class="flex-fill">
                        <span class="sr-only">{this.translation('lbl_task_description')}</span>
                        <p class="mb-0">{occurrence.body}</p>
                      </div>
                      <hr class="w-100 mt-3 mt-md-6 mb-0" />
                      <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
                        <div class="mr-auto">
                          {taskStateLabel.length !== 0 &&
                            <div class="d-flex flex-column mr-2 mt-3 mt-md-4">
                              <span class="small mb-1">{this.translation('lbl_task_status')}</span>
                              <strong>
                                {getTaskStateLabelTranslation(occurrence.state!, this.canConfirm)}
                              </strong>
                            </div>
                          }
                        </div>
                        <div>
                          {(!this.disabled && !this.isOverview) && 
                            <b-button
                              class="mt-4"
                              disabled={this.disabled}
                              id={occurrence?.id}
                              variant="primary"
                              onClick={() => this.$emit('toggleTaskDetails', this.getTaskFromOccurrence(occurrence))}
                            >
                              {this.translation('btn_edit')}
                            </b-button>
                          }
                          {(this.canConfirm && isPastOccurrence && !occurrence.state.confirmed && occurrence.state.completed) &&
                            <b-button
                              class="mt-4"
                              variant="primary"
                              onClick={() => this.onSetTaskConfirmed(occurrence)}
                            >
                              {this.translation('btn_task_completed_not_confirmed')}
                            </b-button>
                          }
                          {(this.canComplete && isPastOccurrence && !occurrence.state.completed) &&
                            <b-button
                              class="mt-4"
                              variant="primary"
                              onClick={() => this.setTaskComplete(occurrence)}
                            >
                              {this.translation('btn_task_set_complete')}
                            </b-button>
                          }
                        </div>
                      </div>
                    </b-card>
                  </b-col>
                )
              })}
            </b-row>
          )
        })}
      </div>
    )
  }
}